import React from "react"
import { GoogleMap, Polygon, OverlayView } from '@react-google-maps/api'
import wktParser from 'wkt'
import {Card, Container, Row, Col} from 'react-bootstrap'


/*
  This view displays a map of the feedlot and user can select various modes of viewing.
  Refpens will be colored based on what mode is selected.
  Admins can also use this tool to see pens missing an upload, generate flight plans, and view flight plans
*/
export default class DashboardMapCard extends React.Component {

  constructor(props) {
    super(props);

    // info from props
    this.feedlot_id = props.feedlot_id

    // in use colors
    this.unselectedColor = "#00ff00" // green
    this.selectedColor = "#ff0000" // red
    
    // check for mobile use and modify views via this variable
    this.isMobile = detectMob()
    
    // state of class, changing these will re render the view
    this.state = {
      refpens: null, 
      selected_refpen: null,
      zoom: null, // tracked to update labels
      searchTxt: "",
    }

    this.mapContainerStyle = {
      width: "100%",
      height: "100%"
    }

    this.googleMap // stored map variable
    this.popupWindow // stored popupwindow - only used for mobile views
    this.viewChanged = false // temp variable to check if map panned or zoomed // the zoom state will be set once map is idle  
    
    this.cattleInAlleyBadge // reference to cattle in alley badge
    this.gatesOpenBadge // reference to gates Open badge
    this.missingPensBadge // reference to missing pens badge

    // function bindings
    this.searchTextChanged = this.searchTextChanged.bind(this)

    //input refs
    this.searchInput = React.createRef()
    this.changeNameInput = React.createRef()

    this.listener = this.subscribe()
  }

  componentDidMount () {}

  render () {
    try {
      var refpens = this.state.refpens
      var selected_refpen = this.state.selected_refpen
      var searchTxt = this.state.searchTxt
      
      if(refpens == null || refpens.length == 0) {
        return (
          <Card id="feedlot-map">
            <Card.Header>
              <Card.Title>
                Feedlot Map
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div style={{width: "100%", textAlign: "center", padding: "2rem"}}><b>
                {refpens == null ? "Initializing" : "Pen Boundaries Not Yet Uploaded"}              
              </b></div>
            </Card.Body>
          </Card>
        )
      }

      if (searchTxt != null || searchTxt != "") {
        refpens = refpens.filter((refpen,i)=> { // filter by search text
          if(refpen.name) {
            return refpen.name.toLowerCase().includes(searchTxt.toLocaleLowerCase())
          }
          return false
        })
      }

      // setup map options and basic controls
      var mapOptions = {}
      mapOptions.disableDefaultUI = true
      mapOptions.fullscreenControl = true
      mapOptions.gestureHandling = "greedy"
      mapOptions.mapTypeId = "satellite"
      if (this.isMobile == false) {
        mapOptions.zoomControl = true
        mapOptions.mapTypeControl = true
      }

      return (

        <Card id="feedlot-map">
          <Card.Header>
            <Container fluid className="p-0">
              <Row>
                <Col>
                  <Card.Title>
                    Map
                  </Card.Title>
                </Col>
                {selected_refpen != null &&
                  <Col xs="auto" key={selected_refpen.id}>
                    <div className="input-group">
                      <div className="input-group-prepend"> 
                        <span className="input-group-text">
                          Change Name
                        </span>
                      </div>
                      <input ref={this.changeNameInput} type="text" className="form-control" defaultValue={selected_refpen.name}/>
                      <div className="input-group-append"> 
                        <button className="input-group-text btn btn-primary" onClick={this.submitNameChange.bind(this,selected_refpen.id)}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </Col>
                }
              </Row>
            </Container>
            
          </Card.Header>
          <Card.Body style={{height: "70vh", display: "flex", flexFlow: "column", overflow: "hidden"}}>
            <div style={{height: "100%", display: "flex", overflow: "hidden"}}>
              <div style={{height: "100%", width: "200px", background: "gray", display: "flex", flexFlow: "column"}}>
                <div className="p-1">
                  <input ref={this.searchInput} type="text" className="form-control card-search-input" placeholder="Search" onChange={this.searchTextChanged}/>
                </div>

                <div style={{height: "100%", overflowY: "scroll"}} id="ref-pen-scroll-view">
                  {refpens.map((refpen, i) => {
                    return <div 
                      key={refpen.id}
                      id={"refpen_" + refpen.id}
                      className={"card-body-row " + (selected_refpen?.id == refpen.id ? "selected" : "")}
                      onClick={() => {
                        this.selecteRefPen(refpen)
                        this.googleMap.fitBounds(this.getWktBoundaries(refpen.boundary))
                      }}
                      >
                        <div style={{marginLeft: "0.5rem", fontSize: "1em"}}>
                          {refpen.name || "Undefined"}
                        </div>
                    </div>
                  })}
                </div>
              </div>

              {false && 
                <div style={{width: "100%", height: "100%"}}>

                </div>
              }
              
              {true && 
                <GoogleMap // setup custom controls, legends, calc viewport, event listeners
                  onLoad={(map) => { 
                    this.googleMap = map
                    this.googleMap.fitBounds(this.getFeedlotBounds())
                    this.centerMapControl()

                    var _this = this
                    
                    this.googleMap.addListener('zoom_changed', () => {
                      _this.viewChanged = true
                    });

                    this.googleMap.addListener('center_changed', () => {
                      _this.viewChanged = true
                    });
                    
                    this.googleMap.addListener('idle', () => {
                      var zoomLevel = _this.googleMap.getZoom();
                      if (_this.viewChanged) {
                        _this.viewChanged = false
                        _this.setState({zoom: zoomLevel})
                      }
                    })
                  }}
                  mapContainerStyle={this.mapContainerStyle}
                  options={mapOptions}
                >
                
                {refpens.map((refpen, i) => { // setup polygons
                  return <Polygon
                    key={refpen.id}
                    paths={this.wktToPath(refpen.boundary)}
                    options={this.generatePolygonOptions(refpen)}
                    onClick={(event) => {
                      if (this.isMobile == false) { // desktop opens pen page
                        this.selecteRefPen(refpen, true)
                      } else { 
                        // open info window on mobile
                        // clear previous window
                        var infoWindow = new google.maps.InfoWindow();
                        if (this.popupWindow != null) {
                          this.popupWindow.close()
                          this.popupWindow = null
                        }

                        // create info window and display
                        infoWindow.setContent(this.createPolygonWindowContentForRefpen(refpen));
                        var latLng = this.boundaryToTopCenterCoordinate(this.getWktBoundaries(refpen.boundary))
                        
                        infoWindow.setPosition(latLng);
                        infoWindow.open({
                          map: this.googleMap,
                          shouldFocus: false
                        });
                        this.popupWindow = infoWindow

                        this.selecteRefPen(refpen, true)
                      }
                    }}
                  />
                })}

                {refpens.filter((refpen,i)=> { // setup labels
                  // dont check if google maps has not initialized yet
                  if (this.googleMap == null) { 
                    return false
                  }

                  // don't display null names
                  if (refpen.name == null) {
                    return false
                  }

                  
                  // calculate boundaries
                  var frame = this.googleMap.getBounds()
                  var literalBounds = this.getWktBoundaries(refpen.boundary)
                  var bounds = new google.maps.LatLngBounds({lat: literalBounds.south, lng: literalBounds.west}, {lat: literalBounds.north, lng: literalBounds.east})

                  // check if label will be in bounds
                  // remove before doing more intense processing
                  if(frame.contains(bounds.getCenter()) == false) {
                    return false
                  }
                  
                  // project to pixels
                  var proj = this.googleMap.getProjection();
                  var SW = bounds.getSouthWest();
                  var NE = bounds.getNorthEast();
                  var swPx = proj.fromLatLngToPoint(SW);
                  var nePx = proj.fromLatLngToPoint(NE);
                  var pixelWidth = (nePx.x - swPx.x)* Math.pow(2, this.state.zoom);
                  //var pixelHeight = (nePx.y - swPx.y)* Math.pow(2, this.state.zoom);

                  // if pixel width of refpen is > 50 - accept
                  if(pixelWidth > 50) {
                    return true
                  }
                  return false
                }).map((refpen, i) => {
                  
                  // get position for label
                  var bounds = this.getWktBoundaries(refpen.boundary)
                  var center = new google.maps.LatLngBounds({lat: bounds.south, lng: bounds.west}, {lat: bounds.north, lng: bounds.east}).getCenter()
                  
                  // label
                  return <OverlayView
                    key={refpen.id}
                    position={center}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div style={{background: "#ffffffBB", borderRadius: "3px", padding: "2px", transform: "translate(-50%, -50%)"}}>
                      {refpen?.name || "Null"}
                    </div>
                  </OverlayView>
                  }
                )}
                </GoogleMap>
              }
            </div>
          </Card.Body>
        </Card>   
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::DashboardMapCardChannel",
        feedlot_id: this.feedlot_id
      },
      {  
        connected() {
          _this.getRefPens()
        },

        received(data) {
          if (data.refpens != null) {
            _this.setState({
              refpens: data.refpens
            })
          } else if(data.refpen != null) {
            _this.updateRefPen(data.refpen)
          } else if(data.message == "refpen_destroyed") {
            _this.removeRefPen(data.refpen_id)
          } else if(data.message == "refpen_added") {
            _this.getRefPen(data.refpen_id)
          } else if(data.message == "refpen_updated") {
            _this.getRefPen(data.refpen_id)
          }
        }
      }
    )
  }

  getRefPens() {
    this.listener.perform("get_refpens")
  }

  getRefPen(refpen_id) {
    this.listener.perform("get_refpen", {id: refpen_id})
  }
  
  removeRefPen(refpen_id) {
    this.setState(function(state, props) {
      return {
        refpens: state.refpens.filter((refpen) => refpen.id != refpen_id)
      }
    })
  }

  updateRefPen(refpen_to_update) {
    this.setState(function(state, props) {
      var refpens = state.refpens
      var index = refpens.findIndex((refpen) => refpen.id == refpen_to_update.id)
      if(index == -1) {
        refpens = [refpen_to_update, ...refpens]
      } else {
        refpens[index] = refpen_to_update
      }
      
      return {
        refpens: refpens
      }
    })
  }

  searchTextChanged() {
    this.setState({
      searchTxt: this.searchInput.current.value
    })
  }

  // create info window for refpen
  createPolygonWindowContentForRefpen(refpen) {
    var div = document.createElement("div")
    var penNameDiv = document.createElement("div")
    penNameDiv.innerHTML = "Name: " + (refpen?.name || "")
    
    div.appendChild(penNameDiv)
    
    return div
  }

  // get maps ready path from wkt
  wktToPath(wkt) {  
    var geometry = wktParser.parse(wkt)
    
    if(geometry.type == "Polygon") {
      var points = geometry.coordinates[0].map(function(point) {
        return {lat: point[1], lng: point[0]}
      })

      // remove last point, last point is the first point repeated
      points.pop()
      return points
    }

    return []
  }

  // get boundary for wkt
  getWktBoundaries(wkt) {
    var geometry = wktParser.parse(wkt)
    var n,s,e,w
    if(geometry.type == "Polygon") {
      for (var point of geometry.coordinates[0]) {
        n = ((n || point[1]) > point[1]) ? n : point[1]
        s = ((s || point[1]) < point[1]) ? s : point[1]
        e = ((e || point[0]) > point[0]) ? e : point[0]
        w = ((w || point[0]) < point[0]) ? w : point[0]
      }
    }
    return {north: n, south: s, east: e, west: w}
  }

  // calculated top center from boundary
  boundaryToTopCenterCoordinate(boundary) {
    return {lat: boundary.north, lng: boundary.west - (boundary.west - boundary.east)/2}
  }

  // get overall bounds of entire feedlot
  getFeedlotBounds() {
    var refpens = this.state.refpens
    var n,s,e,w
    for (var refpen of refpens) {
      var boundary = this.getWktBoundaries(refpen.boundary)
      n = ((n || boundary.north) > boundary.north) ? n : boundary.north
      s = ((s || boundary.south) < boundary.south) ? s : boundary.south
      e = ((e || boundary.east) > boundary.east) ? e : boundary.east
      w = ((w || boundary.west) < boundary.west) ? w : boundary.west
    }
    return {north: n, south: s, east: e, west: w}
  }

  // generate custom options for polygon based on selected view mode
  generatePolygonOptions(refpen) {
    var selected = this.state.selected_refpen?.id == refpen.id
    return {
      fillColor: selected ? this.selectedColor : this.unselectedColor ,
      fillOpacity: 0.2,
      strokeColor: selected ? this.selectedColor : this.unselectedColor,
      strokeOpacity: 1,
      strokeWeight: 1,
      clickable: true,
      draggable: false,
      editable: false,
      zIndex: 1
    }
  }

  selecteRefPen(refpen, scroll = false) {
    this.setState({
      selected_refpen: refpen
    })

    if (scroll) {
      this.scrollToRefPen(refpen?.id)
    }
  }

  scrollToRefPen(refpen_id) {
    var position =  $("#refpen_" + refpen_id).offset().top - $("#ref-pen-scroll-view").offset().top
    var currentScrollTop = $("#ref-pen-scroll-view")[0].scrollTop
    var height = $("#ref-pen-scroll-view")[0].offsetHeight

    var newPosition = position + currentScrollTop - (height*(1/3))
    
    $("#ref-pen-scroll-view").animate({
      scrollTop: newPosition
    });
  }

  submitNameChange(refpen_id) {
    var new_name = this.changeNameInput.current?.value
    if (new_name) {
      $.ajax({
        url: Routes.edit_ref_pen_name_feedlot_path(this.feedlot_id),
        type: "POST",
        data: {
          utf8: "&#x2713;",
          authenticity_token: form_authenticity_token(),
          ref_pen_id: refpen_id,
          new_name: new_name
        },
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", "Successfully Changed Pen Name")
          } else {
            postAlert("danger", "Failed To Update Pen Name")
          }
        },
        error: function(request, textStatus, errorThrown) {
          Sentry.capture_exception(errorThrown)
          postAlert("danger", "Failed To Update Pen Name")
        },
        complete: function() {}
      })
    } else {
      postAlert("danger", "Name Cannot Be Blank")
    }
  }

  // create and display the center map control
  centerMapControl() {
    var _this = this
    const div = document.createElement("div")
    const controlButton = document.createElement("button")
    controlButton.className = "maps-control-btn"
    controlButton.innerHTML = "<i class='fa fa-crosshairs'></i>"
    controlButton.title = "Click to recenter the map";
    controlButton.type = "button";
    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener("click",(event) => {
      _this.setState(
        this.googleMap.fitBounds(this.getFeedlotBounds())
      )
    })
    div.appendChild(controlButton)
    this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(div)
  }

  
}