import React from "react"
/*
  Used in Pen Edit views
  Box in the toolbar to edit the value of "Needs Reflown"
*/
class NeedsReflown extends React.Component {

  constructor(props) {
    super(props);

    this.pen_id = props.pen_id
    this.is_tutorial = props.is_tutorial || false

    this.state = {
      flagged: props.flagged,
      system_admin: props.system_admin,
    }

    this.confirmBox = React.createRef()

    this.customAlert = this.customAlert.bind(this)
  }

  render () {
    try {
      const flagged = this.state.flagged
      const system_admin = this.state.system_admin
      const is_tutorial = this.is_tutorial
      return (
        <React.Fragment>
          <div ref={this.confirmBox} className="custom-alert" style={{marginTop: "20px"}}>
            <div className="title">Needs Reflown? Are you sure?</div>
            <br/>
            <div className="message"> 
              Use only if you cannot see the entire boundary or cows are impossible to count.
              Be aware if you hit ok, an email will be sent immediately to initiate the pilot to refly.  
              Please explain why a re-fly is necessary in the comment box.
            </div>
            <br/>
            {is_tutorial &&
              <React.Fragment>
                <div className="message">
                  Since this is the tutorial, no email will be sent feel free to set this box as checked.
                </div>
                <br/>
              </React.Fragment>
            }
            <button className="no" style={{float: "left"}}>No</button>
            <button className="yes" style={{float: "right"}}>Yes</button>
          </div>
          <label style={{fontSize: "12px", marginBottom: "0px"}}>
            <b>Needs Reflown</b>
          </label>
          <br/>
          <label className="switch" style={{display: "inline-block", marginBottom: "0px"}}>
            {(system_admin == true || flagged == false || is_tutorial) &&
              <React.Fragment>
                <input id="needs_reflown_input" checked={flagged} type="checkbox" style={{display: "none"}} onClick={this.customAlert}/>
                <span className="toggler round red"/>
              </React.Fragment>
            }
            {!(system_admin == true || flagged == false) &&
              <React.Fragment>
              <input id="needs_reflown_input" checked={flagged} type="checkbox" style={{display: "none"}}/>
              <span className="toggler round red"/>
            </React.Fragment>
            }
          </label>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  // After selecting ok from the alert message, submit immedietly
  onFlagChanged() {
    const _this = this
    const flagged = this.state.flagged
    const pen_id = this.pen_id
    const form_authenticity_token = this.props.form_authenticity_token
    if(this.is_tutorial == false) {
      var jform = new FormData();
        jform.append('utf8',"&#x2713;");
        jform.append('authenticity_token',form_authenticity_token);
        jform.append('pen_id',pen_id);
        jform.append('needs_reflown_value', !flagged)
  
      $.ajax({
        url: `/counters/change_needs_reflown`,
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data', // this too
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          _this.setState({flagged: response.flagged})
        },
        error: function(request, textStatus, errorThrown) {
        },
        complete: function() {}
      })
    } else {
      _this.setState({flagged: !flagged})
    }
  }

  // Display an alert box letting user know to use with caution
  customAlert() {
    if(this.state.flagged == false) {
      var confirmBox = $(this.confirmBox.current);
    
      confirmBox.find(".yes").unbind().click(function() {
        confirmBox.hide();
      });
      confirmBox.find(".no").unbind().click(function() {
        confirmBox.hide();
      });
      confirmBox.find(".yes").click(() => {this.onFlagChanged()});
      confirmBox.show();
    } else {
      this.onFlagChanged() 
    }
  }
}
export default NeedsReflown
