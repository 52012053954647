import React from "react"
/*
  Custom Paginator User Interface
*/
export default class PaginatorUI extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: props.currentPage,
      totalPages: props.totalPages,
    }

    // function handler - takes params currentPage
    this.pageSelected = props.pageSelected || function (pageNumber) {}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentPage != this.props.currentPage) {
      this.setState({
        currentPage: this.props.currentPage,
      })
    }
    if (prevProps.totalPages != this.props.totalPages) {
      this.setState({
        totalPages: this.props.totalPages,
      })
    }
  }

  render() {
    const { currentPage, totalPages } = this.state

    if(currentPage == null || totalPages == null) {
      return null
    }

    // get pages to display.  
    //Examples. [1,2,3, null, 7,8,9, null, 12,13,14], [1,2,3, 8,9,10] 
    var pagesToDisplay = [currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2] // pages around current page
    pagesToDisplay = pagesToDisplay.concat([0]) // pages in the beginning
    pagesToDisplay = pagesToDisplay.concat([totalPages-1]) // pages in the beginning
    pagesToDisplay = pagesToDisplay.sort((a,b) => a-b) // sort ascending
    // remove duplicates, below 0, above totalPages
    pagesToDisplay = pagesToDisplay.filter((page, pos) => { return pagesToDisplay.indexOf(page) == pos && page >= 0 && page < totalPages })
    
    // insert null between pages that are not consecutive
    pagesToDisplay = pagesToDisplay.reduce((acc, page) => {
      if(acc.length > 0 && acc[acc.length-1] != null && acc[acc.length-1] != page-1) {
        acc.push(null)
      }
      acc.push(page)
      return acc
    }, [])
    

    return (
      <ul className="pagination m-0 pages-footer">
        {pagesToDisplay.map((val, i) =>
          <React.Fragment key={i}>
            {val != null &&
              <li className="page-item"><button className={"page-link" + (currentPage == val ? " page-selected" : "")} onClick={() => this.pageSelected(val)}>{val+1}</button></li>
            }
            { val == null &&
              <li className="mx-1">...</li>
            }
          </React.Fragment>
        )}
      </ul>
    )
  }
}