import React from "react"

/*
  Used in Pen Edit Views
  Small section in the toolbar that can edit the value of a pen's "needs_reviewed" column
*/
class FlagForReview extends React.Component {

  constructor(props) {
    super(props);

    this.pen_id = props.pen_id
    this.is_tutorial = props.is_tutorial || false

    this.state = {
      flagged: props.flagged // boolean
    }

    this.modal = React.createRef()
    this.messageInput = React.createRef()
  }

  render () {
    try {
      const flagged = this.state.flagged
      return (
        <React.Fragment>
          <label style={{fontSize: "12px", marginBottom: "0px"}}>
            <b>Flag for Review</b>
          </label>
          <br/>
          <label className="switch" style={{display: "inline-block", marginBottom: "0px"}}>
            <input id="needs_reviewed_input" checked={flagged} type="checkbox" style={{display: "none"}} onChange={() => this.onFlagChanged()}/>
            <span className="toggler round red"/>
          </label>

          <div ref={this.modal} id="flag-comment-modal" className="modal fade" role="dialog" tabIndex="-1" >
            <div className="modal-dialog" style={{marginTop: "5rem"}}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Append a comment to {flagged ? "unflag" : "flag"} this pen.</h5>
                  <button className="close" aria-label="Close" data-dismiss="modal" >
                    <span aria-hidden="true">x</span>
                  </button>
                </div>
                <div className="modal-body">
                  <input ref={this.messageInput} className="form-control" type="text" name="comment" placeholder="Add a comment ..." autoComplete="off" style={{fontWeight: "bold", fontSize: "13px",}}/>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                  <button className="btn btn-secondary" onClick={() => this.onSubmitFLagChange()}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  // open modal
  // modal contains a place to write comment
  onFlagChanged() {
    $(`#flag-comment-modal`).modal() 
  }

  // submit message with flag for review change
  onSubmitFLagChange() {
    
    const _this = this
    const messageInput = this.messageInput.current
    const flagged = this.state.flagged
    const pen_id = this.pen_id
    const message = messageInput.value
    const form_authenticity_token = this.props.form_authenticity_token
    if (Boolean(message)) {
      if(this.is_tutorial == false) {
        $(`#flag-comment-modal`).modal('hide')
        
        var jform = new FormData();
        jform.append('utf8',"&#x2713;");
        jform.append('authenticity_token',form_authenticity_token);
        jform.append('pen_id',pen_id);
        jform.append('message',message);
        jform.append('needs_reviewed_value', !flagged)
        
        $.ajax({
          url: `/counters/change_needs_reviewed`,
          type: "POST",
          data: jform,
          dataType: 'json',
          mimeType: 'multipart/form-data', // this too
          contentType: false,
          cache: false,
          processData: false,
          success: function(response) {
            var penComments = window.penComments
            var comments = penComments.state.comments
            comments.unshift({name: "You", time: dateToStr(new Date()), message: message})
            penComments.setState({sending_message: false, comments: comments})
            messageInput.value = ""
            _this.setState({flagged: !flagged})
            $(`#flag-comment-modal`).modal("hide") 
          },
          error: function(request, textStatus, errorThrown) {
            var penComments = window.penComments
            penComments.setState({sending_message: false})
          },
          complete: function() {}
        })
      }  else {
        var penComments = window.penComments
        var comments = penComments.state.comments
        comments.unshift({name: "You", time: dateToStr(new Date()), message: message})
        penComments.setState({sending_message: false, comments: comments})
        messageInput.value = ""
        _this.setState({flagged: !flagged})
        $(`#flag-comment-modal`).modal("hide") 
      }
    } else {
      alert("Must enter a comment")
    }
  }
}
export default FlagForReview
