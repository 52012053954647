import React from "react";
import {Container, Row, Col} from 'react-bootstrap' 

/*
  General Use Modal for editing pen fields
*/
export default class DuplicatePenModal extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      pen_id: null,
      pen_name: null,
      error_message: null,
      is_submitting: false,
    }

    this.listener = this.postalListener()

    this.nameInput = React.createRef()
    this.skip_counting_input = React.createRef()
    this.skip_quality_control_input = React.createRef()
    this.submit = this.submit.bind(this)
  }

  postalListener() {
    var _this = this
    return postal.subscribe({
      channel: "DuplicatePenModal",
      topic: "open",
      callback: function(data, envelope) {

        // two sets of set state here because we need the view to refresh so the inputs fields can be set to the new default values
        _this.setState({
          pen_id: null
        }, () => {
          _this.setState({
            pen_id: data.pen_id,
            pen_name: data.pen_name
          }, () => {
            if (_this.isValidState()) {
              $('#duplicate-pen-modal').modal("show");
            }
          })
        })
      }
    });
  }

  isValidState() {
    return this.state.pen_id != null
  }

  static close() {
    $('#duplicate-pen-modal').modal("hide");
  }

  render () {
    try {
      if (this.isValidState() == false) {
        return null
      }

      const error_message = this.state.error_message

      return (
        <div className="modal fade" id="duplicate-pen-modal" role="dialog" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered" style={{minWidth: "50vw"}}>
            <div className="modal-content">
              <div className="modal-header">
                <Container>
                  <Row>
                    <Col>
                      <h5 className="modal-title">
                        Duplicate Pen: {this.state.pen_name}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <button className="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">×</span>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid className="modal-body">
                
                {error_message != null && 
                  <Row>
                    <Col>
                      <h6 className="text-danger text-center">{error_message}</h6>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Pen Name
                        </span>
                      </div>
                      <input 
                        ref={this.nameInput}
                        defaultValue={this.state.pen_name}
                        type="text" 
                        className="form-control"/>
                    </div>
                  </Col>
                </Row>
                <Row><Col>
                  <div><i>
                    Note: This will duplicate the pen but not the associated data (comments/counting_history/etc). 
                    <br/>
                    It will include counts, picture, boundary, etc.
                    <br/>
                    The new pen will be removed from quality control and will not be counted by AI.
                    <br/>
                    The Geographical RefPen will not be set.  Needs Reflown will be set to False.
                    <br/>
                    <i className="text-danger">The new name cannot match the current name.</i>
                  </i></div>
                </Col></Row>
                <Row>
                  <Col>
                    <button className="btn btn-primary" onClick={this.submit}>
                      Submit
                    </button>
                  </Col>
                </Row>
                
              </Container>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  submit() {
    if (this.state.is_submitting) { return }
    var pen_id = this.state.pen_id
    var pen_name = this.nameInput.current.value
    //var skip_ai_counting = this.skip_counting_input.current.checked
    //var skip_quality_control = this.skip_quality_control_input.current.checked
    
    this.setState({is_submitting: true, })

    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('pen_name', pen_name);
    
    var _this = this
    $.ajax({
      url: Routes.duplicate_admin_pen_path(pen_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          window.location = Routes.admin_pen_path(response.pen_id)
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Create Pen",
        })
      },
      complete: function() {
        _this.setState({is_submitting: false})
      }
    })
  }
}


class DuplicatePen extends React.Component {

  
}