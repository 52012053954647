import React from "react"
import {Card, Container, Row, Col, NavLink} from 'react-bootstrap' 

export default class PenViewComponent extends React.Component {

  constructor(props) {
    super(props)

    this.job_id = props.job_id
    this.is_admin = props.is_admin || false
    this.is_mobile = props.is_mobile || false

    this.state = {
      pen_id: props.pen_id,
      pen_data: null,
      image_loaded: false,
      image_failed_to_load: false, // boolean
      no_image_found: false,
      data_failed_to_load: false // boolean
    }

    this.zoom = this.zoom.bind(this)
    this.down = this.down.bind(this)
    this.move = this.move.bind(this)
    this.up = this.up.bind(this)
    this.touchDown = this.touchDown.bind(this)
    this.touchMove = this.touchMove.bind(this)
    this.touchEnd = this.touchEnd.bind(this)
    this.zoomSliderChanged = this.zoomSliderChanged.bind(this)

    this.canvas = React.createRef()
    this.zoomRange = React.createRef()
    this.currentTouches = []

    this.localMessageSubscriber = this.subscribeToLocalMessages()

    this.getPenData()

    this.shouldSetupCanvas = false
  }

  componentDidUpdate() {
    if (this.shouldSetupCanvas == true) {
      this.shouldSetupCanvas = false
      

      if (this.state.no_image_found == false) {
        // need to wait for the modal to display before showing the image
        setTimeout(() => {  
          this.setupCanvas() 
        }, 700);
      }
    }
  }

  render () {
    try {
      var pen = this.state.pen_data
      
      var general_message = null
      var general_message_color = "black"
      var image_error_message = null
      var data_error_message = null

      if (this.state.no_image_found == true) {
        image_error_message = "No Image"
      } else if (this.state.image_failed_to_load == true) {
        image_error_message = "Failed To Load Image"
      }
      if (this.state.data_failed_to_load == true) {
        data_error_message = "Data Failed To Load"
      }

      if (image_error_message == null && data_error_message == null) {
        if (this.state.pen_id == null) {
          general_message = "Select A Pen From The List"
          general_message_color = "black"
        } else if (this.state.pen_data == null || this.state.image_loaded == false) {
          general_message = "Loading Image"
          general_message_color = "green"
        }
      }

      var shouldShowCanvas = general_message == null && image_error_message == null && data_error_message == null

      return (
        <React.Fragment>
          {general_message != null &&
            <h5 className="mt-2"style={{textAlign: "center", color: general_message_color}}>
              {general_message}
            </h5>
          }
          {image_error_message != null &&
            <h5 className="mt-2"style={{textAlign: "center", color: "red"}}>
              {image_error_message}
            </h5>
          }
          {data_error_message != null &&
            <h5 className="mt-2"style={{textAlign: "center", color: "red"}}>
              {data_error_message}
            </h5>
          }
          {shouldShowCanvas == true && 
            <React.Fragment>
              <div className="input-group mb-1 mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">Zoom</span>
                </div>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <input ref={this.zoomRange} type="range" min="0" max="100" defaultValue="0" className="slider" id="zoomRange" onChange={this.zoomSliderChanged}/>
                  </span>
                </div>
              </div>
              <div style={{height: "70vh"}}>
                <canvas ref={this.canvas} style={{width: "100%", height: "100%"}}/>
              </div>
            </React.Fragment>
          }
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  getPenData() {
    if (this.state.pen_id != null) {
      var _this = this

      $.ajax({
        url: `/pens/${this.state.pen_id}/data`,
        type: "GET",
        dataType: 'json',
        success: function(response) {
          if(response.pen != null) {
            _this.shouldSetupCanvas = true
            _this.setState({
              pen_data: response.pen,
              data_failed_to_load: false,
              no_image_found: response.pen.image_url == ""
            })
          } else {
            _this.setState({
              pen_data: null,
              data_failed_to_load: true
            })
          }
        },
        error: function(request, textStatus, errorThrown) {
          _this.setState({
            data_failed_to_load: true
          })
        },
        complete: function() {
        }
      })
    }
  }

  subscribeToLocalMessages() {
    var _this = this
    return postal.subscribe({
      channel: "PenViewComponent",
      topic: "pen_selection_changed",
      callback: function(data, envelope) {
        _this.setState({
          pen_id: data.pen_id,
          pen_data: null,
          image_loaded: false,
          image_failed_to_load: false,
          data_failed_to_load: false,
          no_image_found: false,
        }, () => {
          _this.getPenData()
        })
      }
    });
  }

  setupCanvas() {
    var pen_data = this.state.pen_data
    this.gkhead = new Image()
    this.gkhead.src = pen_data.image_url

    this.canvasHighlight = document.createElement('canvas')
    this.canvasBoundary = document.createElement('canvas')
    this.canvasDetections = document.createElement('canvas')
    this.scale = null
    this.panX = 0
    this.panY = 0
    this.imageScale = this.is_mobile ? 0.5 : 1
    
    var _this = this

    // stop observing all elements
    if (this.resizeObserver != null) {
      this.resizeObserver.disconnect()
    }

    // observer to watch the size changes of the canvas
    // resize when the size changes
    this.canvas_resize_observer = new ResizeObserver((entries) => { // 'entries' should be populated with the canvas element
      try {
        _this.resize()
        console.log("canvas resize")
      } catch(error) {
        console.log("Failed to resize canvas")
        // do nothing, resize prolly isn't defined yet or the canvas is not setup
      }
    });
    

    this.gkhead.addEventListener('error', function() {
      _this.setState({
        image_failed_to_load: true
      })
    },false)

    this.gkhead.addEventListener('load', function() {
      _this.imageWidth = this.width*_this.imageScale
      _this.imageHeight = this.height*_this.imageScale

      _this.setState({
        image_loaded: true,
        image_failed_to_load: false
      }, () => {

        _this.canvas.current.width = _this.canvas.current.clientWidth
        _this.canvas.current.height = _this.canvas.current.clientHeight

        /*if ((_this.imageWidth / _this.canvas.current.clientWidth) - (_this.imageHeight / _this.canvas.current.clientHeight) < 0) {
          _this.canvas.current.width = _this.imageWidth
          _this.canvas.current.height = _this.imageWidth / (_this.canvas.current.clientWidth / _this.canvas.current.clientHeight)
        } else {
          _this.canvas.current.width = _this.imageHeight * (_this.canvas.current.clientWidth / _this.canvas.current.clientHeight)
          _this.canvas.current.height = _this.imageHeight
        }*/

        _this.canvasHighlight.width = _this.imageWidth
        _this.canvasHighlight.height = _this.imageHeight
        
        _this.canvasBoundary.width = _this.imageWidth
        _this.canvasBoundary.height = _this.imageHeight

        _this.canvasDetections.width = _this.imageWidth
        _this.canvasDetections.height = _this.imageHeight

        _this.highlightPen()
        _this.drawDetections()

        _this.redrawCanvas()
        
        
        if (_this.is_mobile) {
          console.log("mobile")
          _this.canvas.current.addEventListener('touchstart',_this.touchDown)
          _this.canvas.current.addEventListener('touchmove',_this.touchMove)
          _this.canvas.current.addEventListener('touchend',_this.touchEnd)
          _this.canvas.current.addEventListener('mousemove',_this.move, false)
        } else {
          _this.canvas.current.addEventListener('mousedown',_this.down, false)
          _this.canvas.current.addEventListener('mousemove',_this.move, false)
          _this.canvas.current.addEventListener('mouseup',_this.up, false)
        }
        _this.canvas.current.addEventListener('wheel', _this.zoom, false);
        _this.canvas.current.addEventListener('DOMMouseScroll', _this.zoom, false);
        _this.canvas.current.addEventListener('mousewheel', _this.zoom, false);
      })

      _this.canvas.current.scrollIntoView({behavior: "smooth"})

      if (_this.canvas.current != null) {
        _this.canvas_resize_observer.observe(_this.canvas.current)
      }
      
    }, false);
  }

  redrawCanvas() {
    this.limitTransforms()

    var canvas = this.canvas.current
    var ctx = canvas.getContext('2d')
    ctx.fillStyle = "#EEEEEE"
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    ctx.drawImage(this.gkhead,this.panX,this.panY,this.imageWidth*this.scale,this.imageHeight*this.scale)
    ctx.drawImage(this.canvasDetections,this.panX,this.panY,this.canvasDetections.width*this.scale, this.canvasDetections.height*this.scale)
    ctx.drawImage(this.canvasHighlight,this.panX,this.panY,this.canvasHighlight.width*this.scale, this.canvasHighlight.height*this.scale)
  }

  resize() {
    var canvas = this.canvas.current
    if(canvas != null) {
      canvas.width = canvas.clientWidth
      canvas.height = canvas.clientHeight
      this.redrawCanvas()
    }
  }

  limitTransforms() {
    this.limitZoom()
    var canvas = this.canvas.current
      
    //keep image in view
    if (this.panX < -(this.imageWidth*this.scale-canvas.width))  {
      this.panX = -(this.imageWidth*this.scale-canvas.width)
    } else if (this.panX > 0) {
      this.panX = 0
    }
    if (this.panY < -(this.imageHeight*this.scale-canvas.height))  {
      this.panY = -(this.imageHeight*this.scale-canvas.height)
    } else if (this.panY > 0) {
      this.panY = 0
    }

    //center if image is smaller than canvas
    if (this.imageWidth*this.scale < canvas.width) {
      this.panX = (canvas.width - this.imageWidth*this.scale)/2
    }
    if (this.imageHeight*this.scale < canvas.height) {
      this.panY = (canvas.height - this.imageHeight*this.scale)/2
    }
  }

  limitZoom() {
    var canvas = this.canvas.current

    var shouldRetain = this.scale <= this.minScale
    var canvasRatio = canvas.width/canvas.height
    var imageRatio = this.imageWidth/this.imageHeight
    if(canvasRatio < imageRatio) {
      this.minScale = canvas.width/this.imageWidth
      this.maxScale = this.minScale*(this.is_mobile ? 30 : 10)
    } else {
      this.minScale = canvas.height/this.imageHeight
      this.maxScale = this.minScale*(this.is_mobile ? 30 : 10)
    }

    if(this.scale == null) {
      this.scale = this.minScale 
      return false
    }

    if (shouldRetain) {
      this.scale = this.minScale
      return false
    }

    if (this.scale <= this.minScale) {
      this.scale = this.minScale
    } else if(this.scale >= this.maxScale ) {
      this.scale = this.maxScale
    }

    return false
  }

  drawDetections() {
    var pen = this.state.pen_data
    var ctx = this.canvasDetections.getContext("2d")
    ctx.clearRect(0,0,this.canvasDetections.width, this.canvasDetections.height)
    var detection_size = pen.detection_size || 6
    for(var detection of pen.detections) {
      var shouldDisplay = true
      if (pen.boundary_points_x.length >= 3) {
        shouldDisplay = this.inside(detection)
      }

      if(shouldDisplay) {
        ctx.beginPath();
        ctx.fillStyle = "red"
        ctx.arc((detection.x*this.imageScale), (detection.y*this.imageScale), detection_size*this.imageScale, 0,2*Math.PI)
        ctx.fill()
      }
    }
  }  

  highlightPen() {
    var boundary_points_x = this.state.pen_data.boundary_points_x
    var boundary_points_y = this.state.pen_data.boundary_points_y
    var ctx = this.canvasHighlight.getContext("2d")
    if(boundary_points_x.length >= 3) {
      var previousType = ctx.globalCompositeOperation 
      ctx.fillStyle = "#00000088"
      ctx.rect(0,0,this.canvasHighlight.width,this.canvasHighlight.height)
      ctx.fill()
      ctx.globalCompositeOperation = "destination-out"
      ctx.fillStyle = "#FFFFFF"
      ctx.beginPath();
      for(var i in boundary_points_x) {
        var x = boundary_points_x[i]*this.imageScale
        var y = boundary_points_y[i]*this.imageScale
        if (i == 0) {
          ctx.moveTo(x,y)
        } else {
          ctx.lineTo(x,y)
        }
      }
      ctx.closePath();
      ctx.fill();
      ctx.globalCompositeOperation = previousType
    }
  }

  zoom(evt = null, customClicks = null, originX = null, originY = null) {
    console.log("zoom")
    var delta = customClicks
    if (evt != null) {
      delta = evt.wheelDelta ? evt.wheelDelta/200 : evt.detail ? -evt.detail : 0;
    }
    console.log("last: ", this.lastX, this.lastY)
    console.log("origin: ", originX, originY)
    var xPosition = (originX || this.lastX*this.imageScale || this.imageWidth/2)
    var yPosition = (originY || this.lastY*this.imageScale || this.imageHeight/2)
    
    if (delta) {
      var clicks = delta
      var scaleFactor = 1.1;
      var factor = Math.pow(scaleFactor,clicks);
      var old_scale = this.scale
      this.scale *= factor
      this.limitZoom()
      factor = this.scale / old_scale
      this.panX += (xPosition - (xPosition * factor))*old_scale
      this.panY += (yPosition - (yPosition * factor))*old_scale

      this.zoomRange.current.value = (this.scale - this.minScale)/(this.maxScale - this.minScale) * 100
      
      this.redrawCanvas()
    } 
    if (evt != null) {
      return evt.preventDefault() && false;
    } else {
      return false
    }
  }

  zoomSliderChanged() {
    
    var canvasbounds = this.canvas.current.getBoundingClientRect()

    var xPosition =  ((canvasbounds.width/2)-this.panX)/this.scale
    var yPosition =  ((canvasbounds.height/2)-this.panY)/this.scale

    var old_scale = this.scale
    var new_scale = ((this.maxScale - this.minScale) * (this.zoomRange.current.value / 100.0)) + this.minScale
    this.scale = new_scale
    var factor = this.scale / old_scale
    
    this.panX += (xPosition - (xPosition * factor))*old_scale
    this.panY += (yPosition - (yPosition * factor))*old_scale
    
    this.redrawCanvas()
  }

  down(evt) {
    console.log(this)
    evt.preventDefault();
    console.log("down")
    var canvas = this.canvas.current
    var offsetX, offsetY, pageX, pageY
    
    offsetX = evt.offsetX
    offsetY = evt.offsetY
    pageX = evt.pageX
    pageY = evt.pageY
    
    this.lastX = (((offsetX || (pageX - canvas.offsetLeft)) - this.panX)/this.scale)/this.imageScale;
    this.lastY = (((offsetY || (pageY - canvas.offsetTop)) - this.panY)/this.scale)/this.imageScale;

    this.startX = offsetX || (pageX - canvas.offsetLeft)
    this.startY = offsetY || (pageY - canvas.offsetTop)

    this.dragStart = true
  }

  move(evt){
    evt.preventDefault();
    var canvas = this.canvas.current
    var offsetX, offsetY, pageX, pageY, movementX, movementY
    
    offsetX = evt.offsetX
    offsetY = evt.offsetY
    pageX = evt.pageX
    pageY = evt.pageY
    movementX = evt.movementX
    movementY = evt.movementY
    
    this.lastX = (((offsetX || (pageX - canvas.offsetLeft)) - this.panX)/this.scale)/this.imageScale;
    this.lastY = (((offsetY || (pageY - canvas.offsetTop)) - this.panY)/this.scale)/this.imageScale;
    
    if (this.dragStart) {
      this.didPan = true
      
      this.panX += movementX || 0
      this.panY += movementY || 0
      
      this.redrawCanvas()
    }
  }

  up(evt) { 
    evt.preventDefault();
    this.didPan = false
    this.dragStart = false
  }

  touchDown(evt) {
    evt.preventDefault()
    var canvas = this.canvas.current
    var offsetX, offsetY, pageX, pageY
    
    var canvasRect = canvas.getBoundingClientRect();
    for (var touch of evt.changedTouches) {
      var newTouch = copyTouch(touch)
      newTouch.offsetX = newTouch.clientX - canvasRect.left
      newTouch.offsetY = newTouch.clientY - canvasRect.top
      newTouch.lastX = (((newTouch.offsetX || (newTouch.pageX - canvas.offsetLeft)) - this.panX)/this.scale)/this.imageScale;
      newTouch.lastY = (((newTouch.offsetY || (newTouch.pageY - canvas.offsetTop)) - this.panY)/this.scale)/this.imageScale;
      newTouch.startX = newTouch.offsetX || (newTouch.pageX - canvas.offsetLeft)
      newTouch.startY = newTouch.offsetY || (newTouch.pageY - canvas.offsetTop)
      newTouch.movementX = 0
      newTouch.movementY = 0

      this.currentTouches.push(newTouch)
    }
    
    pageX = this.currentTouches[0].pageX
    pageY = this.currentTouches[0].pageY
    offsetX = this.currentTouches[0].offsetX
    offsetY = this.currentTouches[0].offsetY

    this.dragStart = true
  }

  touchMove(evt) {
    evt.preventDefault();
    var canvas = this.canvas.current
    var offsetX, offsetY, pageX, pageY, movementX, movementY
    
    var canvasRect = canvas.getBoundingClientRect();
    for (var touch of evt.changedTouches) {
      let currentTouchIndex = currentTouchById(touch.identifier, this.currentTouches)
      if (currentTouchIndex >= 0 && currentTouchIndex < this.currentTouches.length) {
        var oldTouch = this.currentTouches[currentTouchIndex]
        var newTouch = copyTouch(touch)
        newTouch.offsetX = newTouch.clientX - canvasRect.left
        newTouch.offsetY = newTouch.clientY - canvasRect.top
        newTouch.lastX = (((newTouch.offsetX || (newTouch.pageX - canvas.offsetLeft)) - this.panX)/this.scale)/this.imageScale;
        newTouch.lastY = (((newTouch.offsetY || (newTouch.pageY - canvas.offsetTop)) - this.panY)/this.scale)/this.imageScale;
        newTouch.startX = oldTouch.startX
        newTouch.startY = oldTouch.startY
        newTouch.movementX = newTouch.offsetX - oldTouch.offsetX
        newTouch.movementY = newTouch.offsetY - oldTouch.offsetY
        this.currentTouches.splice(currentTouchIndex, 1, newTouch)
      }
    }

    if(this.dragStart) {
      if(this.currentTouches.length == 1) {
        this.didPan = true
        movementX = this.currentTouches[0].movementX
        movementY = this.currentTouches[0].movementY
        this.panX += movementX || 0
        this.panY += movementY || 0
        
      } else if(this.currentTouches.length > 1) {
        try {
          this.didPan = true
          var sumMovement = {x: 0, y: 0}
          var originalCenter = {x: 0, y: 0}
          var newCenter = {x: 0, y: 0}
          var originalDistanceSum = 0
          var newDistanceSum = 0
          for (var touch of this.currentTouches) {
            sumMovement.x += touch.movementX
            sumMovement.y += touch.movementY
            originalCenter.x += newTouch.offsetX - newTouch.movementX
            originalCenter.y += newTouch.offsetY - newTouch.movementY
            newCenter.x += touch.offsetX
            newCenter.y += touch.offsetY
          }
  
          originalCenter.x /= this.currentTouches.length
          originalCenter.y /= this.currentTouches.length
          newCenter.x /= this.currentTouches.length
          newCenter.y /= this.currentTouches.length
          sumMovement.x /= this.currentTouches.length
          sumMovement.y /= this.currentTouches.length
  
          for (var touch of this.currentTouches) {
            var originalX = touch.offsetX - touch.movementX
            var originalY = touch.offsetY - touch.movementY
            originalDistanceSum += Math.sqrt(Math.pow(originalX - originalCenter.x, 2) + Math.pow(originalY - originalCenter.y, 2))
            newDistanceSum += Math.sqrt(Math.pow(touch.offsetX - newCenter.x, 2) + Math.pow(touch.offsetY - newCenter.y, 2))
          }
  
          var distanceDifference =  newDistanceSum - originalDistanceSum
  
          this.panX += sumMovement.x
          this.panY += sumMovement.y
  
          var zoomOriginX = ((newCenter.x-this.panX)/this.scale)
          var zoomOriginY = ((newCenter.y-this.panY)/this.scale)

          console.log("sumMovement ", sumMovement.x, sumMovement.y)
          console.log("zoomOrigin ", zoomOriginX, zoomOriginY)
          console.log("distanceDifference ", distanceDifference)
  
          this.zoom(null, (distanceDifference)/20, zoomOriginX, zoomOriginY)
        } catch (e) {
          console.log(e)
        }
      }

      if(this.didPan) {
        this.redrawCanvas()
      }
    }
  }

  touchEnd(evt) {
    evt.preventDefault();

    for (var touch of evt.changedTouches) {
      let currentTouchIndex = currentTouchById(touch.identifier, this.currentTouches)
      if (currentTouchIndex >= 0 && currentTouchIndex < this.currentTouches.length) {
        this.currentTouches.splice(currentTouchIndex, 1);
      }
    }

    if (this.currentTouches.length == 0) {
      this.didPan = false
      this.dragStart = false
    }
  }

  inside(detection) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    var x = detection.x, y = detection.y;
    var pen = this.state.pen_data

    var inside = false;
    for (var i = 0, j = pen.boundary_points_x.length - 1; i < pen.boundary_points_x.length; j = i++) {
        var xi = pen.boundary_points_x[i], yi = pen.boundary_points_y[i];
        var xj = pen.boundary_points_x[j], yj = pen.boundary_points_y[j];

        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
  }

}