import ReactDOM from 'react-dom';
import React from 'react';
var ReactRailsUJS = require("react_ujs");

window.ReactDOM = ReactDOM
window.React = React

var componentRequireContext = require.context("components", true);
ReactRailsUJS.useContext(componentRequireContext);

// individual reacct components that are referenced in a non conventional way
import ImportPhotos from "../components/ImportPhotos"
window.ImportPhotos = ImportPhotos

import FlagForReview from "../components/FlagForReview"
window.FlagForReview = FlagForReview

import PenComments from "../components/PenComments"
window.PenComments = PenComments

import ReactTooltip from 'react-tooltip';
window.ReactTooltip = ReactTooltip
