import React from "react"

/*
  Shows up in pen editing views
  Draws the ref pen geometry on a small canvas
*/
class DrawPenBoundaries extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      shouldDisplay: true
    }

    this.mercatorLocation = props.lng != null ? turf.toMercator(turf.point([props.lng,props.lat])) : null
    this.angle = this.props.angle || 0
    this.boundaryData = []
    this.canvas = React.createRef()

    this.getBoundaryData()

  }

  render () {
    if (this.state.shouldDisplay) {
      return <canvas ref={this.canvas} style={{width: "100%", height: "100%"}}/>
    }
    return null
  }

  // Get the boundary data
  getBoundaryData() {
    const _this = this
    const user_id = this.props.user_id
    const feedlot_id = this.props.feedlot_id
    $.ajax({
      url: `/feedlots/${feedlot_id}/get_ref_pen_data`,
      type: "POST",
      data: {
        utf8: "&#x2713;",
        authenticity_token: form_authenticity_token(),
        user_id: user_id
      },
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.boundaryData = response.data
          if(_this.mercatorLocation == null) {
            const penBoundary = _this.getPenBoundary()
            
            if(penBoundary != null) {
              _this.mercatorLocation = turf.toMercator(_this.getCenterOfPen(penBoundary))
            }
          }

          if (_this.mercatorLocation) {
            _this.draw()
          } else {
            _this.setState({shouldDisplay: false})
          }
        }
      },
      error: function(response) {
      }
    })
  }

  // draw on the canvas based on the image location, ref_pen associated with the pen,
  // orientation of the drone, and height
  draw() {
    const ref_pen_id = this.props.ref_pen_id
    const pen = this.getPenBoundary()
    const penWidth = this.getPenWidth(pen, this.angle)
    const offsetX = this.mercatorLocation.geometry.coordinates[0]
    const offsetY = this.mercatorLocation.geometry.coordinates[1]
    const zoom = 1.4
    const canvas = this.canvas.current

    // set up canvas
    canvas.width = penWidth * zoom
    canvas.height = penWidth * zoom
    const width = canvas.width
    const height = canvas.height
    const angle = this.angle * -3.14159/180.0

    // setup context for drawing
    var ctx = canvas.getContext("2d");
    ctx.fillStyle = 'grey';
    ctx.fillRect(0,0,width,height)

    ctx.fillStyle = 'orange';
    ctx.strokeStyle = 'orange'
    
    ctx.translate(width/2, height/2)
    ctx.rotate(angle)
    ctx.translate(-width/2, -height/2)

    // draw each ref_pen, fills in the associated ref_pen
    for(var i = 0;i<this.boundaryData.length;i++) {
      var isThisPen = false
      var shape = turf.toMercator(this.boundaryData[i].geometry)
      if(this.boundaryData[i].id == ref_pen_id) {
        isThisPen = true
      }

      ctx.beginPath()
      if(shape.type == "Polygon") {
        for(var j = 0;j<shape.coordinates[0].length;j++) {
          var coordinate = shape.coordinates[0][j]
          
          coordinate[0] -= offsetX
          coordinate[1] -= offsetY
          coordinate[1] *= -1
          coordinate[0] += width/2
          coordinate[1] += height/2

          if(j == 0) {
            ctx.moveTo(coordinate[0], coordinate[1])
          } else {
            ctx.lineTo(coordinate[0], coordinate[1])
          }
        }
      }
      if (isThisPen) {
        ctx.fill()
      }
      ctx.closePath()
      ctx.stroke()
    }
  }

  // get the boundary of the ref_pen id associated with the pen
  getPenBoundary() {
    const ref_pen_id = this.props.ref_pen_id
    for(var i = 0;i<this.boundaryData.length;i++) {
      if(this.boundaryData[i].id == ref_pen_id) {
        return this.boundaryData[i]
      }
    }
    
    // return null if not found
    return null
  }

  // rotate to the angle of the drone and get the width.
  getPenWidth(pen, angle) {
    const offsetX = this.mercatorLocation.geometry.coordinates[0]
    const offsetY = this.mercatorLocation.geometry.coordinates[1]
    var shape = turf.toMercator(pen.geometry)

    if(shape.type == "Polygon") {
      for(var j = 0;j<shape.coordinates[0].length;j++) {
        var coordinate = shape.coordinates[0][j]
        coordinate[0] -= offsetX
        coordinate[1] -= offsetY
      }
    }
    var rotatedShape = turf.transformRotate(shape, parseFloat(angle))
    var bbox = turf.bbox(rotatedShape)
    var width = bbox[2] - bbox[0]
    return width
  }

  getCenterOfPen(pen) {
    var shape = pen.geometry
    console.log(shape)
    var bbox = turf.bbox(shape)
    console.log(bbox)
    var width = bbox[2] - bbox[0]
    var height = bbox[3] - bbox[1]
    
    return turf.point([bbox[0] + width/2.0, bbox[1] + height/2.0])
  }
}
export default DrawPenBoundaries
