import React from "react"

/*
  Modal to create/edit/delete a line item for a job
*/
class CreateLineItem extends React.Component {

  constructor(props) {
    super(props);

    this.job_id = props.job_id
    this.line_item = props.line_item
    console.log(this.line_item)
    this.rate = this.line_item == null ? 0.07 : this.line_item.rate

    this.button = React.createRef()
    this.rateInput = React.createRef()
    this.submitButton = React.createRef()
    this.removeButton = React.createRef()
    this.modal = React.createRef()
  }

  render () {
    try {
      return (
        <React.Fragment>
          <button ref={this.button} className="btn btn-primary" onClick={() => this.buttonpressed()} style={{marginBottom: "5px"}}>
            { this.line_item == null &&
              <div>Create Line Item</div>
            }
            { this.line_item != null &&
              <React.Fragment>
                <div>Fee: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.line_item.price)}</div>
                <div>Rate: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(round(this.line_item.rate, 2))}</div>
                <div>Update Line Item</div>
              </React.Fragment>
              
            }
          </button>
          <div ref={this.modal} className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    { this.line_item == null &&
                      <div>Create Line Item</div>
                    }
                    { this.line_item != null &&
                      <React.Fragment>
                        <div>Update Line Item</div>
                        <i className="fas fa-trash" style={{color: "#ff0000", cursor: "pointer", display: "inline"}} onClick={() => this.remove()}></i>
                      </React.Fragment>
                    }
                  </h5>
                  <button className="close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true">x</span>
                  </button>
                </div>
                <div className="modal-body">
                  <label>
                    Rate
                  </label>
                  <input ref={this.rateInput} className="form-control no-spinner" required type="number" autoComplete="off" defaultValue={round(this.rate, 2)}/>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  <button ref={this.submitButton} className="btn btn-secondary" onClick={() => this.submit()}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error);
      return null
    }
  }

  
  componentDidMount () {}

  // Open Modal
  buttonpressed() {
    event.cancelBubble = true;
    event.stopPropagation()

    $(this.modal.current).modal()
  }

  // Submit the line item 
  submit() {
    const path = this.line_item == null ? Routes.create_line_item_admin_job_path(this.job_id) : Routes.update_line_item_admin_job_path(this.job_id)
    const value = this.rateInput.current.value
    const job_id = this.job_id 

    if(value) {
      var jform = new FormData();
        jform.append('utf8',"&#x2713;");
        jform.append('authenticity_token',form_authenticity_token());
        jform.append('rate', value)
      $.ajax({
        url: path,
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if(response.status == "Success") {
            location.reload()
          } else {
            alert("Failed")
          }
        },
        error: function(request, textStatus, errorThrown) {
          alert("Failed")
        },
        complete: function() {
          
        }
      })
    } else {
      alert("Please enter a value")
    }
  }

  // Submit the line item 
  remove() {
    if (ask("Are you sure you want to delete this line item?")) {
      var jform = new FormData();
        jform.append('utf8',"&#x2713;");
        jform.append('authenticity_token',form_authenticity_token());
      $.ajax({
        url: Routes.remove_line_item_admin_job_path(this.job_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if(response.status == "Success") {
            location.reload()
          } else {
            alert("Failed")
          }
        },
        error: function(request, textStatus, errorThrown) {
          alert("Failed")
        },
        complete: function() {
          
        }
      })
    } 
  }
}

export default CreateLineItem
