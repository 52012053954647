import React from "react"
import Button from 'react-bootstrap/Button'

/*
  Used in Pen Edit Views
  Allows user to create comments
*/
class PenComments extends React.Component {

  constructor(props) {
    super(props);

    this.is_tutorial = props.is_tutorial || false


    this.state = {
      sending_message: false,
      comments: props.comments
    }

    this.messageInput = React.createRef()
  }

  render () {
    try {
      const sending_message = this.state.sending_message
      const comments = this.state.comments
      return (
        <React.Fragment>
          <div style={{display: "flex"}}>
            <input disabled={sending_message} ref={this.messageInput} className="form-control" type="text" name="comment" placeholder="Add a comment ..." autoComplete="off" style={{fontWeight: "bold", fontSize: "13px",}}/>
            <Button disabled={sending_message} className="btn btn-primary" onClick={() => this.onSubmit()} style={{marginLeft: "10px",display: "flex"}}>
              <i className="fa fa-paper-plane" aria-hidden="true"/>
            </Button>
          </div>
          
          {comments.map((value, index) => {
            console.log(index)
            const name = value.name
            const time = value.time
            const message = value.message
            return (
              <div style={{borderRadius: "5px", background: "#eeeeee", padding: "10px", marginTop: "10px", borderStyle: "solid", borderWidth: "1px", borderColor:"rgba(0, 0, 0, 0.125)"}} >
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{fontWeight: "bold", fontSize: "13px"}}>
                    {name}
                  </div>
                  <div style={{fontWeight: "bold", fontSize: "13px"}}>
                    {time}
                  </div>
                </div>
                <hr style={{marginTop:"5px", marginBottom:"5px"}}/>
                <div style={{fontSize: "13px"}}>
                  {message}
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  // Submit message to be saved under the pen
  onSubmit() {
    const objectInstance = this
    var form_authenticity_token = this.props.form_authenticity_token
    var user_id = this.props.user_id
    var pen_id = this.props.pen_id
    var message = this.messageInput.current.value

    if(this.is_tutorial == false) {
      this.setState({sending_message: true})

      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token);
      jform.append('user_id',user_id);
      jform.append('pen_id',pen_id);
      jform.append('message',message);
      
      $.ajax({
        url: `/counters/comment`,
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data', // this too
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          var flagForReview = window.flagForReview
          var comments = objectInstance.state.comments
          comments.unshift({name: "You", time: dateToStr(new Date()), message: message})
          objectInstance.setState({sending_message: false, comments: comments})
          objectInstance.messageInput.current.value = ""
          flagForReview.setState({flagged: response["flagged"]})
        },
        error: function(request, textStatus, errorThrown) {
          objectInstance.setState({sending_message: false})
        },
        complete: function() {
          
        }
      })
    } else {
      var flagForReview = window.flagForReview
      var comments = objectInstance.state.comments
      comments.unshift({name: "You", time: dateToStr(new Date()), message: message})
      objectInstance.setState({sending_message: false, comments: comments})
      objectInstance.messageInput.current.value = ""
      flagForReview.setState({flagged: true})
    }
    
  }
}
export default PenComments
