import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 
import DraggableScroll from '../../DraggableScroll'

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class ActiveCounters extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      status: "connecting",
      counters: [],
    }

    this.listener = this.subscribe()
  }

  render () {
    try {

      const { counters } = this.state
      
      return (
        <Card id="active-counters">
          <Card.Header>
            <Container fluid className="p-0">
              <Row className="justify-content-between">
                <Col xs="auto" className="p-0">
                  <Card.Title>
                    Active Counters
                  </Card.Title>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>
            <Container fluid className="py-1">
              <Row>
                {counters.map((counter) => {
                  return <CounterObject 
                    key={counter.id}
                    counter={counter}
                    kickCounter={(heart_beat_id) => {this.kickCounter(heart_beat_id)}}
                  />
                })}
              </Row>
            </Container>
          </Card.Body>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "AdminChannels::ActiveCountersChannel",
      },
      {  
        connected() {
          console.log("Connected")
          _this.getCounters()
        },

        received(data) {
          console.log("got data")
          console.log(data)
          if (data.counters != null) {
            _this.setState({
              status: "Ok",
              counters: data.counters
            })
          } else if (data.counter != null) {
            _this.updateCounter(data.counter)
          } else if (data.message == "heart_beat_updated") {
            _this.getCounter(data.counter_id)
          }
        },

        disconnected() {
          _this.setState({status: "disconnected"})
        }
      }
    )
  }

  getCounters() {
    this.setState({status: "loading"})
    this.listener.perform("get_counters")
  }

  getCounter(id) {
    this.listener.perform("get_counter", {id: id})
  }

  // remove counter from state
  removeCounter(id) {
    this.setState(function(state, props) {
      return {
        counters: state.counters.filter((counter) => counter.id != id)
      }
    })
  }

  // update counter in state
  updateCounter(counter_to_update) {

    // remove counter if counter doesn't have an associated pen && no run_count data
    if (counter_to_update.pen == null && counter_to_update.run_count == null) {
      this.removeCounter(counter_to_update.id)
      return
    }

  
    this.setState(function(state, props) {

      var exists = false
      var counters = state.counters.map((counter) => {
        if (counter.id == counter_to_update.id) {
          exists = true
          return counter_to_update
        } else {
          return counter
        }
      })
      counters = exists ? [...counters] : [...counters, counter_to_update]

      return {
        counters: counters
      }
    })
  }

  kickCounter(heart_beat_id){
    if (heart_beat_id == null) {
      return
    }
    if (ask("Are you sure you want to kick this user from the pen?")) {
      this.listener.perform("kick_counter", {heart_beat_id: heart_beat_id})
    }
  }
}

class CounterObject extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      counter: props.counter,
    }

    this.kickCounter = props.kickCounter
    console.log(this.kickCounter)
  }

  // props updated
  componentDidUpdate(prevProps) {
    if (prevProps.counter != this.props.counter) {
      this.setState({counter: this.props.counter})
    }
  }

  render () {
    try {
      const { counter } = this.state
      const { pen, job, feedlot, heart_beat, run_time, run_count } = counter

      const bg_color = pen == null ? "#EEE" : "white"

      var run_time_hours = 0, run_time_minutes = 0
      if (run_time != null) { // run_time is in minutes
        run_time_hours = Math.floor(run_time / 60)
        run_time_minutes = Math.floor((run_time - (run_time_hours*60)))
      }

      return (
        <Col xs="auto">
          <Card>
            <Card.Header>
              <a className="txt-sm link-light nowrap" href={Routes.admin_user_path(counter.id)}>
                {counter.name || counter.email || "Undefined"}
              </a>
              {heart_beat != null &&
                <div className="float-right">
                  <button className="btn text-danger btn-icon fas fa-ban"
                    data-tip data-for="kick-counter-tooltip"
                    onClick={() => {this.kickCounter(heart_beat.id)}}
                    />
                  <ReactTooltip id='kick-counter-tooltip' effect='solid' textColor="black">
                    <span>Force Kick User From Pen</span>
                  </ReactTooltip>
                </div>
              } 
            </Card.Header>
            <Card.Body style={{background: bg_color}}>
              <Container fluid className="txt-sm">
                <Row className="justify-content-between">
                  {pen != null &&
                    <Col xs="auto">
                      <div>
                        Pass: {pen?.passes + 1}
                      </div>
                      <div>
                        Minutes Spent: {heart_beat?.minutes_passed}
                      </div>
                    </Col>
                  }
                  <Col xs="auto">
                    <div>
                      Time Active: {run_time_hours}:{run_time_minutes}
                    </div>
                    <div>
                      Pens Counted: {run_count || 0}
                    </div>
                  </Col>
                </Row>
                {pen != null &&
                  <Row className="justify-content-between">
                    <Col xs="auto">
                      Pen: <a href={Routes.admin_pen_path(pen.id)}>{pen.name || "Undefined"}</a>
                    </Col>
                    <Col xs="auto">
                      Job: <a href={Routes.admin_job_path(job.id)}>{job.name}</a>
                    </Col>
                    <Col xs="auto">
                      Feedlot: <a href={Routes.admin_feedlot_path(feedlot.id)}>{feedlot.name}</a>
                    </Col>
                  </Row>
                }
                
              </Container>
            </Card.Body>
          </Card>
        </Col>
      )
      
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}