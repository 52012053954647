import React from "react"
/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class DraggableScroll extends React.Component {

  constructor(props) {
    super(props)

    this.childrenRefs = []

    this.mouseDown = false
    this.startX = null
    this.scrollLeft = false;
  }

  componentDidMount() {
    var _this = this
    var slider = this.childrenRefs[0].current
    if (slider != null) {
      let startDragging = function (e) {
        _this.mouseDown = true;
        _this.startX = e.pageX - slider.offsetLeft;
        _this.scrollLeft = slider.scrollLeft;
      };
      let stopDragging = function (event) {
        _this.mouseDown = false;
      };
      
      slider.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!_this.mouseDown) { return; }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - _this.startX;
        slider.scrollLeft = _this.scrollLeft - scroll;
      });
      
      // Add the event listeners
      slider.addEventListener('mousedown', startDragging, false);
      slider.addEventListener('mouseup', stopDragging, false);
      slider.addEventListener('mouseleave', stopDragging, false);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        {React.Children.map(this.props.children, (element, idx) => {
          var ref = React.createRef()
          this.childrenRefs.push(ref)
          return React.cloneElement(element, { ref: ref });
        })}
      </React.Fragment>
    )
  }



}