import React from "react"
import {Card, Container, Row, Col, Dropdown} from 'react-bootstrap' 

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class OrderedJobsCard extends React.Component {

  constructor(props) {
    super(props)

    this.feedlot_id = props.feedlot_id
    this.is_admin = props.is_admin || false

    this.state = {
      status: "Loading",
      page: null,
      total_pages: null,
      jobs: []
    }

    this.searchInput = React.createRef()
    this.feedlotListener = this.subscribe()

    this.currentWidth = window.innerWidth
    window.addEventListener("resize", () => {
      var previousWidth = this.currentWidth
      this.currentWidth = window.innerWidth
      if (this.currentWidth <= md_bp && previousWidth > md_bp) {
        this.forceUpdate()
      }
      if(this.currentWidth > md_bp && previousWidth <= md_bp) {
        this.forceUpdate()
      }
    });
  }

  render () {
    try {
      const is_admin = this.is_admin
      const total_pages = this.state.total_pages

      var condensed = this.currentWidth <= md_bp

      return (
        <Card id="ordered-jobs">
          <Card.Header>
            <Container fluid className="p-0">
              <Row className="justify-content-between row-gap-2" >
                <Col xs="auto" className="p-0">
                  <Card.Title>
                    Ordered Jobs
                  </Card.Title>
                  <a className="btn btn-primary ml-2 fas fa-plus" 
                    href={Routes.create_job_feedlot_path(this.feedlot_id)}
                    data-tip data-for="order-job"
                    style={{verticalAlign: "bottom", fontSize: "1em"}}/>
                  <ReactTooltip id='order-job' effect='solid' 
                     

                    textColor="black">
                    <span>Order Job</span>
                  </ReactTooltip>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>
            {this.state.jobs.length == 0 && 
              <div className="mt-2 text-center">
                <b>In Progress Jobs You've Ordered Will Display Here</b>
              </div>
            }
            {this.state.jobs.length > 0 && 
              <React.Fragment>
                {condensed == false && 
                  <table className="component-table">
                    <thead>
                      <tr>
                        <td>
                          Name
                        </td>
                        <td>
                          Flight Plan Ready
                        </td>
                        <td>
                          Images Uploaded
                        </td>
                        <td>
                          Pens Completed
                        </td>
                        <td>
                          Created At
                        </td>

                        {/* Menu Icon */}
                        {/*<td></td>*/}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.jobs.map((job,i) => 
                        <OrderedJobObject
                          key={job.id}
                          is_admin={is_admin}
                          job={job}
                          condensed={condensed}/>
                      )}
                    </tbody>
                  </table>  
                }
                {condensed == true && this.state.jobs.map((job,i) => 
                  <OrderedJobObject
                    key={job.id}
                    is_admin={is_admin}
                    job={job}
                    condensed={condensed}/>
                )}
              </React.Fragment>
            }
          </Card.Body>
          {total_pages != null && total_pages > 1 && 
            <Card.Footer className="pagination-section">
              <nav>
                <ul className="pagination m-0 pages-footer">
                  {[...Array(total_pages)].map((val, i) =>
                    <li className="page-item" key={i}><button className={"page-link" + (this.state.page == i ? " page-selected" : "")} onClick={() => this.getJobs(i)}>{i+1}</button></li>
                  )}
                </ul>
              </nav>
            </Card.Footer>
          }
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::OrderedJobsComponentChannel", 
        feedlot_id: this.feedlot_id
      },
      {  
        connected() {
          _this.getJobs(0)
        },

        received(data) {
          if (data.jobs != null) {
            _this.setState({
              status: "Ok",
              page: data.page,
              total_pages: data.total_pages,
              jobs: data.jobs
            })
          } else if (data.job != null) {
            _this.updateJob(data.job)
          } else if (data.message == "job_added") {
            _this.getJob(data.job_id)
          } else if (data.message == "job_removed") {
            _this.removeJob(data.job_id)
          } else if (data.message == "job_updated") {
            if (_this.state.jobs.filter((job) => job.id == data.job_id).length > 0) {
              _this.getJob(data.job_id)
            }
          }
        }
      }
    )
  }

  getJobs(page) {
    page = page || 0
    let search = this.searchInput?.current?.value || ""
    this.feedlotListener.perform("get_jobs", {
      page: page,
      search: search
    })
  }

  getJob(job_id) {
    this.feedlotListener.perform("get_job", {id: job_id})
  }

  removeJob(job_id) {
    this.setState(function(state, props) {
      return {
        jobs: state.jobs.filter((job) => job.id != job_id)
      }
    })
  }

  updateJob(job_to_update) {
    this.setState(function(state, props) {
      var jobs = state.jobs
      var index = jobs.findIndex((job) => job.id == job_to_update.id)
      if(index == -1) {
        jobs = [job_to_update, ...jobs]
      } else {
        jobs[index] = job_to_update
      }
      
      return {
        jobs: jobs
      }
    })
  }
}

class OrderedJobObject extends React.Component {

  constructor(props) {
    super(props);

    // static data
    this.is_admin = props.is_admin
    this.condensed = props.condensed
    
    this.state = {
      job: props.job
    }
  }

  // when pen is updated, update the state
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.job != this.props.job) {
      this.setState({
        job: nextProps.job
      })
      return true
    }
    if (nextProps.condensed != this.condensed) {
      this.condensed = nextProps.condensed
      return true
    }
    return false
  }

  render () {
    try {
      const is_admin = this.is_admin
      const job = this.state.job
      if(job == null) {
        return null
      }

      const job_path = is_admin ? Routes.admin_job_path(job.id) : Routes.job_path(job.id)
      
      if (this.condensed) { // mobile view
        return (
          <Container fluid className="condensed" style={{position: "relative"}}>
            <Row className="justify-content-between text-light condensed-header">
              <Col>
                <a className="txt-lg link-light nowrap" href={job_path}>
                  {job.name || "Undefined"}
                </a>
              </Col>
              <Col xs="auto" className="mr-3 nowrap">
                <div className="txt-lg">
                  {job.created_at}
                </div>
              </Col>
              {/*this.renderDropDownMenu({top: "0.2rem"})*/}
            </Row>
            <Row>
              <Col xs={12}>
                <table>
                  <tbody>
                    <tr>
                      <td className="pr-2 text-right">
                        Flight Plan Ready:
                      </td>
                      <td className="pr-2 text-center">
                       {job.flight_plan_uploaded_at != null &&
                          <a href="https://www.dronelink.com/" target="_blank">
                            Yes
                          </a>
                        }
                        {job.flight_plan_uploaded_at == null &&
                          <React.Fragment>
                            No
                          </React.Fragment>
                        }
                      </td>
                    </tr>
                      <tr>
                        <td className="pr-2 text-right">
                          Images Uploaded:
                        </td>
                        <td className="pr-2 text-center">
                          <button className="btn btn-link p-0" onClick={() => this.importPhotos()}>
                            {job.pens_with_images}/{job.pen_count}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2 text-right">
                          Pens Completed:
                        </td>
                        <td className="pr-2 text-center">
                          {job.pens_complete}/{job.pen_count}
                        </td>
                      </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        )
      } else {
        return (
          <tr>
            <td> {/* Name */}
              <a style={{width: "100%"}} href={job_path}>
                {job.name || "Undefined"}
              </a>
            </td>
  
            <td> {/* Flight Plan Ready */}
              {job.flight_plan_uploaded_at != null &&
                <a href="https://www.dronelink.com/" target="_blank">
                  Yes
                </a>
              }
              {job.flight_plan_uploaded_at == null &&
                <React.Fragment>
                  No
                </React.Fragment>
              }
            </td>
  
            <td> {/* Images Uploaded */}
              <button className="btn btn-link p-0" onClick={() => this.importPhotos()}>
                {job.pens_with_images}/{job.pen_count}
              </button>
            </td>
  
            <td> {/* Completed */}
              {job.pens_complete}/{job.pen_count}
            </td>
  
            <td style={{whiteSpace: "nowrap"}}> {/* Created At */}
              {job.created_at}
            </td>
  
            {/* Menu */}
            {/*<td className="menu">
              {this.renderDropDownMenu({position: "static"})}
            </td>*/}
  
          </tr>
        )
      }
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }


  importPhotos() {
    postal.publish({
      channel: "ImportPhotos",
      topic: "open",
      data: {
        job_id: this.state.job.id
      }
    })
  }

  renderDropDownMenu(style = {}) {
    const is_admin = this.is_admin
    const job = this.state.job
    const job_path = is_admin ? Routes.admin_job_path(job.id) : Routes.job_path(job.id)

    return (
      <Dropdown style={style}>
        <Dropdown.Toggle 
          as="i" 
          className="btn-icon fas fa-ellipsis-v dropdown-no-arrow txt-lg"
          bsPrefix="">
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => this.importPhotos()}>Upload Images</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
    
  }
}