import React from "react"
import {Container, Row, Col, Badge} from 'react-bootstrap' 
import ExifReader from 'exifreader';

/*
  Used to import photos to a job
*/
class ImportPhotos extends React.Component {

  constructor(props) {
    super(props);

    this.is_admin = props.is_admin || false

    this.state = {
      job_id: props.job_id,
      error_message: null,
      
      requested_ref_pens: null,
      pens_with_images: null,

      parsing: false,
      images_array: null,

      uploading: null,
      done: null,

      image_uuids_being_uploaded: null,
      images_uploading_count: null,

      constrainToRequested: true,
      key: 0,

      cancel: false,
    }
    this.imageInput = React.createRef();
    this.imageButton = React.createRef();
    
    this.constrainToRequestedInput = React.createRef();
    this.skipQualityControlInput = React.createRef();

    this.listener = this.postalListener()
  }


  postalListener() {
    var _this = this
    return postal.subscribe({
      channel: "ImportPhotos",
      topic: "open",
      callback: function(data, envelope) {
        _this.setState({
          job_id: data.job_id,
          error_message: null,
          requested_ref_pens: null,
          pens_with_images: null,
          parsing: false,
          images_array: null,
          uploading: null,
          done: null,
          image_uuids_being_uploaded: null,
          images_uploading_count: null,
          cancel: false
        }, () => {
          _this.getData()
          _this.open()
        })
      }
    });
  }

  open() {
    return $('#import-pens-modal').modal({backdrop: 'static'});
  }

  close() {
    if (this.imageInput.current != null) {
      this.setState((state) => {
        return {
          error_message: null,
          requested_ref_pens: null,
          pens_with_images: null,
          parsing: false,
          images_array: null,
          uploading: null,
          done: null,
          image_uuids_being_uploaded: null,
          images_uploading_count: null,
          key: state.key+1,
        }
      })
    }
    
    return $('#import-pens-modal').modal("hide");
  }

  closeModal() {
    if (this.state.uploading == true) {
      if (ask("Are you sure you want to leave? This will cancel the upload.")) {
        this.setState({cancel: true})
        this.close();
      }
    } else {
      this.close();
    }
  }

  render () {
    try {
      const is_admin = this.is_admin
      const error_message = this.state.error_message
      const images_array = this.state.images_array
      const ref_pens_without_pictures = this.refPensWithoutPictures()
      const pens_that_need_reflown = this.pensNeedsReflown()
      const pens_cannot_upload = this.pensCannotUpload()
      const images_with_parsing_errors = images_array?.filter(image_row => image_row.error != null)
      const images_with_parsing_warnings = images_array?.filter(image_row => image_row.warning != null)

      var pens_paired_with_image = []
      var pens_not_paired_with_image = []
      var ref_pens_paired_with_image = []
      var ref_pens_not_paired_with_image = []
      var image_paired_with_non_selected_ref_pen = []
      var images_parsed = images_array?.reduce((count, image_row) => image_row.parsed == true ? count + 1 : count, 0)
      var total_images = images_array?.length

      for (const pen of pens_that_need_reflown || []) {
        const image_row = this.imageFromRefPenId(pen.ref_pen_id)
        if(image_row == null) {
          pens_not_paired_with_image.push(pen)
        } else {
          pens_paired_with_image.push({pen: pen, image_row: image_row})
        }
      }

      for (const ref_pen of ref_pens_without_pictures || []) {
        const image_row = this.imageFromRefPenId(ref_pen.id)
        if(image_row == null) {
          ref_pens_not_paired_with_image.push(ref_pen)
        } else {
          ref_pens_paired_with_image.push({ref_pen: ref_pen, image_row: image_row})
        }
      }

      for (const image_row of images_array || []) {
        if (image_row != null && image_row.ref_pen_id != null) {
          var ref_pen_id = image_row.ref_pen_id
          if (this.state.requested_ref_pens.find(ref_pen => ref_pen.id == ref_pen_id) == null) {
            image_paired_with_non_selected_ref_pen.push(image_row)
          }
        }
      }

      const isUploading = this.state.uploading || false
      const done = this.state.done || false
      const isParsing = this.state.parsing
      const isRetry = this.isThisARetry()
      const images_to_upload = this.imagesToUpload()
      const ready_to_upload = images_to_upload.length > 0
      const disableSubmit = ready_to_upload == false || isUploading == true || isParsing == true
      const disableEverythingElse = isUploading == true || isParsing == true

      const uploading_images_total = this.numberOfImageRowsBeingUploaded()
      const uploading_images_attempted = this.numberOfImageRowsAttemptedToUpload()
      const uploading_images_failed = this.numberOfImageRowsUploadedFailed()
      const uploading_images_success = this.numberOfImageRowsUploaded()

      return (
        <div className="modal fade" id="import-pens-modal" role="dialog" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered" style={{minWidth: "50vw"}}>
            <div className="modal-content">
              <div className="modal-header">
                <Container> {/*Header - Contains Title and how to use info*/}
                  <Row>
                    <Col>
                      <h5 className="modal-title">
                        Upload Pictures
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <button className="close" aria-label="Close" onClick={()=>this.closeModal()}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <i style={{fontSize: "0.8em"}}>
                        <div>Select all the photos associated with this job, wait for the parsing to complete, and then select the 'Begin Upload'.</div>
                        <div>Please wait for the upload process to finish before exiting the page.</div>
                      </i>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid className="modal-body">
                <Row>
                  <Col> {/*Error Message and try again button*/}
                    {error_message != null &&                    
                      <h6 className="text-center text-danger">
                        {error_message}
                        <button
                          className="btn text-danger btn-icon fas fa-redo-alt" 
                          role="button" 
                          onClick={() => {this.getData()}}/>
                      </h6>
                    }
                    
                    {images_parsed != null && (images_parsed != total_images) && 
                      <h6 className="text-right">{`Parsing Images: ${images_parsed}/${total_images}`}</h6>
                    }
                    {ready_to_upload && isUploading == false &&
                      <h6 className="text-right">{`${images_to_upload.length} Images Ready To Upload`}</h6>
                    }
                    {isUploading &&
                      <React.Fragment>
                        <h6 className="text-right">{`Uploading: ${uploading_images_attempted || 0}/${uploading_images_total || 0}`}</h6>
                        {uploading_images_failed > 0 &&
                          <h6 className="text-right text-danger">{`Errors: ${uploading_images_failed}`}</h6>
                        }
                      </React.Fragment>
                    }
                    {(isUploading == false && isRetry == true) &&
                      <React.Fragment>
                        <h6 className="text-right">{`Uploaded Successfully: ${uploading_images_success || 0}/${uploading_images_total || 0}`}</h6>
                        {uploading_images_failed > 0 &&
                          <h6 className="text-right text-danger">{`Errors: ${uploading_images_failed}`}</h6>
                        }
                      </React.Fragment>
                    }
                  </Col>
                </Row>
                {(isUploading || done) && 
                  <Row className="mt-2 mb-2">
                    <Col>
                      <div className="progress">
                        <div className="progress-bar progress-bar bg-success" style={{width: `${(uploading_images_attempted/uploading_images_total)*100}%`}}/>
                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${(1 - (uploading_images_attempted/uploading_images_total))*100}%`}}/>
                      </div>
                    </Col>
                  </Row>
                }
                <Row> 
                  {is_admin &&
                    <Col xs="auto">
                      <div className="text-right">
                        <label style={{display: "inline-block", fontSize: "0.8em", marginBottom: "0px"}}> Skip Quality Control (Counters won't be alerted)</label>
                        <label className="switch ml-2 mb-0" style={{display: "inline-block"}}>
                          <input 
                            ref={this.skipQualityControlInput} 
                            defaultChecked={false}
                            type="checkbox"
                            style={{display: "none"}} 
                            disabled={disableEverythingElse}/>
                          <span className="toggler round red"/>
                        </label>
                      </div>
                      
                      <div className="text-right">
                        <label style={{display: "inline-block", fontSize: "0.8em", marginBottom: "0px"}}>Constrain To Requested Pens</label>
                        <label className="switch ml-2 mb-0" style={{display: "inline-block"}}>
                          <input 
                            ref={this.constrainToRequestedInput} 
                            defaultChecked={true} 
                            type="checkbox"
                            style={{display: "none"}} 
                            disabled={disableEverythingElse}
                            onChange={()=>{this.constrainToRequestedChanged()}}
                            />
                          <span className="toggler round red"/>
                        </label>
                      </div>
                    </Col>
                  }
                  <Col>
                    <input 
                      key={this.state.key}
                      ref={this.imageInput}
                      type="file" 
                      multiple='multiple' 
                      name="images[]" 
                      onChange={() => this.onInputChanged()}/>
                    <button 
                      ref={this.imageButton} 
                      className="btn btn-primary" 
                      disabled={disableEverythingElse}
                      onClick={() => this.imageInput.current.click()}>
                        Select Images
                    </button> 
                  </Col>
                  <Col xs="auto">
                    <button className="btn btn-primary"  onClick={() => {this.submit()}} disabled={disableSubmit}>
                      {(isUploading == false && done == false) && 
                        <div>Submit</div>
                      } 
                      {isUploading == true && 
                        <div>Uploading</div>
                      }
                      { (isUploading == false && done == true && isRetry == true) && 
                        <div>{(isRetry && uploading_images_failed > 0)  ? "Retry Upload" : "Done"}</div>
                      }
                    </button>
                  </Col>
                </Row>
                <Row><Col><hr/></Col></Row>
                {images_with_parsing_warnings?.length > 0 &&
                  <Row>
                    <Col>
                      <h6 className="text-center">
                        Images With Parsing Warnings: {images_with_parsing_warnings?.length}
                      </h6>
                      
                      {images_with_parsing_warnings?.map((image_row) => {
                        const variant = image_row.upload_result == null ? "warning" : (image_row.upload_result == "Success" ? "success" : "danger")
                        return <Badge variant={variant} className="mr-1">
                          <div>{image_row.file.name}</div>
                          {image_row.upload_result == null &&
                            <div>{image_row.warning}</div>
                          }
                          {image_row.upload_result != null &&
                            <div>{image_row.upload_message}</div>
                          }
                          
                        </Badge>
                      })}
                      <hr/>
                    </Col>
                  </Row>
                }
                {images_with_parsing_errors?.length > 0 &&
                  <Row className="justify-content-center">
                    <Col>
                      <h6 className="text-center">
                        Images With Parsing Errors: {images_with_parsing_errors?.length}
                      </h6>
                      
                      {images_with_parsing_errors?.map((image_row) => {
                        return <Badge variant="danger" className="mr-1">
                          <div>{image_row.file.name}</div>
                          <div>{image_row.error}</div>
                        </Badge>
                      })}
                      <hr/>
                    </Col>
                  </Row>
                }
                <Row className="justify-content-center">
                  <Col xs={6}>
                    <h6 className="text-center">
                      Pens Needing Image - {pens_that_need_reflown?.length + ref_pens_without_pictures?.length}
                    </h6>
                    {pens_paired_with_image?.map((row, index) => {
                      const pen = row.pen
                      const image_row = row.image_row
                      const variant = image_row.upload_result == null ? "warning" : (image_row.upload_result == "Success" ? "success" : "danger")
                      return <Badge variant={variant} className="import-photo-badge">
                        <div className="d-inline-block">
                          <i className="fas fa-camera mr-1"/>
                          <div>{pen.name}</div>
                          <div>{image_row.file.name}</div>
                        </div>
                        {image_row.upload_result == "Failed" &&
                          <div style={{fontSize: "1em"}}>
                            <div>{image_row.upload_message}</div>  
                          </div>
                        }
                        
                      </Badge>
                    })}

                    {ref_pens_paired_with_image?.map((row, index) => {
                      const ref_pen = row.ref_pen
                      const image_row = row.image_row
                      const variant = image_row.upload_result == null ? "warning" : (image_row.upload_result == "Success" ? "success" : "danger")
                      return <Badge variant={variant} className="import-photo-badge d-block">
                        <div className="d-inline-block">
                          <i className="fas fa-camera mr-1"/>
                          <div>{ref_pen.name}</div>
                          <div>{image_row.file.name}</div>
                        </div>
                        {image_row.upload_result == "Failed" &&
                          <div style={{fontSize: "1em"}}>
                            <div>{image_row.upload_message}</div>  
                          </div>
                        }
                        
                      </Badge>
                    })}

                    {image_paired_with_non_selected_ref_pen?.map((image_row, index) => {
                      const variant = image_row.upload_result == null ? "warning" : (image_row.upload_result == "Success" ? "success" : "danger")
                      return <Badge variant={variant} className="import-photo-badge d-block">
                        <i className="fas fa-camera mr-1"/>
                        <div className="d-inline-block">
                          <div>{image_row.ref_pen_name}</div>
                          <div>{image_row.file.name}</div>
                        </div>
                        {image_row.upload_result == "Failed" &&
                          <div style={{fontSize: "1em"}}>
                            <div>{image_row.upload_message}</div>  
                          </div>
                        }
                        
                      </Badge>
                    })}

                    {pens_not_paired_with_image?.map((pen, index) => {
                      return <Badge variant="danger" className="import-photo-badge d-block">
                        <div>{pen.name}</div>
                        <div>{"Needs Reflown"}</div>
                      </Badge>
                    })}

                    {ref_pens_not_paired_with_image?.map((ref_pen, index) => {
                      return <Badge variant="dark" className="import-photo-badge d-block">
                        <div>{ref_pen.name}</div>
                      </Badge>
                    })}

                  </Col>
                  {pens_cannot_upload?.length > 0 && 
                    <Col xs="6">
                      <h6 className="text-center">
                        Pens With Images - {pens_cannot_upload?.length} 
                      </h6>
                      {pens_cannot_upload?.map((pen, index) => {
                        return <Badge variant="success" className="import-photo-badge">
                          {pen.name}
                        </Badge>
                      })}
                    </Col>
                  }
                  
                </Row>
              </Container>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  // Get ref pen and upload pen data
  getData() {
    var _this = this

    this.setState({
      error_message: null,
    })

    $.ajax({
      url: `/jobs/${this.state.job_id}/data_for_image_import`,
      type: "GET",
      success: function(response) {
        if (response.status == "Success") {
          _this.setState({
            requested_ref_pens: response.requested_ref_pens,
            pens_with_images: response.pens_with_images,
            error_message: null,
          })
        }
      },
      error: function(request, textStatus, error) {
        _this.setState({
          requested_ref_pens: null,
          pens_with_images: null,
          error_message: "Error Occurred While Getting Pen Data"
        })
      },
      complete: function() {}
    })
  }

  // filter ref pens that don't have a picture
  refPensWithoutPictures() {
    const ref_pen_ids_with_images = this.state.pens_with_images?.map(pen => pen.ref_pen_id)
    return this.state.requested_ref_pens?.filter((ref_pen, index) => {
      return ref_pen_ids_with_images?.includes(ref_pen.id) == false
    })
  }

  // filter pens that need reflown
  pensNeedsReflown() {
    return this.state.pens_with_images?.filter(pen => pen.needs_reflown == true)
  }

  // pen already has image and doesn't need reflown
  pensCannotUpload() {
    return this.state.pens_with_images?.filter(pen => pen.needs_reflown == false && pen.picture_attached)
  }

  imageFromRefPenId(ref_pen_id) {
    for (var image_row of this.state.images_array || []) {
      if (image_row.ref_pen_id == ref_pen_id) {
        return image_row
      }
    }
    return null
  }

  imagesToUpload() {
    const images_array = this.state.images_array
    //const ref_pens_without_pictures = this.refPensWithoutPictures()
    //const pens_that_need_reflown = this.pensNeedsReflown()
    var images_to_upload = []
    
    

    for (const image_row of images_array || []) {
      if (this.state.constrainToRequested == false) {
        if (image_row.override_upload != true) { // block upload even though constrain was set to false. Pen already contains images
          images_to_upload.push(image_row)
        }
      } else if (image_row.ref_pen_id != null && image_row.error == null) {
        images_to_upload.push(image_row)
      }
    }

    /*for (const pen of pens_that_need_reflown || []) {
      const image_row = this.imageFromRefPenId(pen.ref_pen_id)
      if(image_row == null) {

      } else {
        images_to_upload.push(image_row)
      }
    }

    for (const ref_pen of ref_pens_without_pictures || []) {
      const image_row = this.imageFromRefPenId(ref_pen.id)
      if(image_row == null) {
        
      } else {
        images_to_upload.push(image_row)
      }
    }*/

    images_to_upload = images_to_upload.filter((image_row) => image_row.upload_result == null || image_row.upload_result == "Failed")

    return images_to_upload
  }

  isThisARetry() {
    return this.state.image_uuids_being_uploaded != null
  }

  
  getImageRowsBeingUploaded() {
    var uuids = this.state.image_uuids_being_uploaded
    if (uuids != null) {
      return this.state.images_array?.filter(image_row => uuids.includes(image_row.uuid))
    }
    return null
  }

  numberOfImageRowsAttemptedToUpload() {
    return this.getImageRowsBeingUploaded()?.filter(image_row => image_row.upload_result != null)?.length
  }

  numberOfImageRowsUploaded() {
    return this.getImageRowsBeingUploaded()?.filter(image_row => image_row.upload_result == "Success")?.length
  }

  numberOfImageRowsUploadedFailed() {
    return this.getImageRowsBeingUploaded()?.filter(image_row => image_row.upload_result == "Failed")?.length
  }

  numberOfImageRowsBeingUploaded() {
    return this.getImageRowsBeingUploaded()?.length
  }

  updateImageRow(image_rows, image_row) {
    var index = image_rows.findIndex((img_row) => img_row.uuid == image_row.uuid);
    if(index != -1) {
      image_rows[index] = image_row
    }
    return image_rows
  }

  constrainToRequestedChanged() {
    var value = this.constrainToRequestedInput?.current?.checked
    if (value == null) {
      value = true
    }
    this.setState({
      constrainToRequested: value
    }, () => {this.onInputChanged()})
    
  }

  // Update view when images are selected
  async onInputChanged() {
    var constrain_to_requested = this.is_admin ? this.state.constrainToRequested : true
    var imageInput = this.imageInput.current
    var images_array = []

    for(var i = 0;i<imageInput?.files?.length || 0; i++) {
      images_array.push({uuid: stringToUUID(), file: imageInput.files[i], upload_result: null})
    }
    this.setState({
      error_message: null,
      uploading: null,

      images_array: images_array,
      parsing: false,

      uploading: null,
      done: null,
      image_uuids_being_uploaded: null,
      images_uploading_count: null,
      
    })

    this.getData()

    for(var image_row of images_array) {
      if (this.state.cancel == false) {
        var updated_image_row = await this.getPossibleRefPens(image_row, constrain_to_requested)
        if (constrain_to_requested == false && updated_image_row.override_upload != true) {
          updated_image_row.warning = updated_image_row.error
          updated_image_row.error = null
        }
        updated_image_row.parsed = true
        this.setState((state) => {
          var previous_images_array = state.images_array
          var index = previous_images_array.findIndex((img_row) => img_row.uuid == updated_image_row.uuid);
          if(index != -1) {
            previous_images_array[index] = updated_image_row
          }
          return {
            images_array: previous_images_array
          }
        })
      }
    }
  }

  // Start submitting all the files one at a time
  async submit() {
    var skip_quality_control = this.is_admin ? this.skipQualityControlInput.current.checked : false
    var constrain_to_requested = this.is_admin ? this.constrainToRequestedInput.current.checked : true

    var images_to_upload = this.imagesToUpload().map(image_row => {
      image_row.upload_result = null
      image_row.upload_message = null
      return image_row
    })

    var image_uuids_being_uploaded = images_to_upload.map(image_row => image_row.uuid)

    this.setState((state) => {
      var old_images_array = state.images_array
      for (var image_row of images_to_upload) {
        old_images_array = this.updateImageRow(old_images_array, image_row)
      }
      return {
        uploading: true,
        done: false,
        images_array: old_images_array,
        image_uuids_being_uploaded: image_uuids_being_uploaded
      }
    })

    if (skip_quality_control == false) {
      this.notify_upload(images_to_upload.length)
    }
    
    var successful_count = 0
    var error_count = 0
    const total_count = images_to_upload.length

    for(var image_row of images_to_upload) {
      if (this.state.cancel == false) {
        var updated_image_row = (await this.importPicture(image_row, skip_quality_control, constrain_to_requested))
        if (updated_image_row.upload_result == "Success") {
          successful_count += 1
        } else if (updated_image_row.upload_result == "Failed") {
          error_count += 1
        }
        
        this.setState((state) => {
          var old_images_array = state.images_array
          old_images_array = this.updateImageRow(old_images_array, updated_image_row)
          return {
            images_array: old_images_array
          }
        })
      }
    }

    if (skip_quality_control == false) {
      this.notify_upload_completed(successful_count, error_count, total_count)
    }
    

    var _this = this

    this.setState({
      uploading: false,
      done: true,
      cancel: false,
    })

    if (error_count == 0) {
      setTimeout(() => {
        _this.close()
      }, 3000)
    }

  }

  // Submit one file and its associated parsed data
  async importPicture(image_row, skip_quality_control = false, constrain_to_requested = true) {
    var _this = this

    try {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      
      jform.append('file',image_row.file);
      jform.append('file_name',image_row.file.name);
      jform.append('name',image_row.ref_pen_name || image_row.file.name);
      jform.append('date_taken', image_row.dateTaken)
      jform.append('lat', image_row.lat)
      jform.append('lng', image_row.lng)
      jform.append('absAlt', image_row.absAlt)
      jform.append('relAlt', image_row.relAlt)
      jform.append('gRoll', image_row.gRoll)
      jform.append('gYaw', image_row.gYaw)
      jform.append('gPitch', image_row.gPitch)
      jform.append('fRoll', image_row.fRoll)
      jform.append('fYaw', image_row.fYaw)
      jform.append('fPitch', image_row.fPitch) 
      jform.append('ref_pen_id', image_row.ref_pen_id)
      jform.append('possible_ref_pen_ids', image_row.possible_ref_pen_ids)
      jform.append('skip_quality_control', skip_quality_control)
      jform.append('constrain_to_requested', constrain_to_requested)
    
      await $.ajax({
        url: Routes.import_picture_job_path(_this.state.job_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data', // this too
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          image_row.upload_result = response.status
          image_row.upload_message = response.message
        },
        error: function(request, textStatus, errorThrown) {
          image_row.upload_result = "Failed"
          image_row.upload_message = "Failed"
        },
        complete: function() {}
      })
    } catch(err) {
      image_row.upload_result = "Failed"
      image_row.upload_message = "Failed"
    }

    return image_row
  }

  // Send a notification that an upload is occurring
  notify_upload(count_being_uploaded) {
    
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('count', count_being_uploaded)

    $.ajax({
      url: Routes.photos_uploaded_started_job_path(this.state.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        
      },
      error: function(request, textStatus, errorThrown) {
        
      },
      complete: function() {
        
      }
    })
  }

  notify_upload_completed(successful_count, error_count, total_count) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('successful_count', successful_count)
    jform.append('error_count', error_count)
    jform.append('total_count', total_count)

    $.ajax({
      url: Routes.photos_uploaded_job_path(this.state.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        
      },
      error: function(request, textStatus, errorThrown) {
        
      },
      complete: function() {
        
      }
    })
  }

  async getPossibleRefPens(image_row, constrain_to_requested = true) {
    var _this = this
    
    image_row = await this.parseImage(image_row)

    // return image with error
    if(image_row.error != null) {
      return image_row
    }

    // return image with error if no location data found
    if (image_row.lat == null || image_row.lng == null) {
      image_row.error = "No Lat/Lng Data Found"
      return image_row
    }

    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('lat', image_row.lat)
    jform.append('lng', image_row.lng)
    jform.append('constrain_to_requested', constrain_to_requested)
    await $.ajax({
      url: `/jobs/${_this.state.job_id}/possibleRefPens`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data', // this too
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          var possible_ref_pens = response.possible_ref_pens
          if (possible_ref_pens.length > 0) {
            image_row.ref_pen_id = possible_ref_pens[0].id
            image_row.ref_pen_name = possible_ref_pens[0].name
            var matched_pen = _this.state.pens_with_images?.find(pen => pen.ref_pen_id == image_row.ref_pen_id)
            if (matched_pen != null && matched_pen.needs_reflown == false) {
              image_row.error = "Pen Already Has An Attached Image"
              image_row.override_upload = true
            }
          } else {
            image_row.error = "No Valid Pens Found For This Image"
          }
        } else {
          image_row.error = response.message
        }
      },
      error: function(request, textStatus, errorThrown) {
        image_row.error = "Error Occured When attempting to Match Image to Pen"
      },
      complete: function() {
        return image_row
      }
    })

    return image_row
  }

  // parse Images for meta data
  async parseImage(image_row) {
    try {
      if (validate_file_size(image_row.file)) {
        const tags = await ExifReader.load(image_row.file, {expanded: true, includeUnknown: true});

        let exif_data = tags.exif
        let gps_data = tags.gps
        let xmp_data = tags.xmp
        if (exif_data != null) {
          try { image_row.dateTaken = exif_data.DateTimeOriginal?.description } catch (e) {}
        }

        if (gps_data != null) {
          try { image_row.lat = gps_data.Latitude } catch (e) {}
          try { image_row.lng = gps_data.Longitude } catch (e) {}
          try { image_row.absAlt = gps_data.Altitude } catch (e) {} 
        }

        if (xmp_data != null) {
          try { image_row.relAlt = parseFloat(xmp_data.RelativeAltitude?.value) } catch (e) {}
          try { image_row.gRoll = parseFloat(xmp_data.GimbalRollDegree?.value) } catch (e) {}
          try { image_row.gYaw = parseFloat(xmp_data.GimbalYawDegree?.value) } catch (e) {}
          try { image_row.gPitch = parseFloat(xmp_data.GimbalPitchDegree?.value) } catch (e) {}
          try { image_row.fRoll = parseFloat(xmp_data.FlightRollDegree?.value) } catch (e) {}
          try { image_row.fYaw = parseFloat(xmp_data.FlightYawDegree?.value) } catch (e) {}
          try { image_row.fPitch = parseFloat(xmp_data.FlightPitchDegree?.value) } catch (e) {}
        }
      } else {
        image_row.error = "File Exceeded Max File Size"
      }
    } catch (err) {
      console.log(err)
      image_row.error = "Could Not Parse Image Data"
      return image_row
    }
    return image_row
  }
}

export default ImportPhotos
