import React from "react"
import {Card, Container, Row, Col, Dropdown, Badge, Button} from 'react-bootstrap' 
import CreateLineItem from "../../CreateLineItem"
import FlightPlanStatus from '../admin_components/FlightPlanStatus';

export default class JobDescriptionCard extends React.Component {

  constructor(props) {
    super(props)

    this.tally_icon_path = props.tally_icon_path
    this.hc_icon_path = props.hc_icon_path
    this.difference_icon_path = props.difference_icon_path
    this.pens_icon_path = props.pens_icon_path
    this.images_icon_path = props.images_icon_path
    this.upload_images_icon_path = props.upload_images_icon_path

    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id
    this.is_admin = props.is_admin || false
    
    this.state = {
      job: null
    }

    this.getJob = this.getJob.bind(this)
    this.listener = this.subscribe()

    this.uploadCSV = this.uploadCSV.bind(this)
    this.createPen = this.createPen.bind(this)
    this.editName = this.editName.bind(this)
    this.editPasses = this.editPasses.bind(this)
    this.setSkipQualityControl = this.setSkipQualityControl.bind(this)
    this.setArchive = this.setArchive.bind(this)
    this.setStatus = this.setStatus.bind(this)
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  render () {
    try {
      var job = this.state.job
      if(job == null) {
        return null
      }

      var is_admin = this.is_admin
      var flight_plans = job.flight_plans
      var job_path = is_admin ? Routes.admin_job_path(this.job_id) : Routes.job_path(this.job_id)
      var feedlot_path = is_admin ? Routes.admin_feedlot_path(this.feedlot_id) : Routes.feedlot_path(this.feedlot_id)

      var status_info = {title: "", on: null, color_class: ""}
      if (job.finished == true) {
        status_info = {title: "Complete", on: true, color_class: "text-success"}
      } else {
        status_info = {title: "In Progress", on: false, color_class: ""}
      }

      const can_upload = is_admin == true || job.finished == false
      const can_export = is_admin == true || job.finished == true

      return (
        <Card>
          <Card.Header>
            <Container fluid className="pl-0">
              <Row className="pr-4">
                <Col className="p-0">
                  <Card.Title style={{minWidth: "max-content", display: "block"}} className="m-0">
                    <div>
                      <a href={job_path} style={{color: "inherit"}}>{`Job: ${job.name}`}</a>
                    </div>
                    
                    <div className="m-0" style={{fontSize: "0.7em"}}>
                      <a href={feedlot_path} style={{color: "inherit"}}>{`Feedlot: ${job.feedlot_name}`}</a>
                    </div>
                  </Card.Title>
                </Col>
                <Col xs="auto" className="p-0">
                  <span className="label">
                    Created At:
                  </span>
                  <span className="field"> 
                    {job.created_at}
                  </span>
                </Col>
              </Row>
              <Row className="row-gap-1 justify-content-between" >
                <Col xs="auto" className="p-0 mt-auto">
                  <span className="nowrap" style={{fontSize: "1.1em"}}>
                    <span className="pr-1">
                      <b>Status:</b>
                    </span>
                    <span className={status_info.color_class}>
                      {status_info.title}
                    </span>
                    {is_admin == true && 
                      <React.Fragment>
                        {status_info.on == false &&
                          <React.Fragment>
                            <i className="btn-icon fas fa-toggle-off ml-2" 
                              data-tip data-for="set-status-tooltip"
                              onClick={() => {this.setStatus(true)}}/>
                            <ReactTooltip id='set-status-tooltip' effect='solid' 
                               

                              textColor="black">
                              <span>Set Status To Complete</span>
                            </ReactTooltip>
                          </React.Fragment>
                        }
                        {status_info.on == true &&
                          <React.Fragment>
                            <i className="btn-icon fas fa-toggle-on text-success ml-2"
                              data-tip data-for="set-status-tooltip"
                              onClick={() => {this.setStatus(false)}}/>
                            <ReactTooltip id='set-status-tooltip' effect='solid' 
                               

                              textColor="black">
                              <span>Set Status To In Progress</span>
                            </ReactTooltip>
                        </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  </span>
                </Col>
                <Col xs="auto" className="p-0">
                  <div className="nowrap">
                    <div data-tip data-for="upload-images-tooltip" className="d-inline-block">
                      <button id="upload-images-btn" className={"btn btn-primary fa txt-lg py-1 px-2"}
                        disabled={!can_upload}
                        onClick={() => this.importPhotos()}>
                        <img src={this.upload_images_icon_path} className="scale" style={{filter: "invert(100%)"}}/>
                      </button>
                    </div>
                    <ReactTooltip id='upload-images-tooltip' effect='solid' 
                       

                      textColor="black">
                      <span>Upload Images to Pens</span>
                      {!can_upload && 
                        <div>
                          <i>Feature Disabled When Job is Completed</i>
                        </div>
                      }
                    </ReactTooltip>
                    <div data-tip data-for="export-csv-tooltip" className="d-inline-block">
                      <a id="export-pen-data-btn" className={"btn btn-primary fas fa-file-download m-1 txt-lg py-1 px-2" + (!can_export ? "disabled" : "")}
                        href={Routes.export_csv_job_path(this.job_id)}
                        data-method="post"/>
                    </div>
                    <ReactTooltip id='export-csv-tooltip' effect='solid' 
                      textColor="black">
                      <span>Download CSV of Pen Info</span>
                      {!can_export && 
                        <div>
                          <i>Feature Enabled When Job is Completed</i>
                        </div>
                      }
                    </ReactTooltip>
                  </div>
                  
                </Col>
              </Row>
            </Container>
            <Dropdown>
              <Dropdown.Toggle 
                as="i" 
                className="btn-icon fas fa-ellipsis-v dropdown-no-arrow"
                bsPrefix=""
                style={{fontSize: "1.5em"}}>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.editName}>
                  Edit Job Name
                </Dropdown.Item>
                {is_admin == true &&
                  <React.Fragment>
                    <hr className="text-primary"/>
                    <Dropdown.Item href={Routes.stats_admin_job_path(this.job_id)}>
                      Stats
                    </Dropdown.Item>
                    <Dropdown.Item href={Routes.image_locations_admin_job_path(this.job_id)}>
                      Image Locations
                    </Dropdown.Item>
                    <Dropdown.Item href={Routes.pen_locations_admin_job_path(this.job_id)}>
                      Pen Locations
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.uploadCSV}>
                      Upload Yard Count CSV
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.createPen}>
                      Create Pen
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.editPasses}>
                      Edit Passes Threshold
                    </Dropdown.Item>
                    {job.archived == false &&
                      <Dropdown.Item 
                      className="text-danger"
                      onClick={this.setArchive}>
                      Archive
                      </Dropdown.Item>
                    }
                  </React.Fragment>
                }
              </Dropdown.Menu>
            </Dropdown>
          </Card.Header>
          <Card.Body style={{height: "auto"}}>
            {is_admin == false && this.generateClientView()}
            {is_admin == true && this.generateAdminView()}
          </Card.Body>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }


  generateAdminView() {
    var job = this.state.job

    var is_admin = this.is_admin
    var flight_plans = job.flight_plans

    var pens_needs_reviewed = job.pens_needs_reviewed
    var pens_needs_reflown = job.pens_needs_reflown
    var flight_plan_needs_reflown_url = Routes.generate_path_admin_job_path(job.id) + "?" + job.ref_pen_needs_reflown_ids.map(id => `ref_pen_ids[]=${id}`).join("&")

    return (
      <Container fluid>
        <FlightPlanStatus
          job_id={job.id}
          type='collector'/>

        <Row className="row-gap-3 justify-content-between mt-3">
          <Col xs='12' sm='6' lg='3' className="text-center mt-3 px-2">
            <hr className="mb-1 mt-0" />
            <h5><b>Completion</b></h5>
            <hr className="mb-1 mt-0" />
            <table className="mx-auto">
              {is_admin && job.archived == true &&
                <tr>
                  <th className="label">
                    Archived:
                  </th>
                  <td className="field">
                    <div className="text-danger">
                      True
                    </div>
                  </td>
                </tr>
              }
              {job.finished == true && 
                <tr>
                  <th className="label">
                    Completed At:
                  </th>
                  <td className="field">
                    {job.finished_at}
                  </td>
                </tr>
              }
              <tr>
                <th className="label">
                  Counting Progress:
                </th>
                <td className="field">
                  {job.pens_completed_count}/{job.pens_count}
                </td>
              </tr>
              {(job.counting_completed_at != null && job.counting_completed == true) && 
                <tr>
                  <th className="label">
                    Counting Completed At:
                  </th>
                  <td className="field">
                    {job.counting_completed_at}
                  </td>
                </tr>
              }
              {job.counting_completed_at != null && 
                <tr>
                  <th className="label">
                    Counting Completed At:
                  </th>
                  <td className="field">
                    {job.counting_completed ? "Done Counting" : "In Progress"}
                  </td>
                </tr>
              }
            </table>
          </Col>
          <Col xs='12' sm='6' lg='2' className="text-center mt-3">
            <hr className="mb-1 mt-0" />
            <h5><b>Counts</b></h5>
            <hr className="mb-1 mt-0" />
            <table className="mx-auto">
              <tr>
                <th className="label">
                  Yard Count: 
                </th>
                <td className="field">
                  {numberWithCommas(job.total_yard_count || 0)}
                </td>
              </tr>
              <tr>
                <th className="label">
                  HC Count: 
                </th>
                <td className="field">
                  {numberWithCommas(job.total_count || 0)}
                </td>
              </tr>
              <tr>
                <th className="label">
                  Difference:
                </th>
                <td className="field">
                  {numberWithCommas((job.total_count || 0) - (job.total_yard_count || 0))}
                </td>
              </tr>
            </table>
          </Col>
          <Col xs='12' sm='6' lg='3' className="text-center mt-3">
            <hr className="mb-1 mt-0" />
            <h5><b>Pens</b></h5>
            <hr className="mb-1 mt-0" />
            <table className="mx-auto text-center">
              <tr>
                <th className="label">
                  Total:
                </th>
                <td className="field">
                  {job.pens_count}
                </td>
              </tr>
              <tr>
                <th className="label">
                  Images Uploaded:
                </th>
                <td className="field">
                  {job.images_uploaded_count}
                </td>
              </tr>

              {pens_needs_reviewed != 0 &&
                <tr className="text-warning">
                  <th className="label">
                    Needs Reviewed:
                  </th>
                  <td className="field">
                    {job.pens_needs_reviewed}
                  </td>
                </tr>
              }
              {pens_needs_reflown != 0 &&
                <tr className="text-danger">
                  <th className="label">
                    <a className="text-danger" href={flight_plan_needs_reflown_url}>
                      Needs Reflown:
                    </a>
                  </th>
                  <td className="field">
                    <a className="text-danger" href={flight_plan_needs_reflown_url}>
                      {job.pens_needs_reflown}
                    </a>
                  </td>
                </tr>
              }
              {job.pens_gates_open != 0 &&
                <tr>
                  <th className="label">
                    Gates Open:
                  </th>
                  <td className="field">
                    {job.pens_gates_open}
                  </td>
                </tr>
              }
              {job.pens_cattle_in_alley != 0 &&
                <tr>
                  <th className="label">
                    Cattle In Alley:
                  </th>
                  <td className="field">
                    {job.pens_cattle_in_alley}
                  </td>
                </tr>
              }
            </table>
          </Col>
          
          <Col xs='12' sm='6' lg='3' className="text-center mt-3">
            <hr className="mb-1 mt-0" />
            <h5><b>Misc</b></h5>
            <hr className="mb-1 mt-0" />
            <table className="mx-auto">
              <tr>
                <th className="label">
                  Passes Threshold: 
                </th>
                <td className="field">
                  {job.passes}
                </td>
              </tr>
              <tr>
                <th className="label">
                  Skip Quality Control: 
                </th>
                <td className="field">
                  <div style={{display: "inline"}}>
                    {job.ignore == true ? "Yes" : "No"}
                  </div>
                  {job.ignore == false &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-toggle-off ml-2" 
                        data-tip data-for="set-skip-QC-toolip"
                        onClick={() => {this.setSkipQualityControl(true)}}/>
                      <ReactTooltip id='set-skip-QC-toolip' effect='solid' 
                          

                        textColor="black">
                        <span>Set Skip Quality Control to True</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                  {job.ignore == true &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-toggle-on ml-2" 
                        data-tip data-for="set-skip-QC-toolip"
                        onClick={() => {this.setSkipQualityControl(false)}}/>
                      <ReactTooltip id='set-skip-QC-toolip' effect='solid' 
                          

                        textColor="black">
                        <span>Set Skip Quality Control to False</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                </td>
              </tr>
              <tr>
                <th className="label">
                  Priority:
                </th>
                <td className="field">
                  <div style={{display: "inline"}}>
                    {job.priority == true ? "Yes" : "No"}
                  </div>
                  {job.priority == false &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-toggle-off ml-2" 
                        data-tip data-for="set-priority-toolip"
                        onClick={() => {this.setPriority(true)}}/>
                      <ReactTooltip id='set-priority-toolip' effect='solid' 
                        textColor="black">
                        <span>Set Priority to True</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                  {job.priority == true &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-toggle-on ml-2" 
                        data-tip data-for="set-priority-toolip"
                        onClick={() => {this.setPriority(false)}}/>
                      <ReactTooltip id='set-priority-toolip' effect='solid' 
                        textColor="black">
                        <span>Set Priority to False</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                </td>
              </tr>
              <tr>
                <th className="label">
                  Should Auto Complete: 
                </th>
                <td className="field">
                  <div style={{display: "inline"}}>
                    {job.should_auto_complete == true ? "Yes" : "No"}
                  </div>
                  {job.should_auto_complete == false &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-toggle-off ml-2" 
                        data-tip data-for="set-should-auto-complete-toolip"
                        onClick={() => {this.setShouldAutoComplete(true)}}/>
                      <ReactTooltip id='set-should-auto-complete-toolip' effect='solid' 
                        textColor="black">
                        <span>Set Should Auto Complete to True</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                  {job.should_auto_complete == true &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-toggle-on ml-2" 
                        data-tip data-for="set-should-auto-complete-toolip"
                        onClick={() => {this.setShouldAutoComplete(false)}}/>
                      <ReactTooltip id='set-should-auto-complete-toolip' effect='solid' 
                        textColor="black">
                        <span>Set Should Auto Complete to False</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                </td>
              </tr>
            </table>
          </Col>
          
          <Col xs='12' sm='6' lg='3' className="text-center mt-3">
            <hr className="mb-1 mt-0" />
            <h5><b>Billing</b></h5>
            <hr className="mb-1 mt-0" />
            <table className="mx-auto">
              <tr>
                <th className="label">
                  Skip Billing: 
                </th>
                <td className="field">
                  <div style={{display: "inline"}}>
                    {job.skip_billing == true ? "Yes" : "No"}
                  </div>
                  <React.Fragment>
                    <i className={`btn-icon fas ${job.skip_billing ? 'fa-toggle-on' : 'fa-toggle-off'} ml-2`}
                      data-tip data-for="set-skip-billing-toolip"
                      onClick={() => {this.setSkipBilling(!job.skip_billing)}}/>
                    <ReactTooltip id='set-skip-billing-toolip' effect='solid' 
                      textColor="black">
                      <span>Set Should Skip Billing</span>
                    </ReactTooltip>
                  </React.Fragment>
                </td>
              </tr>
              {(job.line_item != null || job.finished == true) && 
                <tr>
                  <th colSpan={2}>
                    <CreateLineItem
                      job_id={this.job_id}
                      line_item={job.line_item}
                    />
                  </th>
                </tr>
              }
              { job.subscription_id != null && 
                <React.Fragment>
                  <tr>
                    <th className="label">
                      Skip Sub Entry: 
                    </th>
                    <td className="field">
                      <div style={{display: "inline"}}>
                        {job.skip_sub_entry == true ? "Yes" : "No"}
                      </div>
                      <React.Fragment>
                        <i className={`btn-icon fas ${job.skip_sub_entry ? 'fa-toggle-on' : 'fa-toggle-off'} ml-2`}
                          onClick={() => {this.setAutoCreateSubscriptionEntry(!job.skip_sub_entry)}}/>
                      </React.Fragment>
                    </td>
                  </tr>
                  <tr>
                  <th colSpan={2}>
                      <a href={Routes.admin_subscription_path(job.subscription_id)} target="_blank">
                        View Subscription
                      </a>
                    </th>
                  </tr>
                  { job.subscription_entry_id != null && 
                    <React.Fragment>
                      <tr>
                        <th colSpan={2}>
                          <a href={Routes.edit_entry_admin_subscription_path(job.subscription_id, {subscription_entry_id: job.subscription_entry_id})} target="_blank">
                            Edit Subscription Entry
                            <br/>
                          </a>
                        </th>
                      </tr>
                      <tr>
                        <th className="label">
                          Sub Entry - Count:
                        </th>
                        <td className="field">
                          {numberWithCommas(job.subscription_entry_count || 0)}
                        </td>
                      </tr>
                      <tr>
                        <th className="label">
                          Sub Entry - Completed At:
                        </th>
                        <td className="field">
                          {job.subscription_entry_completed_at}
                        </td>
                      </tr>
                    </React.Fragment>
                  } 
                  { (job.subscription_entry_id == null && job.finished == true) &&
                    <tr>
                      <th colSpan={2}>
                        <form method="post" id="createSubEntryForm" action={Routes.add_entry_admin_subscription_path(this.state.job.subscription_id)}>
                          <input type="hidden" name="utf8" value="&#x2713;"/>
                          <input type="hidden" name="authenticity_token" value={form_authenticity_token()}/>
                          <input type="hidden" name="job_id" value={this.job_id}/>
                          <Button type="submit" variant="primary">
                            Create Subscription Entry
                          </Button>
                        </form>
                      </th>
                    </tr>
                  }
                </React.Fragment>
              }
            </table>
          </Col>

        </Row>
        <Row className="mb-2">
          <Col xs="12">
            <Card>
              <Card.Header>
                Flight Plans
                  <a className="btn-icon fas fa-plus-circle ml-2"
                    style={{fontSize: "1em"}}
                    href={Routes.generate_path_admin_job_path(this.job_id)}
                    data-tip data-for="new-flight-plan-tooltip">
                  </a>
                  <ReactTooltip id='new-flight-plan-tooltip' effect='solid' textColor="black">
                    <span>Create New Flight Plan</span>
                  </ReactTooltip>
                  <div className="float-right font-weight-bold">
                    Notifications Sent: 
                    <span className="text-secondary ml-2">
                      <FlightPlanStatus
                        job_id={job.id}
                        type='notification_sent'
                        message_style='full'
                      />
                    </span>
                  </div>
              </Card.Header>
              <Card.Body>
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Plan</th>
                      <th>Exported</th>
                      <th>Active Users</th>
                    </tr>
                  </thead>
                  <tbody>
                  {flight_plans.map((flight_plan, index) => {
                    return (
                      <tr>
                        <td>
                          <Badge 
                            as="a"
                            key={flight_plan.id}
                            variant="primary"
                            className="ml-2 p-2"
                            href={Routes.generate_path_admin_job_path(this.job_id) + `?flight_plan_request_id=${flight_plan.id}`}>
                            {`${index+1}: ${flight_plan.pens.length} Pens`}
                          </Badge>  
                        </td>
                        <td>
                          <FlightPlanStatus
                            job_id={job.id}
                            flight_plan_id={flight_plan.id}
                            type='plan_exported'
                            message_style='full'
                          />
                        </td>
                        <td>
                          <FlightPlanStatus
                            job_id={job.id}
                            flight_plan_id={flight_plan.id}
                            type='active_users'
                            message_style='simple'
                          />
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }

  generateClientView() {

    var job = this.state.job
    if(job == null) {
      return null
    }

    return (
      <Container fluid id="pen-list-component">
        <Row className="row-gap-3 txt-lg justify-content-around">
          <Col xs={6} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              <img src={this.tally_icon_path} className="pr-2" style={{height: "1em", verticalAlign: "middle"}}/>
              <span style={{verticalAlign: "middle"}}>
                {numberWithCommas(job.total_yard_count || 0)}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              Yard Count
            </div>
          </Col>
          <Col xs={6} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              <img src={this.hc_icon_path} className="pr-2" style={{height: "1em", verticalAlign: "middle"}}/>
              <span style={{verticalAlign: "middle"}}>
                {job.finished == true ? numberWithCommas(job.total_count || 0) : "-"}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              HC Count
            </div>
          </Col>
          <Col xs={12} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              <img src={this.difference_icon_path} className="pr-2" style={{height: "1em", verticalAlign: "middle"}}/>
              <span style={{verticalAlign: "middle"}}>
                {job.finished == true ? numberWithCommas((job.total_count || 0) - (job.total_yard_count || 0)) : "-"}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              Difference
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "JobDashboardChannels::JobDescriptionCardChannel",
        feedlot_id: this.feedlot_id,
        job_id: this.job_id
      },
      {  
        connected() {
          _this.getJob()
        },

        received(data) {
          if (data.job != null) {
            _this.setState({
              job: data.job
            })
          } else if(data.message == "job_updated") {
            _this.getJob()
          }
        }
      }
    )
  }

  getJob() {
    this.listener.perform("get_job")
  }


  uploadCSV() {
    $('#upload-csv-modal').modal('show')
  }

  createPen() {
    postal.publish({
      channel: "EditJobModal",
      topic: "open",
      data: {
        job_id: this.job_id,
        type: "create_pen"
      }
    })
  }

  editName() {
    postal.publish({
      channel: "EditJobModal",
      topic: "open",
      data: {
        job_id: this.job_id,
        type: "edit_name"
      }
    })
  }

  editPasses() {
    postal.publish({
      channel: "EditJobModal",
      topic: "open",
      data: {
        job_id: this.job_id,
        type: "edit_passes"
      }
    })
  }

  setSkipQualityControl(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.set_ignore_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Skip Quality Control to ${bool_value}`)
        } else {
          postAlert("danger", `Failed To Set Skip Quality Control`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Skip Quality Control`)
      },
      complete: function() {}
    })
  }

  setPriority(bool_value) {
    console.log("setting priority")
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.set_priority_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Priority to ${bool_value}`)
        } else {
          postAlert("danger", `Failed To Set Priority`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Priority`)
      },
      complete: function() {}
    })
  }

  setShouldAutoComplete(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.set_should_auto_complete_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Should Auto Complete to ${bool_value}`)
        } else {
          postAlert("danger", `Failed To Set Should Auto Complete`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Should Auto Complete`)
      },
      complete: function() {}
    })
  }

  setSkipBilling(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.set_skip_billing_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Skip Billing to ${bool_value}`)
        } else {
          postAlert("danger", `Failed To Set Skip Billing`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Skip Billing`)
      },
      complete: function() {}
    })
  }

  setAutoCreateSubscriptionEntry(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.set_skip_sub_entry_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Auto Create Subscription Entry to ${bool_value}`)
        } else {
          postAlert("danger", `Failed To Set Auto Create Subscription Entry`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Auto Create Subscription Entry`)
      },
      complete: function() {}
    })
  }

  createSubEntry() {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('job_id', this.job_id);
    
    $.ajax({
      url: Routes.add_entry_admin_subscription_path(this.state.job.subscription_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Created Subscription Entry`)
        } else {
          postAlert("danger", `Failed To Create Subscription Entry`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Create Subscription Entry`)
      },
      complete: function() {}
    })
  }

  setStatus(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.edit_status_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Status to ${bool_value ? "Complete" : "In Progress"}`)
        } else {
          postAlert("danger", `Failed To Set Status`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Status`)
      },
      complete: function() {}
    })
  }

  setArchive(bool_value) { 
    if (ask("Are You Sure You Want To Archive This Job?")) {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      jform.append('value', bool_value);
      
      $.ajax({
        url: Routes.archive_admin_job_path(this.job_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", `Successfully Archived Job`)
          } else {
            postAlert("danger", `Failed To Archive Job`)
          }
        },
        error: function(request, textStatus, errorThrown) {
          postAlert("danger", `Failed To Archive Job`)
        },
        complete: function() {}
      })
    }
  }

  importPhotos() {
    postal.publish({
      channel: "ImportPhotos",
      topic: "open",
      data: {
        job_id: this.job_id
      }
    })
  }
}