export default class ShareModal extends React.Component {

  constructor(props) {
    super(props);
    
    this.emailInputRef = React.createRef()
    this.modal = React.createRef()

    this.submitShare = this.submitShare.bind(this)

    this.state = {
      feedlot_id: null
    }
    
    this.postalListener = this.createPostalListener()
  }

  createPostalListener() {
    var _this = this
    return postal.subscribe({
      channel: "ShareModal",
      topic: "open",
      callback: function(data, envelope) {
        console.log("trying to open share modal")
        _this.setState({
          feedlot_id: data.feedlot_id
        }, () => {
          _this.open()
        })
      }
    });
  }

  open() {
    console.log("asdf")
    $(this.modal.current).modal('show')
  }

  close() {
    $(this.modal.current).modal('hide')
  }

  render() {
    try {
      return (
        <div className="modal fade" role="dialog" tabIndex="-1" ref={this.modal}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Share
                </h5>
                <button className="close" onClick={()=>this.close()}>
                  <span>x</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">Email</span>
                  </div>
                  <input
                    ref={this.emailInputRef}
                    className="form-control" 
                    type="text" 
                    placeholder="Enter email addresses here"
                  />
                </div>
                <button 
                  className="btn btn-primary float-right"
                  onClick={this.submitShare}
                  >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  submitShare() {
    var _this = this
    var feedlot_id = this.state.feedlot_id
    var email = this.emailInputRef.current.value
    
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('email', email);

    $.ajax({
      url: `/feedlots/${feedlot_id}/invite_team_member`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data', // this too
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        _this.emailInputRef.current.value = ""
        var success = response.success
        var message = response.message

        switch (message) {
          case "already_exists":
            message = `${email} is Already a Team Member`
            break
          case "invalid_email":
            message = `Failed To Share to ${email}: Invalid Email`
            break
          case '':
            message = `Successfully Added ${email} to the Team`
            break
          default:
            message = `Failed To Share to ${email}: Unknown Issue`
        }
        
        var alertType = success ? "success" : "danger"

        if(success) {
          _this.close()
        }

        postAlert(alertType, message)
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Share to ${email}: Unknown Issue`)
      },
      complete: function() {
        
      }
    })
  }
}