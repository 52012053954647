import React from "react"
import Select from "react-select"

export default class FeedlotSelectComponent extends React.Component {

  constructor(props) {
    super(props)
    
    this.selected_feedlot_id = props.selected_feedlot_id
    this.selected_feedlot_label = props.selected_feedlot_name
    this.feedlots = props.feedlots // just ids and name
    this.is_mobile = props.is_mobile || false
    this.is_admin = props.is_admin || false

    this.changeSelectedFeedlot = this.changeSelectedFeedlot.bind(this)
  }

  changeSelectedFeedlot(selectedOption) {
    var feedlot_id = selectedOption.value
    if (this.is_admin) {
      window.location = Routes.admin_feedlot_path(feedlot_id)
    } else {
      window.location = Routes.feedlot_path(feedlot_id)
    }
  }

  render () {
    try {
      if (this.selected_feedlot_id == null) {
        return null
      }

      var inputStyle = this.is_mobile ? {maxWidth: "fit-content", minWidth: "30vw"} : {minWidth: "250px"}

      return (
        <div style={inputStyle}>
          <Select id="feedlot_select"
            defaultValue={{value: this.selected_feedlot_id, label: this.selected_feedlot_label}}  
            onChange={this.changeSelectedFeedlot}
            options={this.feedlots.map((feedlot,i) => {
              return {
                value: feedlot.id, 
                label: feedlot.name
              };}
            )}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                primary25: 'salmon',
                primary: 'darkred'
              },
            })}
          />
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}

