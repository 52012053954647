
import React from "react"

export default class MetaDataView extends React.Component {

  constructor(props) {
    super(props)

    this.url = props.url
    
    this.state = {
      meta_data: null,
      message: "Loading...",
    }
  }

  componentDidMount() {
    this.parseImageData()
  }

  render () {
    let keys_and_titles = [["gps", "GPS Data"], ["xmp", "XMP Data"], ["exif", "Exif Data"], ["file", "File Data"], ["jfif", "JFIF Data"], ["mpf", "MPF Data"]]

    try {
      return <React.Fragment>
        <div>{this.state.message}</div>

        { this.state.meta_data != null && keys_and_titles.map(key_and_title => {
          try {
            let key = key_and_title[0]
            let title = key_and_title[1]
            return <table>
              <thead><tr><th>{title}</th></tr></thead>
              <tbody>
                {Object.keys(this.state.meta_data[key]).map(sub_key => {
                  try {
                    let item = this.state.meta_data[key][sub_key]
                    var value = key == "gps" ? item : item.description
                    return <tr>
                      <td className="px-4 py-1">{sub_key}:</td>
                      <td className="py-1">{value}</td>
                    </tr>
                  } catch (error) {
                    console.log(error)
                    return <tr><td>Failed To Parse</td></tr>
                  }
                })}
              </tbody>
            </table>  
          } catch(error) {
            console.log(error)
            return <div></div>
          }
        })}
      </React.Fragment>
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  async parseImageData() {
    if (this.url == null) {
      this.setState({message: "No Image Found"})
    } else {
      try {
        const tags = await ExifReader.load(this.url, {expanded: true, includeUnknown: true});
        console.log(tags)
        this.setState({meta_data: tags, message: ""})
      } catch (err) {
        console.log(err)
        this.setState({message: "Error Parsing Image Data"})
      }
    }
  }
}