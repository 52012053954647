import React from "react"
/*
  Used in pen/stats to request an image be generated for a specific counting_stat
*/
class PenHistory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      display: false
    }
  }

  render () {
    try {
      const sending_message = this.state.sending_message
      const comments = this.state.comments
      return (
        <React.Fragment>
          {this.state.display == false &&
            <button className="btn btn-primary" onClick={()=>this.displayImage()}>Display Photo</button>
          } 
          {this.state.display == true && 
            <img style={{width:"100%"}} src={this.props.path} />
          }
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  displayImage() {
    this.setState({display: true})
  }
}
export default PenHistory
