import { element } from "prop-types"
import React from "react"
import {Container, Row, Col, NavLink} from 'react-bootstrap' 
import parseActivity from '../../ActivityParser.js'


export default class NotificationsComponent extends React.Component {

  constructor(props) {
    super(props)
    
    this.is_admin = props.is_admin || false
    this.is_mobile = props.is_mobile || false
    this.is_masquerading = props.is_masquerading || false

    // allow admins to group notifs by feedlot/job/pen
    this.group_methods = [
      {value: "all", label: "All"},
      {value: "feedlot", label: "Feedlot"},
      {value: "job", label: "Job"},
      {value: "pen", label: "Pen"},
    ]

    this.state = {
      show_notifs: false,
      notifications: [],
      selected_group_method: "all", // admins have extra layers of grouping (feedlot/job/pen)
      page: 0,
      total_pages: null,
      new_notifications_count_all: 0,
      new_notifications_count_feedlot: 0,
      new_notifications_count_job: 0,
      new_notifications_count_pen: 0,
      is_fetching: false,
    }

    this.listener = this.subscribe()
    this.notificationPanel = React.createRef()

    this.listContainerRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this)

  }

  componentDidMount() {
    //var panel = this.notificationPanel.current
    //var panel.style.maxWidth = window.innerWidth
  }

  render () {
    try {
      const is_admin = this.is_admin
      
      const new_notifications_count_all = this.state.new_notifications_count_all
      const new_notifications_count_feedlot = this.state.new_notifications_count_feedlot
      const new_notifications_count_job = this.state.new_notifications_count_job
      const new_notifications_count_pen = this.state.new_notifications_count_pen

      const notifications = this.state.notifications
      const className = this.is_mobile ? "mobile-notif-button" : "notif-button"
      const dropdown_className = "notif-dropdown" //this.is_mobile ? "mobile-notif-dropdown" : "notif-dropdown"
      const selected_group_method = this.state.selected_group_method // admin feature

      return (
        <React.Fragment>
          <span id="notifications" aria-expanded="false" aria-haspopup="true" className={className} href="#" onClick={() => {
            this.toggleDisplay()
            this.viewAll()
          }}>
            <i className="fas fa-bell">
              {(new_notifications_count_all > 0) && 
                <span className="badge badge-pill badge-primary" style={{position:"absolute", top: "-5px", left: "6px", fontSize: "0.6em"}}>
                  {new_notifications_count_all}
                </span>
              }
            </i>
          </span>

          {this.state.show_notifs == true &&
            <div ref={this.notificationPanel} className={dropdown_className} onScroll={this.handleScroll}>
              <Container fluid className="notification-container">
                <Row className="justify-content-between">
                  {is_admin == true &&
                    <Col className="mt-auto mb-auto">
                      <div className="btn-group d-block nowrap">
                        {this.group_methods.map((method,i) => {
                          let notifs_count
                          switch (method.value) {
                            case "all":
                              notifs_count = new_notifications_count_all
                              break
                            case "feedlot":
                              notifs_count = new_notifications_count_feedlot
                              break
                            case "job":
                              notifs_count = new_notifications_count_job
                              break
                            case "pen":
                              notifs_count = new_notifications_count_pen
                              break 
                          }
                          let isGroupSelected = selected_group_method == method.value

                          return (<button 
                          key={method.value}
                          type="button"
                          className={"btn btn-primary text-nowrap p-0 px-1 " + (isGroupSelected ? "selected" : "unselected")}
                          onClick={() => {
                            if (isGroupSelected) { return }
                            this.setState({
                              selected_group_method: method.value,
                              page: 0,
                              total_pages: null,
                              notifications: []
                            },() => {
                              this.getNotifications(0)
                            })
                          }}>
                            {method.label}
                            {notifs_count > 0 && 
                              <span className="badge badge-pill badge-primary txt-sm ml-1">
                                {notifs_count}
                              </span>
                            }
                          </button>)
                        })}
                      </div>
                    </Col>
                  }
                  <Col xs="auto">
                    <a className="btn btn-link" href={Routes.home_notifications_path()}>
                      View All
                    </a>
                  </Col>
                </Row>
                {notifications.map((notification, index) =>
                  <ActivityRenderer 
                    key={notification.id}
                    notification={notification}
                    is_admin={this.is_admin}
                  />
                )}
                { this.state.is_fetching == true &&
                  <Row>
                    <Col>
                      <b>
                        Fetching Notifications...
                      </b>
                    </Col>
                  </Row>
                }
                { this.state.total_pages == null && this.state.is_fetching == false &&
                  <Row>
                    <Col>
                      <b>
                        No Notifications
                      </b>
                    </Col>
                  </Row>
                }
              </Container>
            </div>
          }

        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::NotificationsComponentChannel",
      },
      {  
        connected() {
          _this.getNotifications(_this.state.page)
          _this.getNewNotificationsCount()
        },

        received(data) {
          if (data.message == "added") {
            _this.getNotification(data.notification_id)
          } else if (data.notifications != null) {
            _this.setState({
              is_fetching: false
            })
            var page = data.page || 0
            var total_pages = data.total_pages || 0
            var group = data.group || "all"
            if (group != _this.state.selected_group_method) { return } 
            _this.addNotifications(data.notifications, page, total_pages)
          } else if (data.notification != null) {
            _this.updateNotification(data.notification)
          } else if (data.new_notifications_count_all != null) {
            let new_notifications_count_all = data.new_notifications_count_all
            let new_notifications_count_feedlot = data.new_notifications_count_feedlot
            let new_notifications_count_job = data.new_notifications_count_job
            let new_notifications_count_pen = data.new_notifications_count_pen
            _this.setState({
              new_notifications_count_all: new_notifications_count_all,
              new_notifications_count_feedlot: new_notifications_count_feedlot,
              new_notifications_count_job: new_notifications_count_job,
              new_notifications_count_pen: new_notifications_count_pen
            })
          }
        }
      }
    )
  }

  getNotifications(page) {
    var group = this.state.selected_group_method
    this.setState({
      is_fetching: true
    })    
    this.listener.perform("get_notifications", {page: page, group: group})
  }

  getNewNotificationsCount() {
    this.listener.perform("get_new_notifications_count")
  }

  getNotification(notification_id) {
    this.listener.perform("get_notification", {id: notification_id})
  }

  viewAll(group = 'all') {
    // exit early if user is masquerading
    if (this.is_masquerading) { return }
    this.listener.perform("view_all_notifications", {group: group})
  }

  // merge incoming notifications with existing notifications
  addNotifications(incoming_notifications, page, total_pages) {
    this.setState(function(state, props) {
      var notifications = this.state.notifications
      incoming_notifications.forEach(function(incoming_notification) {
        var index = notifications.findIndex((notif) => notif.id == incoming_notification.id);
        if(index == -1) {
          notifications.push(incoming_notification)
        } else {
          notifications[index] = incoming_notification
        }
      })
      return {
        notifications: notifications,
        page: page+1,
        total_pages: total_pages
      }
    })
  }

  updateNotification(notification) {
    this.setState(function(state, props) {
      var notifications = state.notifications
      var index = notifications.findIndex((notif) => notif.id == notification.id);
      var new_notifications_count_all = state.new_notifications_count_all
      var new_notifications_count_feedlot = state.new_notifications_count_feedlot
      var new_notifications_count_job = state.new_notifications_count_job
      var new_notifications_count_pen = state.new_notifications_count_pen 
      if(index == -1) {
        notifications = [notification, ...notifications]
        new_notifications_count_all += 1
        if (notification.activity.trackable_type == "Feedlot") {
          new_notifications_count_feedlot += 1
        } else if (notification.activity.trackable_type == "Job") {
          new_notifications_count_job += 1
        } else if (notification.activity.trackable_type == "Pen") {
          new_notifications_count_pen += 1
        }
      } else {
        notifications[index] = notification
      }
      
      return {
        notifications: notifications,
        new_notifications_count_all: new_notifications_count_all,
        new_notifications_count_feedlot: new_notifications_count_feedlot,
        new_notifications_count_job: new_notifications_count_job,
        new_notifications_count_pen: new_notifications_count_pen
      }
    })
  }

  toggleDisplay() {
    this.setState(function(state, props) {
      var show_notifs = !state.show_notifs
      return {
        show_notifs: show_notifs
      }
    })
  }

  groupNotifications(group_method = null) {
    if (group_method == null) {
      group_method = this.state.selected_group_method
    }
    switch (group_method) {
      case 'feedlot':
        return this.state.notifications.filter((notif) => notif.activity.trackable_type == 'Feedlot')
      case 'job':
        return this.state.notifications.filter((notif) => notif.activity.trackable_type == 'Job')
      case 'pen':
        return this.state.notifications.filter((notif) => notif.activity.trackable_type == 'Pen')
      default:
        return this.state.notifications
    }
  }

  handleScroll() {
    // when scrolled to the bottom of the list, fetch more notifications 
    if (this.notificationPanel.current == null) { return }
    if (this.state.is_fetching == true) { return }
    if (this.state.total_pages == 0) { return }

    const { scrollTop, clientHeight, scrollHeight } = this.notificationPanel.current;
    const distanceToBottom = Math.abs(scrollHeight - (scrollTop + clientHeight))
    if (distanceToBottom < 10) {
      this.getNotifications(this.state.page)
    }
  }

}

// inside a Container Row Col structure
class ActivityRenderer extends React.Component {
  constructor(props) {
    super(props)

    this.is_admin = props.is_admin

    this.state = {
      notification: props.notification
    }
  }

  render () {
    try {
      const notification = this.state.notification
      var {title, message, time} = parseActivity(notification.activity, true, true, this.is_admin)
      var border_class = "client_activity"
      if (notification.activity.level == "admin") {
        border_class = "admin_activity"
      }
      
      return (
        <Row className={border_class}><Col className="p-0">
          <Row>
            <Col className="p-0">
              <Row>
                <Col>
                  <b className="nowrap">
                    {title}
                  </b>
                </Col>
                <Col xs="auto">
                  <i>
                    {time}
                  </i>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pt-1">
            <Col>
              {message}
            </Col>
            { notification.viewed == false && 
              <Col xs="auto">
                <i className="fas fa-circle" style={{color: theme.primary}}></i>
              </Col>     
            }
          </Row>
        </Col></Row>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}

