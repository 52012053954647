import React from "react"
import {Container, Row, Col} from 'react-bootstrap' 
import ExifReader from 'exifreader';

/*
  General Use Modal for editing pen fields
*/
export default class EditPenModal extends React.Component {

  constructor(props) {
    super(props);

    // each type denotes which detail of the pen is going to be modified
    this.types = [
      {value: "upload_image", title: "Upload Image"},
      {value: "pen_name", title: "Edit Pen Name"},
      {value: "yard_count", title: "Edit Yard Count"}
    ]
    
    this.state = {
      pen_id: props.pen_id || null,
      pen_name: props.pen_name || null,
      type: props.type || null    
    }

    this.listener = this.postalListener()
  }

  postalListener() {
    var _this = this
    return postal.subscribe({
      channel: "EditPenModal",
      topic: "open",
      callback: function(data, envelope) {
        _this.setState({
          pen_id: data.pen_id,
          pen_name: data.pen_name,
          type: data.type,
        }, () => {
          if (_this.isValidState()) {
            $('#edit-pen-modal').modal("show");
          }
        })
      }
    });
  }

  static close() {
    $('#edit-pen-modal').modal("hide");
  }

  isValidState() {
    var type = this.types.filter((possible_type) => this.state.type == possible_type.value)[0]
    if (type == null || this.state.pen_id == null) {
      return false
    }
    return true
  }

  render () {
    try {
      if (this.isValidState() == false) {
        return null
      }

      var type = this.types.filter((possible_type) => this.state.type == possible_type.value)[0]

      return (
        <div className="modal fade" id="edit-pen-modal" role="dialog" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered" style={{minWidth: "50vw"}}>
            <div className="modal-content">
              <div className="modal-header">
                <Container>
                  <Row>
                    <Col>
                      <h5 className="modal-title">
                        {this.state.pen_name || ""}: {type.title}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <button className="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">×</span>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid className="modal-body">
                {type.value == "pen_name" && 
                  <EditPenName
                    key={this.state.pen_id + type.value}
                    pen_id={this.state.pen_id}
                  />
                }
                {type.value == "yard_count" &&
                  <EditYardCount
                  key={this.state.pen_id + type.value}
                  pen_id={this.state.pen_id}
                  />
                }
                {type.value == "upload_image" &&
                  <UploadImage
                  key={this.state.pen_id + type.value}
                  pen_id={this.state.pen_id}
                  />
                }
              </Container>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error);
      return null
    }
  }
}


class EditPenName extends React.Component {

  constructor(props) {
    super(props);

    this.pen_id = props.pen_id
    
    this.state = {
      loaded_data: false,
      pen_name: null,
      ref_pen_name: null,
      error_message: null,
    }

    this.nameInput = React.createRef()

    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var pen_name = this.state.pen_name
    var ref_pen_name = this.state.ref_pen_name
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Pen Name
                    </span>
                  </div>
                  <input 
                    ref={this.nameInput}
                    type="text" 
                    className="form-control"
                    defaultValue={pen_name || ref_pen_name || "Undefined"}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: `/pens/${_this.pen_id}/edit_pen_name`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            pen_name: response.pen_name,
            ref_pen_name: response.ref_pen_name,
            loaded_data: true,
            error_message: null
          })
        } else {
          _this.setState({
            pen_name: null,
            ref_pen_name: null,
            loaded_data: false,
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          pen_name: null,
          ref_pen_name: null,
          loaded_data: false,
          error_message: "Failed To Get Pen Data",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var newName = this.nameInput.current?.value || ""
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('new_name', newName);
    
    $.ajax({
      url: `/pens/${_this.pen_id}/edit_pen_name`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditPenModal.close()
          postAlert("success", "Successfully Updated Pen Name")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Update Name",
        })
      },
      complete: function() {}
    })
  }
}

class EditYardCount extends React.Component {

  constructor(props) {
    super(props);

    this.pen_id = props.pen_id
    
    this.state = {
      loaded_data: false,
      yard_count: null,
      error_message: null,
    }

    this.yardCountInput = React.createRef()

    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var yard_count = this.state.yard_count
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Yard Count
                    </span>
                  </div>
                  <input 
                    ref={this.yardCountInput}
                    type="number"
                    className="form-control"
                    defaultValue={yard_count || 0}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: `/pens/${_this.pen_id}/edit_yard_count`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            yard_count: response.yard_count,
            loaded_data: true,
            error_message: null
          })
        } else {
          _this.setState({
            yard_count: null,
            loaded_data: false,
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          yard_count: null,
          loaded_data: false,
          error_message: "Failed To Get Pen Data",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var yard_count = this.yardCountInput.current?.value
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('yard_count', yard_count);
    
    $.ajax({
      url: `/pens/${_this.pen_id}/edit_yard_count`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditPenModal.close()
          postAlert("success", "Successfully Updated Pen Name")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Update Name",
        })
      },
      complete: function() {}
    })
  }
}

class UploadImage extends React.Component {

  constructor(props) {
    super(props);

    this.pen_id = props.pen_id
    
    this.state = {
      uploading: false,
      error_message: null,
      file: null,

      dateTaken: null,
      lat: null,
      lng: null,
      absAlt: null,
      relAlt: null,
      gRoll: null,
      gYaw: null,
      gPitch: null,
      fRoll: null,
      fYaw: null,
      fPitch: null
    }

    this.imageInput = React.createRef()
    this.submit = this.submit.bind(this)
  }

  render () {
    var uploading = this.state.uploading
    var error_message = this.state.error_message
    var file = this.state.file

    var canUpload = uploading == false && file != null
    var canSelectFiles = uploading == false

    return (
      <React.Fragment> 
        {uploading == true && 
          <Row>
            <Col>
              <h6 className="text-success text-center">Uploading</h6>
            </Col>
          </Row>
        }
        {error_message != null && 
          <Row>
            <Col>
              <h6 className="text-danger text-center">{error_message}</h6>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <input 
              ref={this.imageInput} 
              type="file"
              disabled={!canSelectFiles}
              onChange={() => this.onInputChanged()}/>
            <div className="input-group mb-3" style={{width: "fit-content"}}>
              <div className="input-group-prepend">
                <button 
                  className="btn btn-primary" 
                  disabled={!canSelectFiles}
                  onClick={() => this.imageInput.current.click()}>
                    Select Image
                </button>
              </div>
              <span className="form-control">
                {file?.name || "No File Selected"}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="btn btn-primary" disabled={!canUpload} onClick={this.submit}>
              Submit
            </button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  onInputChanged() {
    var file = this.imageInput.current?.files[0]
    this.parseImage(file)
    this.setState({
      file: file
    })
  }

  // parse Images for meta data
  async parseImage(file) {
    console.log("asdf")
    try {
      if (validate_file_size(file)) {
        const tags = await ExifReader.load(file, {expanded: true, includeUnknown: true});

        let exif_data = tags.exif
        let gps_data = tags.gps
        let xmp_data = tags.xmp
        
        var dateTaken = null
        var lat = null
        var lng = null
        var absAlt = null
        var relAlt = null
        var gRoll = null
        var gYaw = null
        var gPitch = null
        var fRoll = null
        var fYaw = null
        var fPitch = null


        if (exif_data != null) {
          try { dateTaken = exif_data.DateTimeOriginal?.description } catch (e) {}
        }

        if (gps_data != null) {
          try { lat = gps_data.Latitude } catch (e) {}
          try { lng = gps_data.Longitude } catch (e) {}
          try { absAlt = gps_data.Altitude } catch (e) {} 
        }

        if (xmp_data != null) {
          try { relAlt = parseFloat(xmp_data.RelativeAltitude?.value) } catch (e) {}
          try { gRoll = parseFloat(xmp_data.GimbalRollDegree?.value) } catch (e) {}
          try { gYaw = parseFloat(xmp_data.GimbalYawDegree?.value) } catch (e) {}
          try { gPitch = parseFloat(xmp_data.GimbalPitchDegree?.value) } catch (e) {}
          try { fRoll = parseFloat(xmp_data.FlightRollDegree?.value) } catch (e) {}
          try { fYaw = parseFloat(xmp_data.FlightYawDegree?.value) } catch (e) {}
          try { fPitch = parseFloat(xmp_data.FlightPitchDegree?.value) } catch (e) {}
        }

        this.setState({
          dateTaken: dateTaken,
          lat: lat,
          lng: lng,
          absAlt: absAlt,
          relAlt: relAlt,
          gRoll: gRoll,
          gYaw: gYaw,
          gPitch: gPitch,
          fRoll: fRoll,
          fYaw: fYaw,
          fPitch: fPitch
        })
      }
    } catch (err) {
      console.log("got here")
      console.log(err)
    }
  }

  submit() {
    this.setState({uploading: true, error_message: null})
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('file', this.state.file)
    jform.append('date_taken', this.state.dateTaken)
    jform.append('lat', this.state.lat)
    jform.append('lng', this.state.lng)
    jform.append('abs_alt', this.state.absAlt)
    jform.append('rel_alt', this.state.relAlt)
    jform.append('g_roll', this.state.gRoll)
    jform.append('g_yaw', this.state.gYaw)
    jform.append('g_pitch', this.state.gPitch)
    jform.append('f_roll', this.state.fRoll)
    jform.append('f_yaw', this.state.fYaw)
    jform.append('f_pitch', this.state.fPitch)
    
    $.ajax({
      url: `/admin/pens/${_this.pen_id}/upload_image`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          var pen_id = response.pen_id
          window.location = Routes.admin_pen_path(pen_id)
        } else {
          _this.setState({
            uploading: false,
            error_message: "Failed To Upload Image"
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          uploading: false,
          error_message: "Failed To Upload Image"
        })
      },
      complete: function() {}
    })
  }
}