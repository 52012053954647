import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 
import PaginatorUI from '../../PaginatorUI'

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class CounterPenHistory extends React.Component {

  constructor(props) {
    super(props)

    this.counter_id = props.counter_id
    
    this.state = {
      status: "connecting",
      counting_stats: [],
      page: null,
      total_pages: null,
    }

    this.searchInput = React.createRef()
    this.listener = this.subscribe()
  }

  render () {
    try {

      const { counting_stats, page, total_pages } = this.state
      
      return (
        <Card id="active-counters">
          <Card.Header>
            <Container fluid className="p-0">
              <Row className="justify-content-between">
                <Col xs="auto" className="p-0">
                  <Card.Title>
                    Pen History
                  </Card.Title>
                </Col>
                <Col xs="auto" className="p-0">
                  <div className="input-group">
                    <input ref={this.searchInput} type="text" className="form-control card-search-input" placeholder="Search" onKeyDown={(e) => {e.key === "Enter" && this.getJobs(0)}}/>
                    <div className="input-group-append"> 
                      <button className="input-group-text btn btn-primary" onClick={() => this.getStats(0)}>
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>
            {counting_stats.map((counting_stat) => {
              return <StatObject 
                key={counting_stat.id}
                counting_stat={counting_stat}
              />
            })}
          </Card.Body>
          {(total_pages != null && total_pages > 1) && 
            <Card.Footer className="pagination-section">
              <nav>
                <PaginatorUI
                  currentPage={page}
                  totalPages={total_pages}
                  pageSelected={(page) => this.getStats(page)}
                  />
              </nav>
            </Card.Footer>
          }
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "AdminChannels::CounterPenHistoryChannel",
        counter_id: this.counter_id
      },
      {  
        connected() {
          _this.getStats(0)
        },

        received(data) {
          if (data.counting_stats != null) {
            _this.setState({
              status: "Ok",
              counting_stats: data.counting_stats,
              page: data.page,
              total_pages: data.total_pages,
            })
          }
        },

        disconnected() {
          _this.setState({status: "disconnected"})
        }
      }
    )
  }

  getStats(page) {
    let search = this.searchInput?.current?.value || null
    this.setState({status: "loading"})
    this.listener.perform("get_counting_stats", {page: page, search: search})
  }

  // update counter in state
  update(stat_to_update) {

    if (this.state.page != 0) {
      return
    }

    this.setState(function(state, props) {

      var exists = false

      var counting_stats = state.counting_stats.map((counting_stat) => {
        if (counting_stat.id == stat_to_update.id) {
          exists = true
          return stat_to_update
        } else {
          return counting_stat
        }
      })
      counting_stats = exists ? [...counting_stats] : [counter_to_update, ...counting_stats]

      return {
        counting_stats: counting_stats
      }
    })
  }
}

class StatObject extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      counting_stat: props.counting_stat,
    }
  }

  // props updated
  componentDidUpdate(prevProps) {
    if (prevProps.counting_stat != this.props.counting_stat) {
      this.setState({counting_stat: this.props.counting_stat})
    }
  }

  render () {
    try {
      const { counting_stat } = this.state

      const { 
        pen, // id, name
        job, // id, name
        feedlot // id, name
      } = counting_stat
      const { 
        changed_border, // boolean
        additions, // int
        removals, // int
        time_spent, // minutes: seconds
        needs_reviewed, // boolean
        needs_reflown, // boolean
        pen_comments, // [string]
        pass, // int
        created_at, // date string
      } = counting_stat

      const bg_color = pen == null ? "#EEE" : "white"

      return (
        <React.Fragment>
          <Container fluid className="p-0">
            <Row className="justify-content-between">
              <Col xs="auto" className="pl-0">
                <Row>
                  <Col xs="auto">
                    <a href={Routes.admin_pen_path(pen.id)} className="mx-1">{pen.name}</a>
                  </Col>
                  <Col xs="auto">
                    <a href={Routes.admin_job_path(job.id)} className="mx-1">{job.name}</a>
                  </Col>
                  <Col xs="auto">
                    <a href={Routes.admin_feedlot_path(feedlot.id)} className="mx-1">{feedlot.name}</a>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto">
                Created At: {created_at}
              </Col>
            </Row> 
            <Row>
            
              <Col xs="auto">
                Additions: {numberWithCommas(additions)}
              </Col>

              <Col xs="auto">
                Removals: {numberWithCommas(removals)}
              </Col>

              <Col xs="auto">
                Changed Border: {changed_border ? "Yes" : "No"}
              </Col>

              <Col xs="auto">
                Pass: {pass}
              </Col>

              <Col xs="auto">
                Time Spent: {time_spent}
              </Col>

              {needs_reviewed == true &&
                <Col xs="auto" className="text-warning">
                  Needs Reviewed
                </Col>
              }

              {needs_reflown == true &&
                <Col xs="auto" className="text-danger">
                  Needs Reflown
                </Col>
              }
              {pen_comments.length > 0 &&
                <Col xs="auto">
                  {pen_comments.map ((comment) => {
                    return <div>{comment}</div>
                  })}
                </Col>
              }
            </Row> 
          </Container>
          <hr/>
        </React.Fragment>
      )
      
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}