import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 
import parseActivity from '../../ActivityParser.js'


export default class RecentActivityCard extends React.Component {

  constructor(props) {
    super(props)

    // only expects one of these
    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id
    this.pen_id = props.pen_id

    this.is_admin = props.is_admin || false 

    this.levels = this.is_admin ? ['client', "admin"] : ["client"] // ['client', 'admin', 'general'] include levels of activities that are required

    // filters // only display ones that are in this.levels
    this.level_identifiers = [
      {value: "client", label: "Client"},
      {value: "admin", label: "Admin"},
      //{value: "general", label: "Tracking"}
    ]
    
    this.state = {
      active_levels: this.levels,
      activities: null,
      page: null,
      total_pages: null,
      error_message: null,
    }

    this.listener = this.subscribe()
  }

  render () {
    try {
      const levels = this.levels
      const active_levels = this.state.active_levels
      const page = this.state.page
      const total_pages = this.state.total_pages

      return (
        <Card>
          <Card.Header>
            <Container fluid className="p-0">
              <Row>
                <Col className="p-0">
                  <Card.Title>
                    Recent Activities
                  </Card.Title>
                  {false && 
                    <div className="btn-group m-1 sortmethod-group">
                      {levels.length > 1 && this.level_identifiers.map((level_identifier,i) =>
                        <button 
                          key={level_identifier.value}
                          type="button" style={{fontSize: "1em"}}
                          className={"btn btn-primary text-nowrap p-0 px-1 " + (active_levels.includes(level_identifier.value) ? "selected" : "unselected")}
                          onClick={() => this.selectLevel(level_identifier.value)}>
                            {level_identifier.label}
                        </button>
                      )}
                    </div>
                  }
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>
            {this.generateBody()}
          </Card.Body>
          {total_pages != null && total_pages > 1 &&
            <Card.Footer className="pagination-section">
              <nav>
                <ul className="pagination m-0 pages-footer">
                  {[...Array(total_pages)].map((val, i) =>
                    <li className="page-item" key={i}>
                      <button 
                        className={"page-link" + (this.state.page == i ? " page-selected" : "")} 
                        onClick={() => this.getActivities(i)}>
                          {i+1}
                      </button>
                    </li>
                  )}
                </ul>
              </nav>
            </Card.Footer>
          }
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  generateBody() {
    const activities = this.state.activities
    const error_message = this.error_message

    if (activities == null) {
      if (error_message) {
        return (
          <div className="mt-2 text-center">
            <b>{error_message}</b>
          </div>
        )
      } else {
        return null
      }
    } else if (activities.length == 0) {
      return (<div className="mt-2 text-center">
          {this.feedlot_id != null && 
            <b>Activities Associated with this feedlot and its jobs will display here</b>
          }
          {this.job_id != null && 
            <b>Activities Associated with this job will display here</b>
          }
          {this.pen_id != null && 
            <b>Activities Associated with this pen will display here</b>
          }
      </div>)
    } else {
      return (<Container fluid className="p-0">
        {this.state.activities.map((activity,i) =>
          <ActivityRenderer
            key={activity.id}
            activity={activity}
            feedlot_id={this.feedlot_id}
            job_id={this.job_id}
            pen_id={this.pen_id}
            is_admin={this.is_admin}/>
        )}
      </Container>)
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::RecentActivitiesCardChannel",
        feedlot_id: _this.feedlot_id,
        job_id: _this.job_id,
        pen_id: _this.pen_id,
        active_levels: _this.state.active_levels,
      },
      {  
        connected() {
          _this.getActivities(0)
        },

        received(data) {
          if (data.message == "new_activity") {
            _this.getActivity(data.activity_id)
          } 
          
          else if (data.activities != null) {
            _this.setState({
              activities: data.activities,
              page: data.page,
              total_pages: data.total_pages,
              error_message: null
            })
          } 
          
          else if (data.activity != null) {
            _this.updateActivity(data.activity)
          }
        }
      }
    )
  }


  getActivities(page = 0) {    
    this.listener.perform("get_activities", {page: page, levels: this.state.active_levels})
  }

  getActivity(activity_id) {
    this.listener.perform("get_activity", {activity_id: activity_id, levels: this.state.active_levels})
  }

  updateActivity(_activity) {
    this.setState(function(state, props) {
      var activities = state.activities
      var index = activities.findIndex((activity) => activity.id == _activity.id);
      if(index == -1) {
        activities = [_activity, ...activities]
      } else {
        activities[index] = _activity
      }
      
      return {
        activities: activities
      }
    })
  }

  selectLevel(level) {
    this.setState(function(state, props) {
      var active_levels = state.active_levels
      if(active_levels.includes(level)) {
        active_levels = difference(active_levels, [level])
      } else {
        active_levels = [...active_levels, level]
      }
      return {active_levels: active_levels}
    }, () => {
      this.getActivities(0)
    })
  }

}

class ActivityRenderer extends React.Component {
  constructor(props) {
    super(props)

    this.is_admin = props.is_admin
    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id
    this.pen_id = props.pen_id

    this.state = {
      activity: props.activity
    }
  }

  render () {
    try {
      const activity = this.state.activity
      var show_self_links = true
      var show_parent_links = true
      if (activity.trackable_type == "Feedlot" && activity.trackable_id == this.feedlot_id) {
        show_self_links = false
        show_parent_links = false
      } else if(activity.trackable_type == "Job" && activity.trackable_id == this.job_id) {
        show_self_links = false
        show_parent_links = false
      } else if(activity.trackable_type == "Job" && this.feedlot != null) {
        show_parent_links = false
      } else if(activity.trackable_type == "Pen" && activity.trackable_id == this.pen_id) {
        show_self_links = false
        show_parent_links = false
      }

      var {title, message, time} = parseActivity(activity, show_parent_links, show_self_links, this.is_admin)
      var border_class = "client_activity"
      if (activity.level == "admin") {
        border_class = "admin_activity"
      }

      return (
        <Row className={`card-body-row ${border_class}`} style={{fontSize: "1em"}}><Col>
          <Row>
            <Col>
              <b>
                {title}
              </b>
            </Col>
            <Col xs="auto">
              <i>
                {time}
              </i>
            </Col>
          </Row>
          <Row>
            <Col>
              {message}
            </Col>
          </Row>
        </Col></Row>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}

