import React from "react"
import {Card, Container, Row, Col, Dropdown} from 'react-bootstrap' 

export default class FeedlotDescriptionCard extends React.Component {

  constructor(props) {
    super(props)

    this.feedlot_id = props.feedlot_id
    this.feedlots = props.feedlots
    this.is_admin = props.is_admin || false
    
    this.state = {
      feedlot: null,
    }

    this.getFeedlot = this.getFeedlot.bind(this)
    this.listener = this.subscribe()

    this.editKhamInfo = this.editKhamInfo.bind(this)
    this.editDroneLinkEmail = this.editDroneLinkEmail.bind(this)
    this.editFeedlotName = this.editFeedlotName.bind(this)
    this.editMicroInfo = this.editMicroInfo.bind(this)
    this.setArchive = this.setArchive.bind(this)
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  render () {
    try {
      var is_admin = this.is_admin
      var feedlot = this.state.feedlot
      if(feedlot == null) {
        return null
      }

      var feedlot_path = is_admin ? Routes.admin_feedlot_path(this.feedlot_id) : Routes.feedlot_path(this.feedlot_id)

      return (
        <Card>
          <Card.Header>
            <Container fluid className="pl-0 pr-4">
              <Row>
                <Col className="p-0">
                  <Card.Title style={{minWidth: "max-content", display: "block"}} className="m-0">
                    <a href={feedlot_path} style={{color: "inherit"}}>{`Feedlot: ${feedlot.name}`}</a>
                  </Card.Title>
                </Col>
                
                <Col xs="auto" className="p-0">
                  <span className="label">
                    Created At:
                  </span>
                  <span className="field"> 
                    {feedlot.created_at}
                  </span>
                </Col>
              </Row>

              <Dropdown>
                  <Dropdown.Toggle 
                    as="i" 
                    className="btn-icon fas fa-ellipsis-v dropdown-no-arrow"
                    bsPrefix=""
                    style={{fontSize: "1.5em"}}>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.editDroneLinkEmail}>
                      Edit DroneLink Email
                    </Dropdown.Item>
                    {is_admin && <React.Fragment>
                      <hr className="text-primary"/>
                      <Dropdown.Item onClick={this.editFeedlotName}>
                        Edit Feedlot Name
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.editKhamInfo}>
                        Edit Khameleon Info
                      </Dropdown.Item>
                      
                      {feedlot.archived == false &&
                        <Dropdown.Item 
                          className="text-danger"
                          onClick={this.setArchive}>
                          Archive
                        </Dropdown.Item>
                      }
                    </React.Fragment>}
                  </Dropdown.Menu>
                </Dropdown>
            </Container>
          </Card.Header>

          <Card.Body style={{height: "auto"}}>
            <Container fluid>
            <Row>
                <Col xs="auto">
                  <table style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><tbody>
                    {is_admin == true && feedlot.archived == true &&
                      <tr>
                        <td className="label">
                          Archived: 
                        </td>
                        <td className="field">
                          <div className="text-danger" style={{display: "inline"}}>
                            True
                          </div>
                        </td>
                      </tr>
                    }
                    <tr>
                      {(is_admin == true || feedlot.is_micro == true) && <React.Fragment>
                        <td className="label">
                        Connected To Micro?: 
                        </td>
                        <td className="field">
                          <div style={{display: "inline"}}>
                            {feedlot.is_micro ? "Yes" : "No"}
                          </div>
                          {is_admin == true && feedlot.is_micro == false &&
                            <React.Fragment>
                              <i className="btn-icon fas fa-toggle-off ml-2" 
                                data-tip data-for="set-micro-tooltip"
                                onClick={() => {this.editMicroInfo(true)}}/>
                              <ReactTooltip id='set-micro-tooltip' effect='solid' 
                                 

                                textColor="black">
                                <span>Give Micro Access</span>
                              </ReactTooltip>
                            </React.Fragment>
                          }
                          {is_admin == true && feedlot.is_micro == true &&
                            <React.Fragment>
                              <i className="btn-icon fas fa-toggle-on ml-2" 
                                data-tip data-for="set-micro-tooltip"
                                onClick={() => {this.editMicroInfo(false)}}/>
                              <ReactTooltip id='set-micro-tooltip' effect='solid' 
                                 

                                textColor="black">
                                <span>Remove Micro Access</span>
                              </ReactTooltip>
                          </React.Fragment>
                          }
                        </td>
                      </React.Fragment>}
                    </tr>
                  </tbody></table>
                </Col>
                {is_admin == true &&
                  <Col xs="auto">
                    <table style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><tbody>
                      <tr>
                        <td className="label">
                          Khameleon Name: 
                        </td>
                        <td className="field">
                          {feedlot.kham_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          County: 
                        </td>
                        <td className="field">
                          {feedlot.location_name}
                        </td>
                      </tr>
                    </tbody></table>
                  </Col>
                }
                
                <Col xs="auto">
                  <table style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><tbody>
                    <tr>
                      <td className="label">
                        Preferred DroneLink Email: 
                      </td>
                      <td className="field">
                        {feedlot.dronelink_email}
                      </td>
                    </tr>
                  </tbody></table>
                </Col>
                {is_admin == true &&
                  <Col xs="auto">
                      <a className="btn btn-primary m-1"
                        href={Routes.pen_boundaries_admin_feedlot_path(this.feedlot_id)}
                        data-tip data-for="export-csv-tooltip">
                        Boundaries
                      </a>
                      <ReactTooltip id='export-csv-tooltip' effect='solid' 
                         

                        textColor="black">
                        <span>View/Edit Boundaries</span>
                      </ReactTooltip>
                  </Col>
                }
              </Row>
            </Container>
          </Card.Body>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::FeedlotDescriptionCardChannel",
        feedlot_id: this.feedlot_id
      },
      {  
        connected() {
          _this.getFeedlot()
        },

        received(data) {
          if (data.feedlot != null) {
            _this.setState({
              feedlot: data.feedlot
            })
          } else if(data.message == "feedlot_updated") {
            _this.getFeedlot()
          }
        }
      }
    )
  }

  getFeedlot() {
    this.listener.perform("get_feedlot")
  }

  editKhamInfo() {
    postal.publish({
      channel: "EditFeedlotModal",
      topic: "open",
      data: {
        feedlot_id: this.feedlot_id,
        type: "kham_info"
      }
    })
  }

  editDroneLinkEmail() {
    postal.publish({
      channel: "EditFeedlotModal",
      topic: "open",
      data: {
        feedlot_id: this.feedlot_id,
        type: "dronelink_email"
      }
    })
  }

  editFeedlotName() {
    postal.publish({
      channel: "EditFeedlotModal",
      topic: "open",
      data: {
        feedlot_id: this.feedlot_id,
        type: "feedlot_name"
      }
    })
  }

  editMicroInfo(bool_value) {
    if (ask("Are You Sure You Want To Change This Value?")) {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      jform.append('is_micro', bool_value);
      
      $.ajax({
        url: Routes.set_is_micro_admin_feedlot_path(this.feedlot_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", `Successfully Set Is Micro to ${bool_value}`)
          } else {
            postAlert("danger", `Failed To Set Micro Value`)
          }
        },
        error: function(request, textStatus, errorThrown) {
          postAlert("danger", `Failed To Set Micro Value`)
        },
        complete: function() {}
      })
    }
  }

  setArchive() { 
    if (ask("Are You Sure You Want To Archive This Feedlot?")) {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      
      $.ajax({
        url: Routes.archive_admin_feedlot_path(this.feedlot_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", `Successfully Archived Feedlot`)
          } else {
            postAlert("danger", `Failed To Archive Feedlot`)
          }
        },
        error: function(request, textStatus, errorThrown) {
          postAlert("danger", `Failed To Archive Feedlot`)
        },
        complete: function() {}
      })
    }
  }
}