import React from "react"

export default class Zoom extends React.Component {

  constructor(props) {
    super(props)

    this.fontSizePercentage = parseInt(getCookie("font-size-percentage")) || 100
    this.setZoom()
  }

  render () {
    try {
      return (
        <React.Fragment>
          <div className="input-group">
            <div className="input-group-prepend m-0">
              <div className="btn btn-outline-primary fas fa-minus"
                data-tip data-for="zoom-out-tooltip"
                onClick={()=>{this.zoom("out")}}>
              </div>
            </div>

            <div className="input-group-append m-0">
              <div className="btn btn-outline-primary fas fa-plus"
                data-tip data-for="zoom-in-tooltip"
                onClick={()=>{this.zoom("in")}}>
              </div>
            </div>
          </div>
          <ReactTooltip id='zoom-out-tooltip' effect='solid' 
            place="right"
             

            textColor="black">
            <span>Decrease Font Size</span>
          </ReactTooltip>
          <ReactTooltip id='zoom-in-tooltip' effect='solid' 
            place="right"
             

            textColor="black">
            <span>Increase Font Size</span>
          </ReactTooltip>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  setZoom() {
    /*$('body')[0].style.fontSize = `${this.fontSizePercentage/100}rem`;
    //var header_height = $('#header')[0].clientHeight
    //$('.bd-sidebar')[0].style.height = `calc(100vh - ${header_height}px`*/
  }

  zoom(direction = "in") {
    if (direction == "in" && this.fontSizePercentage < 200) {
      this.fontSizePercentage += 5
    } else if (direction == "out" && this.fontSizePercentage > 50) {
      this.fontSizePercentage -= 5
    }
    setCookie("font-size-percentage", this.fontSizePercentage, 360)

    this.setZoom()
  }
}