import React from "react"
import {Container, Row, Col} from 'react-bootstrap' 

/*
  General Use Modal for editing pen fields
*/
export default class EditFeedlotModal extends React.Component {

  constructor(props) {
    super(props);

    // each type denotes which detail of the pen is going to be modified
    this.types = [
      {value: "kham_info", title: "Edit Khameleon Information"},
      {value: "dronelink_email", title: "Edit Default DroneLink Email"},
      {value: "feedlot_name", title: "Edit Feedlot Name"},
      {value: "create_feedlot", title: "Create New Feedlot"}
    ]
    
    this.state = {
      feedlot_id: props.feedlot_id || null,
      type: props.type || null
    }

    this.listener = this.postalListener()
  }

  postalListener() {
    var _this = this
    return postal.subscribe({
      channel: "EditFeedlotModal",
      topic: "open",
      callback: function(data, envelope) {
        _this.setState({
          feedlot_id: data.feedlot_id,
          type: data.type,
        }, () => {
          if (_this.isValidState()) {
            $('#edit-feedlot-modal').modal("show");
          }
        })
      }
    });
  }

  static close() {
    $('#edit-feedlot-modal').modal("hide");
  }

  isValidState() {
    console.log(this.state.type)
    var type = this.types.filter((possible_type) => this.state.type == possible_type.value)[0]
    console.log(type)
    if (type?.value == "create_feedlot") {
      return true
    }
    if (type == null || this.state.feedlot_id == null) {
      return false
    }
    return true
  }

  render () {
    try {
      if (this.isValidState() == false) {
        return null
      }

      var type = this.types.filter((possible_type) => this.state.type == possible_type.value)[0]

      return (
        <div className="modal fade" id="edit-feedlot-modal" role="dialog" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered" style={{minWidth: "50vw"}}>
            <div className="modal-content">
              <div className="modal-header">
                <Container>
                  <Row>
                    <Col>
                      <h5 className="modal-title">
                        {type.title}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <button className="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">×</span>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid className="modal-body">
                {type.value == "kham_info" && 
                  <KhamName
                    key={this.state.feedlot_id + type.value}
                    feedlot_id={this.state.feedlot_id}
                  />
                }
                {type.value == "dronelink_email" &&
                  <DronelinkEmail
                  key={this.state.feedlot_id + type.value}
                  feedlot_id={this.state.feedlot_id}
                  />
                }
                {type.value == "feedlot_name" &&
                  <FeedlotName
                  key={this.state.feedlot_id + type.value}
                  feedlot_id={this.state.feedlot_id}
                  />
                }
                {type.value == "create_feedlot" &&
                  <CreateFeedlot
                  key={type.value}
                  />
                }
              </Container>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error);
      return null
    }
  }
}


class KhamName extends React.Component {

  constructor(props) {
    super(props);

    this.feedlot_id = props.feedlot_id
    
    this.state = {
      loaded_data: false,
      kham_name: null,
      location_id: null,
      location_name: null,
      error_message: null,
      
      counties: []
    }

    this.nameInput = React.createRef()
    this.countiesInput = React.createRef()
    
    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var kham_name = this.state.kham_name
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Khameleon Name
                    </span>
                  </div>
                  <input 
                    ref={this.nameInput}
                    type="text" 
                    className="form-control"
                    defaultValue={kham_name || ""}/>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Location
                    </span>
                  </div>
                  <select ref={this.countiesInput} defaultValue={this.state.location_id} className="custom-select" style={{width: 'fit-content'}}>
                    <option value={null}></option>
                    {this.state.counties.map((value, index) => {
                      return (
                        <option value={value.location_no}>
                          {value.location}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: Routes.edit_kham_data_admin_feedlot_path(this.feedlot_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            kham_name: response.kham_name,
            location_id: response.location_id,
            location_name: response.location_name,
            error_message: null
          }, () => {
            _this.getCountyInfo()
          })
        } else {
          _this.setState({
            loaded_data: false,
            error_message: "Failed To Get Feedlot Data",
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({        
          loaded_data: false,
          error_message: "Failed To Get Feedlot Data",
        })
      },
      complete: function() {}
    })
  }

  // request the counties and change state to display results
  getCountyInfo() {
    const _this = this
    const form_authenticity_token = this.props.form_authenticity_token
    var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token);
    $.ajax({
      url: Routes.counties_admin_feedlots_path(),
      type: "GET",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          _this.setState({
            counties: response.locations,
            loaded_data: true,
            error_message: null,
          })
        } else {
          _this.setState({
            loaded_data: false,
            error_message: "Could Not Load Counties",
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          loaded_data: false,
          error_message: "Could Not Load Counties",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var kham_name = this.nameInput.current?.value || ""
    const location_id = this.countiesInput.current.value
    const location_name = this.countiesInput.current.selectedOptions[0].innerHTML
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('kham_name', kham_name);
    jform.append('location_id', location_id);
    jform.append('location_name', location_name);
    
    $.ajax({
      url: Routes.edit_kham_data_admin_feedlot_path(this.feedlot_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditFeedlotModal.close()
          postAlert("success", "Successfully Updated Job Name")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Update Name",
        })
      },
      complete: function() {}
    })
  }
}

class DronelinkEmail extends React.Component {

  constructor(props) {
    super(props);

    this.feedlot_id = props.feedlot_id
    
    this.state = {
      loaded_data: false,
      dronelink_email: null,
      error_message: null,
    }

    this.emailInput = React.createRef()

    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var dronelink_email = this.state.dronelink_email
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      DroneLink Email
                    </span>
                  </div>
                  <input 
                    ref={this.emailInput}
                    type="text"
                    className="form-control"
                    defaultValue={dronelink_email || ""}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: Routes.set_dronelink_email_feedlot_path(this.feedlot_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            dronelink_email: response.dronelink_email,
            loaded_data: true,
            error_message: null
          })
        } else {
          _this.setState({
            loaded_data: false,
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          loaded_data: false,
          error_message: "Failed To Get Feedlot Data",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var dronelink_email = this.emailInput.current?.value || ""
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('email', dronelink_email);
    
    $.ajax({
      url: Routes.set_dronelink_email_feedlot_path(this.feedlot_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditFeedlotModal.close()
          postAlert("success", "Successfully Updated DroneLink Email")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Update DroneLink Email",
        })
      },
      complete: function() {}
    })
  }
}

class FeedlotName extends React.Component {

  constructor(props) {
    super(props);

    this.feedlot_id = props.feedlot_id
    
    this.state = {
      loaded_data: false,
      feedlot_name: null,
      error_message: null,
    }

    this.nameInput = React.createRef()

    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var feedlot_name = this.state.feedlot_name
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Feedlot Name
                    </span>
                  </div>
                  <input 
                    ref={this.nameInput}
                    type="text"
                    className="form-control"
                    defaultValue={feedlot_name || ""}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: Routes.edit_name_admin_feedlot_path(this.feedlot_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            feedlot_name: response.feedlot_name,
            loaded_data: true,
            error_message: null
          })
        } else {
          _this.setState({
            loaded_data: false,
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          loaded_data: false,
          error_message: "Failed To Get Feedlot Data",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var feedlot_name = this.nameInput.current?.value || ""
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('new_name', feedlot_name);
    
    $.ajax({
      url: Routes.edit_name_admin_feedlot_path(this.feedlot_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditFeedlotModal.close()
          postAlert("success", "Successfully Updated Feedlot Name")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Update Feedlot Name",
        })
      },
      complete: function() {}
    })
  }
}

class CreateFeedlot extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error_message: null,
    }

    this.nameInput = React.createRef()
    this.submit = this.submit.bind(this)
  }

  render () {
    var feedlot_name = this.state.feedlot_name
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {error_message != null && 
          <Row>
            <Col>
              <h6 className="text-danger text-center">{error_message}</h6>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  Feedlot Name
                </span>
              </div>
              <input 
                ref={this.nameInput}
                type="text"
                className="form-control"
                defaultValue={feedlot_name || ""}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="btn btn-primary" onClick={this.submit}>
              Submit
            </button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  submit() {
    var feedlot_name = this.nameInput.current?.value || ""
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('name', feedlot_name);
    
    $.ajax({
      url: Routes.create_feedlot_admin_feedlots_path(),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          window.location = Routes.admin_feedlot_path(response.feedlot_id)
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Create Feedlot Name",
        })
      },
      complete: function() {}
    })
  }
}