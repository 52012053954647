import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class NotifSettingsCard extends React.Component {

  constructor(props) {
    super(props)

    this.is_admin = props.is_admin || false
    this.feedlot_id = props.feedlot_id

    this.emailNotifs = React.createRef()
    this.smsNotifs = React.createRef()
    
    this.state = {
      status: "Loading",
      settings: null,
    }

    this.getSettings = this.getSettings.bind(this)
    this.updateSettings = this.updateSettings.bind(this)

    this.listener = this.subscribe()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state.settings != null && prevState.settings != this.state.settings) {
      if(this.emailNotifs.current != null) {
        this.emailNotifs.current.checked = this.state.settings.email_notifications
      }
      if(this.smsNotifs.current != null) {
        this.smsNotifs.current.checked = this.state.settings.sms_notifications
      }
    }
  }

  render () {
    const settings = this.state.settings
    const status = this.state.status
    const phone_number = this.state.settings?.phone_number
    try {
      return (
        <Card id="notif-settings">
          <Card.Header>
            <Card.Title>
              Notification Settings
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {settings == null &&
              <div className="mt-2 text-center">
                <b>{status}</b>
              </div>
            }
            {settings != null &&
              <Container fluid className="p-2">
                <Row className="row-gap-3">
                  <Col xs="12">
                    <b>Enable/Disable Notifications for Job Complete and Flight Plan Ready</b>
                  </Col>
                
                  {phone_number == null && 
                    <Col xs="12">
                      <Row>
                        <Col xs="auto" className="p-0">
                          <i>
                            Note:
                          </i>
                        </Col>
                        <Col className="p-0 pl-2">
                          <i>
                            No Phone Number On Record. <br/>Enter Phone Number in <a href={Routes.home_settings_path()}>Profile Settings</a> to utilize sms notifications. 
                          </i>
                        </Col>
                      </Row>
                    </Col>
                  }
                  <Col xs="12">
                    <div style={{lineHeight: "1.1rem"}}>
                      <div>
                        Email Notifs: 
                        <input ref={this.emailNotifs} className="ml-2" type="checkbox" defaultChecked={settings.email_notifications}/>
                      </div>

                      <div>
                        SMS Notifs: 
                        <input ref={this.smsNotifs} className="ml-2" disabled={phone_number == null} type="checkbox" defaultChecked={settings.sms_notifications}/> 
                      </div>
                    </div>
                  </Col>
                  
                  <Col xs="12">
                    <button 
                      className="btn btn-primary"
                      onClick={this.updateSettings}>
                      Submit
                    </button>
                  </Col>
                </Row>
              </Container>
            }
          </Card.Body>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::NotifSettingsChannel",
        feedlot_id: this.feedlot_id
      },
      {  
        connected() {
          _this.getSettings()
        },

        received(data) {
          if (data.settings != null) {
            _this.setState({
              settings: data.settings
            })
          } else if (data.message == "updated") {
            _this.getSettings()
          } else if (data.message == "no-share") {
            _this.setState({
              settings: null,
              status: "Notification Settings Not Applicable"
            })
          } else if (data.message == "Failed") {
            _this.setState({
              settings: null,
              status: "Failed To Load Data"
            })
          }
        }
      }
    )
  }

  getSettings() {
    this.listener.perform("get_settings")
  }

  updateSettings() {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token', this.props.form_authenticity_token)
    jform.append("notification_settings", JSON.stringify([
      {
        feedlot_id: this.feedlot_id,
        email_notifs: this.emailNotifs.current.checked,
        sms_notifs: this.smsNotifs.current.checked
      }])
    )

    var _this = this
    $.ajax({
      url: Routes.home_edit_notification_settings_path(),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          postAlert("success", "Successfully Updated Notification Settings")
        } else {
          postAlert("danger", "Failed To Updated Notification Settings")
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", "Failed To Updated Notification Settings")
      },
      complete: function() {}
    })
  }
}