import React from "react"
import {Container, Row, Col} from 'react-bootstrap' 

/*
  General Use Modal for editing pen fields
*/
export default class EditJobModal extends React.Component {

  constructor(props) {
    super(props);

    // each type denotes which detail of the pen is going to be modified
    this.types = [
      {value: "create_pen", title: "Create Pen"},
      {value: "edit_name", title: "Edit Job Name"},
      {value: "edit_passes", title: "Edit Passes Threshold"}
    ]
    
    this.state = {
      job_id: props.job_id || null,
      type: props.type || null
    }

    this.listener = this.postalListener()
  }

  postalListener() {
    var _this = this
    return postal.subscribe({
      channel: "EditJobModal",
      topic: "open",
      callback: function(data, envelope) {
        _this.setState({
          job_id: data.job_id,
          type: data.type,
        }, () => {
          if (_this.isValidState()) {
            $('#edit-job-modal').modal("show");
          }
        })
      }
    });
  }

  static close() {
    $('#edit-job-modal').modal("hide");
  }

  isValidState() {
    var type = this.types.filter((possible_type) => this.state.type == possible_type.value)[0]
    if (type == null || this.state.job_id == null) {
      return false
    }
    return true
  }

  render () {
    try {
      if (this.isValidState() == false) {
        return null
      }

      var type = this.types.filter((possible_type) => this.state.type == possible_type.value)[0]

      return (
        <div className="modal fade" id="edit-job-modal" role="dialog" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered" style={{minWidth: "50vw"}}>
            <div className="modal-content">
              <div className="modal-header">
                <Container>
                  <Row>
                    <Col>
                      <h5 className="modal-title">
                        {type.title}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <button className="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">×</span>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid className="modal-body">
                {type.value == "create_pen" && 
                  <CreatePen
                    key={this.state.job_id + type.value}
                    job_id={this.state.job_id}
                  />
                }
                {type.value == "edit_name" &&
                  <EditName
                  key={this.state.job_id + type.value}
                  job_id={this.state.job_id}
                  />
                }
                {type.value == "edit_passes" &&
                  <EditPasses
                  key={this.state.job_id + type.value}
                  job_id={this.state.job_id}
                  />
                }
              </Container>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}


class EditName extends React.Component {

  constructor(props) {
    super(props);

    this.job_id = props.job_id
    
    this.state = {
      loaded_data: false,
      job_name: null,
      error_message: null,
    }

    this.nameInput = React.createRef()

    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var job_name = this.state.job_name
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Job Name
                    </span>
                  </div>
                  <input 
                    ref={this.nameInput}
                    type="text" 
                    className="form-control"
                    defaultValue={job_name || "Undefined"}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: Routes.edit_name_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            job_name: response.job_name,
            loaded_data: true,
            error_message: null
          })
        } else {
          _this.setState({
            job_name: null,
            loaded_data: false,
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          job_name: null,          
          loaded_data: false,
          error_message: "Failed To Get Job Data",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var newName = this.nameInput.current?.value || ""
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('new_name', newName);
    
    $.ajax({
      url: Routes.edit_name_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditJobModal.close()
          postAlert("success", "Successfully Updated Job Name")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Update Name",
        })
      },
      complete: function() {}
    })
  }
}

class EditPasses extends React.Component {

  constructor(props) {
    super(props);

    this.job_id = props.job_id
    
    this.state = {
      loaded_data: false,
      passes: null,
      error_message: null,
    }

    this.passesInput = React.createRef()

    this.getData()
    this.submit = this.submit.bind(this)
  }

  render () {
    var passes = this.state.passes
    var loaded_data = this.state.loaded_data
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {loaded_data == false && error_message == null &&
          <h6 className="text-success text-center">Loading</h6>
        }
        {loaded_data == false && error_message != null &&
          <h6 className="text-danger text-center">{error_message}</h6>
        }
        {loaded_data == true &&
          <React.Fragment>
            {error_message != null && 
              <Row>
                <Col>
                  <h6 className="text-danger text-center">{error_message}</h6>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Passes
                    </span>
                  </div>
                  <input 
                    ref={this.passesInput}
                    type="number"
                    className="form-control"
                    defaultValue={passes || 2}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={this.submit}>
                  Submit
                </button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  getData() {
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('get_data',true);
    
    $.ajax({
      url: Routes.edit_passes_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        console.log(response)
        if (response.status == "Success") {
          _this.setState({
            passes: response.passes,
            loaded_data: true,
            error_message: null
          })
        } else {
          _this.setState({
            passes: null,
            loaded_data: false,
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          passes: null,
          loaded_data: false,
          error_message: "Failed To Get Job Data",
        })
      },
      complete: function() {}
    })
  }

  submit() {
    var passes = this.passesInput.current?.value
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('passes', passes);
    
    $.ajax({
      url: Routes.edit_passes_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          EditJobModal.close()
          postAlert("success", "Successfully Updated Passes Threshold")
          _this.setState({
            error_message: null
          })
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Passes Threshold",
        })
      },
      complete: function() {}
    })
  }
}

class CreatePen extends React.Component {

  constructor(props) {
    super(props);

    this.job_id = props.job_id
    
    this.state = {
      loaded_data: false,
      error_message: null,
    }

    this.nameInput = React.createRef()

    this.submit = this.submit.bind(this)
  }

  render () {
    var error_message = this.state.error_message

    return (
      <React.Fragment>
        {error_message != null && 
          <Row>
            <Col>
              <h6 className="text-danger text-center">{error_message}</h6>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  Pen Name
                </span>
              </div>
              <input 
                ref={this.nameInput}
                type="text" 
                className="form-control"/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="btn btn-primary" onClick={this.submit}>
              Submit
            </button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  submit() {
    var pen_name = this.nameInput.current?.value || ""
    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('pen_name', pen_name);
    
    $.ajax({
      url: Routes.create_pen_admin_job_path(this.job_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          if (_this.nameInput.current != null) {
            _this.nameInput.current.value = ""
          }
          
          EditJobModal.close()
          postAlert("success", "Successfully Created Pen")
          //window.location = Routes.admin_pen_path(response.pen_id)
        } else {
          _this.setState({
            error_message: response.message,
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({
          error_message: "Failed To Create Pen",
        })
      },
      complete: function() {}
    })
  }
}