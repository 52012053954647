import React from "react"
import {Container, Row, Col, NavLink} from 'react-bootstrap' 


class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.user = props.user
    this.firstName = this.user.first_name
    this.lastName = this.user.last_name
    this.discordName = this.user.discord_name
    this.is_counter = this.user.counter_level != null
    this.phoneNumber = formatPhoneNumber(this.user.phone_number)
    this.notificationSettings = props.notificationSettings

    this.firstNameInput = React.createRef() 
    this.lastNameInput = React.createRef() 
    this.discordNameInput = React.createRef()
    this.phoneNumberInput = React.createRef() 

    this.state = {
      alerts: [],
      messages: []
    }

    this.submitNameAndNumber = this.submitNameAndNumber.bind(this);
    this.submitNotificationChanges = this.submitNotificationChanges.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render () {
    try {
      return (
        <React.Fragment>
          <Col xs={12}>
            <Row>
              <Col>
                <button className="btn btn-link txt-xlg d-block" onClick={()=>history.back()}>Back</button>
                <h3 className="d-block">Profile Settings</h3>
                <hr className="mt-0 mb-0" style={{width: "100%"}}/>
                <i>* Required</i>
              </Col>
            </Row>

            {(this.state.alerts.length > 0 || this.state.messages.length > 0) && 
              <div className="row mt-3">
                {this.state.alerts.map(function(alert, i) {
                  return <div className="alert alert-danger" role="alert" style={{width: "100%"}}>
                    {alert}
                  </div>
                })}

                {this.state.messages.map(function(message, i) {
                  return <div className="alert alert-primary" role="alert" style={{width: "100%"}}>
                    {message}
                  </div>
                })}
              </div>
            }
            
            <div className="row mt-3">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">* First Name</span>
                </div>
                <input ref={this.firstNameInput} type="text" className="form-control" defaultValue={this.firstName} aria-label="First Name" />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">* Last Name</span>
                </div>
                <input ref={this.lastNameInput} type="text" className="form-control" defaultValue={this.lastName} aria-label="Last Name" />
              </div>
            
              {this.is_counter && 
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">Discord Name</span>
                  </div>
                  <input ref={this.discordNameInput} type="text" className="form-control" defaultValue={this.discordName} aria-label="Discord Name" />
                </div>
              }

              <i>This field is used to send update notifications via SMS</i>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">Phone Number</span>
                </div>
                <input ref={this.phoneNumberInput} type="text" className="form-control" defaultValue={this.phoneNumber} aria-label="Phone Number" onKeyPress={phoneNumberFormatter} />
              </div>
            </div>

            <div className="row mt-3">
              <button type="button" className="btn btn-primary" onClick={this.submitNameAndNumber}>Submit Name And Number</button>
            </div>

            <div className="row mt-3">
              <h4>Notification Settings</h4>
              <i className="ml-3" style={{margin: "auto"}}>Example notifications you will receive are "Job is Done" and "Flight Plan is Ready"</i>
              <hr className="mt-0 mb-0" style={{width: "100%"}}/>
              <i>Note: Phone Number Required for SMS Notifications</i>
            </div>

            {this.notificationSettings.map(function (feedlotSettings, i) {
              return <div className="row mb-3">
                <div style={{display: "inline"}}>
                  {feedlotSettings.feedlot_name} - 
                  <div className="form-check-inline ml-2">
                    <input className="form-check-input" type="checkbox" defaultChecked={feedlotSettings.email_notifs} id={`feedlot_${feedlotSettings.feedlot_id}_email_notifs`}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault" >
                      Email Notifications
                    </label>
                  </div>
                  <div className="form-check-inline ml-2">
                    <input className="form-check-input" type="checkbox" defaultChecked={feedlotSettings.sms_notifs} id={`feedlot_${feedlotSettings.feedlot_id}_sms_notifs`}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault" >
                      SMS Notifications
                    </label>
                  </div>
                </div>
              </div>
            })}

            <div className="row mt-5">
              <button type="button" className="btn btn-primary" onClick={this.submitNotificationChanges}>Submit Notification Settings</button>
            </div>
          </Col>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  componentDidMount () {}

  submitNameAndNumber() {
    var first_name = this.firstNameInput.current.value
    var last_name = this.lastNameInput.current.value
    var discord_name = this.is_counter ? this.discordNameInput.current.value : null
    var phone_number = this.phoneNumberInput.current.value.replace(/[^\d]/g, "")

    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token', form_authenticity_token())
    jform.append('first_name', first_name)
    jform.append('last_name', last_name)
    jform.append('discord_name', discord_name)
    jform.append('phone_number', phone_number)

    var _this = this
    $.ajax({
      url: `/home/edit_name_and_number`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          location.reload()
        } else {
          _this.setState({alerts: response.errors, messages: []})
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({alerts: ["Failed to Update Name And Number Settings"], messages: []})
      },
      complete: function() {
 
      }
    })
  }

  submitNotificationChanges() {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token', form_authenticity_token())
    jform.append("notification_settings", JSON.stringify(this.notificationSettings.map(feedlotSettings => {
      var results = {}
      results.feedlot_id = feedlotSettings.feedlot_id
      results.email_notifs = document.getElementById(`feedlot_${results.feedlot_id}_email_notifs`).checked
      results.sms_notifs = document.getElementById(`feedlot_${results.feedlot_id}_sms_notifs`).checked
      return results
    })))

    var _this = this
    $.ajax({
      url: Routes.home_edit_notification_settings_path(),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          location.reload()
        } else {
          _this.setState({alerts: response.errors, messages: []})
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({alerts: ["Failed to Update Notification Settings"], messages: []})
      },
      complete: function() {
 
      }
    })
  }
}

export default Settings
