import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 
import DraggableScroll from '../../DraggableScroll'

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class CountingProgress extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      status: "connecting",
      jobs: [],
    }

    this.listener = this.subscribe()
  }

  render () {
    try {

      const { jobs } = this.state

      // group jobs by counting finished, actively being counted, and watiting for images, and needs flight plan
      var job_groups = {finished: [], active: [], waiting: [], needs_flight_plan: []}
      jobs.forEach((job) => {
        if (job.counting_finished == true && job.images_count > 0) {
          job_groups.finished.push(job)
        } else if (job.images_count > 0) {
          job_groups.active.push(job)
        } else if (job.flight_plan_uploaded == true || job.skip_fp_client_notification == true) {
          job_groups.waiting.push(job)
        } else {
          job_groups.needs_flight_plan.push(job)
        }
      })

      // sort finished jobs by finished_at
      job_groups.finished.sort((a, b) => { return a.finished_at - b.finished_at })
      
      // sort active jobs by remaining count
      job_groups.active.sort((a, b) => { return a.remaining_count - b.remaining_count })

      // sort waiting for image jobs by flight plan generated at
      job_groups.waiting.sort((a, b) => { return a.flight_plan_uploaded_at - b.flight_plan_uploaded_at })

      // sort needs_flight plan jobs by created_at
      job_groups.needs_flight_plan.sort((a, b) => { return a.created_at - b.created_at })


      return (
        <Card id="counting-progress" className="bg-light">
          <Card.Header>
            <Container fluid className="p-0">
              <Row className="justify-content-between">
                <Col xs="auto" className="p-0">
                  <Card.Title>
                    Job Progress
                  </Card.Title>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>

            {/*Job Needs Flight Plan*/}
            <Card.Header className="bg-danger rounded-0">
              <h5 className="ml-2 my-auto">FlightPlan Needs Sent</h5>
            </Card.Header>
            <Container className="pl-2 bg-light">
              <Row>
                {job_groups.needs_flight_plan.map((job) => {
                  return <JobObject 
                    key={job.id}
                    type="needs_flight_plan"
                    job={job}
                    colorCode="danger"
                  />
                })}
              </Row>
            </Container>
                
            {/*Jobs that are finished counting*/}
            <Card.Header className="bg-success rounded-0">
              <h5 className="ml-2 my-auto">Counting Completed</h5>
            </Card.Header>
            <Container className="pl-2 bg-light">
              <Row>
                {job_groups.finished.map((job) => {
                  return <JobObject 
                    key={job.id}
                    type="finished"
                    job={job}
                    colorCode="success"
                  />
                })}
              </Row>
            </Container>

            {/*Jobs that are actively being counted*/}
            <Card.Header className="bg-warning rounded-0">
              <h5 className="ml-2 my-auto">In Progress</h5>
            </Card.Header>
            <Container className="pl-2 bg-light">
              <Row>
                {job_groups.active.map((job) => {
                  return <JobObject 
                    key={job.id}
                    type="active"
                    job={job}
                    colorCode="warning"
                  />
                })}
              </Row>
            </Container>

            {/*Job Waiting on Images*/}
            <Card.Header className="bg-dark rounded-0">
              <h5 className="ml-2 my-auto">Waiting On Images</h5>
            </Card.Header>
            <Container className="pl-2 bg-light">
              <Row>
                {job_groups.waiting.map((job) => {
                  return <JobObject 
                    key={job.id}
                    type="waiting"
                    job={job}
                    colorCode="dark"
                  />
                })}
              </Row>
            </Container>

          </Card.Body>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "AdminChannels::CountingProgressChannel",
      },
      {  
        connected() {
          _this.getJobs()
        },

        received(data) {
          console.log(data)
          if (data.jobs != null) {
            console.log(data.jobs)
            _this.setState({
              status: "ok",
              jobs: data.jobs
            })
          } else if (data.job != null) {
            _this.updateJob(data.job)
          } else if (data.message == "pen_updated" || data.message == "job_updated") {
            _this.getJob(data.job_id)
          } else if (data.message == "job_removed") {
            _this.removeJob(data.job_id)
          } else if (data.message == "flight_plan_updated") {
            const {job_id, flight_plan_request} = data
            console.log("flight plan updated")
            _this.updateFlightPlan(job_id, flight_plan_request)
          }
        },

        disconnected() {
          _this.setState({status: "disconnected"})
        }
      }
    )
  }

  getJobs() {
    this.setState({status: "loading"})
    this.listener.perform("get_jobs")
  }

  getJob(id) {
    this.listener.perform("get_job", {id: id})
  }

  // remove Job from state
  removeJob(id) {
    this.setState(function(state, props) {
      return {
        jobs: state.jobs.filter((job) => job.id != id)
      }
    })
  }

  // update Job in state
  updateJob(job_to_update) {

    // remove job if job is ignored, archived, or finished
    if (job_to_update.ignore || job_to_update.archived || job_to_update.finished) {
      this.removeJob(job_to_update.id)
      return
    }


    this.setState(function(state, props) {

      var exists = false
      var jobs = state.jobs.map((job) => {
        if (job.id == job_to_update.id) {
          exists = true
          return job_to_update
        } else {
          return job
        }
      })
      
      return {
        jobs: exists ? [...jobs] : [...jobs, job_to_update]
      }
    })
  }

  // update flight plan from flight plan data
  updateFlightPlan(job_id, flight_plan_request) {
    this.setState(function(state, props) {
      var jobs = state.jobs.map((job) => {
        if (job.id == job_id && job.flight_plan_request?.id == flight_plan_request?.id) {
          job.flight_plan_request = flight_plan_request
        }
        return job
      })
      return {
        jobs: [...jobs]
      }
    })
  }
}

class JobObject extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      job: props.job,
      type: props.type,
      colorCode: props.colorCode,

      fp_status_info: null,
      fp_heart_beats: null,
    }

    if (props.type == "needs_flight_plan") {
      this.fp_listener = this.subscribe()
    }
  }

  // props updated
  componentDidUpdate(prevProps) {
    if (prevProps.job != this.props.job || prevProps.type != this.props.type) {
      this.setState({job: this.props.job, type: this.props.type})
    }
  }

  render () {
    try {
      const { job, type, colorCode, fp_heart_beats, fp_status_info } = this.state
      const { 
        id,
        name,
        feedlot_id,
        feedlot_name,
        counting_finished,
        counting_finished_at, 
        flight_plan_uploaded,
        flight_plan_uploaded_at,
        fpr_ids,
        plan_created,
        pen_count, 
        completed_count, 
        review_count,
        refly_count, 
        images_count,
        images_uploaded_at,
        passes, // format - [{pass: n, count: c}] // ex. - [{pass: 0, count: 5}, {pass: 1, count: 10}, {pass: 2, count: 15}]
        created_at,
       } = job
      
      return (
        <React.Fragment>
          <Col xs="12" className={`job-card shadow-sm border-${colorCode}`}>
            <Row>
              <Col xs="6">
                <a className="text-primary font-weight-bold" href={Routes.admin_job_path(id)}>{name}</a> - <a className="text-secondary" href={Routes.admin_feedlot_path(feedlot_id)}>{feedlot_name}</a>
              </Col>

              {type == "finished" && 
                <Col xs="6" className="text-right">
                  Finished: {counting_finished_at}
                </Col>
              }
              {type == "active" && 
                <Col xs="6" className="text-right">
                  Images Uploaded: {images_uploaded_at}
                </Col>  
              }
              {type == "waiting" && 
                <Col xs="6" className="text-right">
                  Flight Plan Uploaded: {flight_plan_uploaded_at}
                </Col>  
              }
              {type == "needs_flight_plan" && 
                <React.Fragment>
                  <Col xs="6" className="text-right">
                    Created: {created_at}
                  </Col>
                </React.Fragment>
              }
            </Row> 
            <hr className="m-1"/>
            <Row>
              <Col xs="auto">
                Progress: {completed_count}/{pen_count}
              </Col>

              <Col xs="auto">
                Images: {images_count}
              </Col>

              {review_count > 0 &&
                <Col xs="auto" className="text-warning">
                  Needs Reviewed: {review_count}
                </Col>
              }

              {refly_count > 0 &&
                <Col xs="auto" className="text-danger">
                  Needs Reflown: {refly_count}
                </Col>
              }

              {(type == "active" || type == "finished") &&
                <Col xs="auto">
                  Passes: 
                  {passes.map(({pass, count}) => {
                    return <span className="mx-1">
                        <i>{pass}</i>:{count}
                      </span>
                  })}
                </Col>
              }

              {type == "needs_flight_plan" &&
                <Col xs="auto">
                  Plan Exported: {fp_status_info.plan_created_boolean}
                </Col>
              }

              {type == "needs_flight_plan" && fpr_ids.length == 1 &&
                <Col xs="auto" className="ml-auto">
                  <a className="btn btn-sm btn-primary" href={Routes.generate_path_admin_job_path(id, {flight_plan_request_id:fpr_ids[0]})}>View Plan</a>
                </Col>
              }
            </Row>
            
            {type == "needs_flight_plan" && fp_heart_beats != null &&
              <Row>
                <Col xs="12" className="text-center">
                  {fp_heart_beats.map((hb) => {
                    return (
                      <React.Fragment>
                        {hb.email != hb.current_user_email && 
                          <span className="alert-danger rounded p-2 mt-2 d-block">
                            <i className="fas fa-exclamation-circle mr-1"></i> 
                            {hb.name != null ? <span>{hb.name} is currently accessing this plan</span> : <span>{hb.email} is currently accessing this plan</span>}
                          </span>
                        }
                      </React.Fragment>
                    );
                  })}
                </Col>
              </Row>
            }

          </Col>
        </React.Fragment>
      )
      
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "AdminChannels::FlightPlanStatusChannel",
        job_id: this.state.job.id
      },
      {  
        connected() {
          _this.getJob()
          _this.getHeartBeats()
        },

        received(data) {
          console.log(data)

          if (data.job != null) {
            _this.updateFlightPlanInfo(data.job)
          } 
          
          if (data.heart_beats != null) {
            _this.updateHeartBeats(data.heart_beats)
          }

          if (data.message == "job_updated" || data.message == "flight_plan_updated") {
            _this.getJob()
          }

          if (data.message == "heart_beats_updated") {
            _this.getHeartBeats()
          }
        }
      }
    )
  }

  getJob() {
    this.fp_listener.perform("get_job")
  }

  getHeartBeats() {
    this.fp_listener.perform("get_heart_beats")
  }

  updateFlightPlanInfo(job) {
    this.setState({fp_status_info: job})
  }

  updateHeartBeats(heart_beats) {
    this.setState({fp_heart_beats: heart_beats})
  }
}