import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 
import DraggableScroll from '../../DraggableScroll'
import PaginatorUI from '../../PaginatorUI'

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class CompletedJobsCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      status: "Loading",
      page: null,
      total_pages: null,
      jobs: []
    }

    this.searchInput = React.createRef()
    this.feedlotListener = this.subscribe()
  }

  render () {
    try {
      const {is_admin} = this
      const {jobs, page, total_pages} = this.state

      var job_groups = {requires_billing_action: [], done: []}
      jobs.forEach((job) => {
        if (job.skip_billing == false && job.line_item == null) {
          job_groups.requires_billing_action.push(job)
        } else {
          job_groups.done.push(job)
        }
      })

      return (
        <Card id="completed-jobs" className="bg-light">
          <Card.Header>
            <Container fluid className="p-0">
              <Row className="justify-content-between">
                <Col xs="auto" className="p-0">
                  <Card.Title>
                    Completed Jobs
                  </Card.Title>
                </Col>
                <Col xs="auto" className="p-0">
                  <div className="input-group">
                    <input ref={this.searchInput} type="text" className="form-control card-search-input" placeholder="Search" onKeyDown={(e) => {e.key === "Enter" && this.getJobs(0)}}/>
                    <div className="input-group-append"> 
                      <button className="input-group-text btn btn-primary" onClick={() => this.getJobs(0)}>
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>
            
            {/*Requires Billing Action Jobs*/}
            {job_groups.requires_billing_action.length > 0 &&
              <React.Fragment>
                <Card.Header className="bg-danger rounded-0">
                  <h5 className="ml-2 my-auto">Requires Billing Action</h5>
                </Card.Header>
                <Container className="pl-2 bg-light">
                  <Row>
                    {job_groups.requires_billing_action.map((job) => {
                      return <CompletedJobObject
                        key={job.id}
                        job={job} 
                        colorCode='danger'/>
                    })}
                  </Row>
                </Container>
              </React.Fragment>
            }
            <Container className="pl-2 bg-light">
              <Row>
                {job_groups.done.map((job) => {
                  return <CompletedJobObject 
                    key={job.id}
                    job={job}
                    colorCode='success'
                  />
                })}
              </Row>
            </Container>
            
          </Card.Body>
          {(total_pages != null && total_pages > 1) && 
            <Card.Footer className="pagination-section">
              <nav>
                <PaginatorUI
                  currentPage={page}
                  totalPages={total_pages}
                  pageSelected={(page) => this.getJobs(page)}
                  />
              </nav>
            </Card.Footer>
          }
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "AdminChannels::CompletedJobsChannel",
      },
      {  
        connected() {
          _this.getJobs(0)
        },

        received(data) {
          if (data.jobs != null) {
            _this.setState({
              status: "Ok",
              page: data.page,
              total_pages: data.total_pages,
              jobs: data.jobs
            })
          }
        }
      }
    )
  }

  getJobs(page) {
    page = page || 0
    let search = this.searchInput?.current?.value || null
    this.feedlotListener.perform("get_jobs", {
      page: page,
      search: search
    })
  }
}

class CompletedJobObject extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      job: props.job,
      colorCode: props.colorCode,
    }
  }

  // when pen is updated, update the state
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.job != this.props.job) {
      this.setState({
        job: nextProps.job
      })
      return true
    }
    
    return false
  }

  render () {
    try {
      const { job, colorCode } = this.state
      if(job == null) {
        return null
      }

      const {
        id,
        name,
        feedlot_id,
        feedlot_name,
        finished_at,
        
        pen_count,
        review_count,
        refly_count,
        in_alley_count,
        gate_pen_count,

        hc_count,
        yard_count,
        difference,

        skip_billing,
        line_item, // rate, count, price // can be null
      } = job

      const job_path = Routes.admin_job_path(job.id)
      const feedlot_path = Routes.admin_feedlot_path(feedlot_id)

      return (
        <React.Fragment>
          <Col xs="12" className={`job-card shadow-sm border-${colorCode}`}>
            <Row>
              <Col xs="6">
                <a className="text-primary font-weight-bold" href={Routes.admin_job_path(id)}>{name}</a> - <a className="text-secondary" href={Routes.admin_feedlot_path(feedlot_id)}>{feedlot_name}</a>  
              </Col>

              <Col xs="6" className="text-right">
                Finished: {finished_at}
              </Col>
            </Row> 
            <hr className="m-1"/>
            <Row>
              <Col xs="4" className="text-left">
                <div>
                  Yard Count: {numberWithCommas(yard_count)}
                </div>
                <hr className="m-1" />
                <div>
                  HC Count: {numberWithCommas(hc_count)}
                </div>
                <hr className="m-1" />
                <div>
                  Difference: {numberWithCommas(difference)}
                </div>
              </Col>

              <Col xs="4" className="text-center">
                <div>
                  Pens: {numberWithCommas(pen_count)}
                </div>
                <hr className="m-1" />
                {review_count > 0 &&
                  <div>
                    Needs Review: {numberWithCommas(review_count)}
                    <hr className="m-1" />
                  </div>
                }
                {refly_count > 0 &&
                  <div>
                    Needs Reflown: {numberWithCommas(refly_count)}
                    <hr className="m-1" />
                  </div>
                }
                {in_alley_count > 0 &&
                  <div>
                    Cattle In Alley: {numberWithCommas(in_alley_count)}
                    <hr className="m-1" />
                  </div>
                }
                {gate_pen_count > 0 &&
                  <div>
                    Gates Open: {numberWithCommas(gate_pen_count)}
                  </div>
                }
              </Col>
              <Col xs="4" className="text-right">
                {skip_billing == true && 
                  <div>
                    Skipped Billing
                    <hr className="m-1" />
                  </div>
                }
                {line_item != null && 
                  <React.Fragment>
                    <div>
                      Rate: ${numberWithCommas(line_item.rate)}
                    </div>
                    <hr className="m-1" />
                    <div>
                      Count: {numberWithCommas(line_item.count)}
                    </div>
                    <hr className="m-1" />
                    <div>
                      Total: ${numberWithCommas(line_item.price)}
                    </div>
                  </React.Fragment>
                }
              </Col>
            </Row> 
          </Col>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}