import React from "react"

export default class CustomSmoothEntry extends React.Component {

  constructor(props) {
    super(props)

    this.childrenRefs = []
  }

  componentDidMount() {
    var elements = compact(this.childrenRefs.map((ref) => ref.current))
    for(var element of elements) {
      var width = $(element).width()
      var opacity = element.style.opacity || "1"
      anime({
        targets: element,
        scale: [0,1],
        opacity: ["0", opacity],
        duration: 1000,
        easing: 'easeInOutQuad',
        transformOrigin: '0px 0px'
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.removing == false && this.props.removing == true) {
      var elements = compact(this.childrenRefs.map((ref) => ref.current))
      for( var element of elements) {
        anime({
          targets: element,
          scale: 0,
          opacity: "0",
          duration: 1000,
          easing: 'easeInOutQuad',
          transformOrigin: '0px 0px'
        });
      }
    }
  }

  componentWillUnmount() {}

  render () {
    return (
      <React.Fragment>
        {React.Children.map(this.props.children, (element, idx) => {
          var ref = React.createRef()
          this.childrenRefs.push(ref)
          return React.cloneElement(element, { ref: ref });
        })}
      </React.Fragment>
    )
  }
}