/*
  Needed actions 

  1. Create Subscription
    - Set Start/End Date
    - Set Count Limit
    - Select Feedlots

  2. Edit Subscription
    - Start/End Date
    - Cancel Date
    - Count Limit
    - Remove/Add Feedlots
    - Adjust Feedlot Start/End Dates
  
  3. Renew Subscription
    - Adjust Start/End Date
    - Adjust Count Limit
*/


import React from "react"
import {Card, Container, Row, Col, Button} from 'react-bootstrap' 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select"

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class EditSubscriptionView extends React.Component {

  constructor(props) {
    super(props)

    this.is_mobile = props.is_mobile || false,
    this.subscription_id = props.subscription?.id    

    // convert all date strings to date objects from subscription
    props.subscription.start_date = stringToDate(props.subscription.start_date)?.toUTC()
    props.subscription.end_date = stringToDate(props.subscription.end_date)?.toUTC()
    props.subscription.cancellation_date = stringToDate(props.subscription.cancellation_date)?.toUTC()
    props.subscription.feedlot_subs.forEach((fsub) => {
      fsub.date_added = stringToDate(fsub.date_added)?.toUTC()
      fsub.date_removed = stringToDate(fsub.date_removed)?.toUTC()
    })
    
    this.state = {
      subscription: props.subscription,
      errors: [],
      currentDate: new Date(),
      next_new_fs_id: -1,
      new_feedlot_ids: [],

      response_errors: [],
      response_sub_errors: {},
      id_to_temp_map: {},

      submitting: false,
    }

    // only id,name, archived
    this.all_feedlots = props.all_feedlots.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
     

    this.feedlotSelect = React.createRef()
  }

  render () {
    try {

      const { subscription } = this.state

      const { 
        id,
        group_id,
        start_date,
        end_date,
        cancellation_date,
        count_limit,
        feedlot_subs,
      } = subscription

      const title = this.subscription_id == undefined ? "Create Subscription" : "Edit Subscription"

      const validationErrors = this.validateSubscription()
      const response_sub_errors = this.state.response_sub_errors

      var grouped_feedlot_subs = Object.entries(group(feedlot_subs, "feedlot_id"))
        .sort(([feedlot_id,f_subs], [feedlot_id2, f_subs2]) => {
          var feedlot1 = this.all_feedlots.find((f) => f.id == feedlot_id)
          var feedlot2 = this.all_feedlots.find((f) => f.id == feedlot_id2)
          return feedlot1.name.localeCompare(feedlot2.name)
        })

      // place new feedlots at the end of the list
      for (var feedlot_id of this.state.new_feedlot_ids) {
        for (var i in grouped_feedlot_subs) {
          var group_feedlot_id = grouped_feedlot_subs[i][0]
          if (group_feedlot_id == feedlot_id) {
            var feedlot_group = grouped_feedlot_subs.splice(i, 1)
            grouped_feedlot_subs.push(feedlot_group[0])
            break
          }
        }
      }

      const dateFormat = "yyyy/MM/dd"
      const currentDate = this.state.currentDate

      const existing_feedlots = unique(feedlot_subs.map((fsub) => { 
        return this.all_feedlots.find((f) => f.id == fsub.feedlot_id)
      }))

      const available_feedlots = this.all_feedlots.filter((f) => {
        return !existing_feedlots.includes(f) && !f.archived
      })

      var feedlot_select_options = available_feedlots.map((feedlot,i) => {
        return {
          value: feedlot.id, 
          label: feedlot.name
        }}
      )

      console.log(feedlot_select_options)

      return (
        <Card id="active-counters">
          <Card.Header>
            <Container fluid>
              <Row>
                <Col>
                  <h3>{title}</h3>
                </Col>
                {this.subscription_id != undefined && 
                  <Col xs="auto">
                    <a className="btn btn-primary float-right" href={Routes.admin_subscription_path(this.subscription_id)}>
                      Back
                    </a>    
                  </Col>
                }
              </Row>
            </Container>
          </Card.Header>
          <Card.Body className="py-3" style={{overflowX: "visible"}}>
            <Container fluid>
              <Row>
                <Col xs="auto">
                  <h4>Subscription Details</h4>
                </Col>
                <Col>
                  { this.renderErrors(this.state.response_errors) }
                  { this.renderErrors(this.state.errors) }
                </Col>
              </Row>

              <Row>
                <Col xs="auto">
                  {this.renderErrors(response_sub_errors["overlapping"])}
                  {this.renderOverlappingSubs(response_sub_errors["overlapping_ids"]?.[0])}
                </Col>
              </Row>
              

              {/* Start Date, End Date, Cancellation Date, Count Limit */}
              <Row>
                <Col xs="auto">
                  {this.renderErrors(response_sub_errors["start_date"], "Response: ")}
                  {this.renderErrors(validationErrors["start_date"], "PreValidation: ")}
                  
                  <label className="my-0">Start Date</label>
                  <div>
                    <DatePicker 
                      selected={start_date} 
                      onChange={ (date) => {
                        var sub = this.state.subscription;
                        sub.start_date = date;
                        this.setState({subscription: sub})
                      }}
                      dateFormat={dateFormat}
                      selectsStart
                      startDate={start_date}
                      endDate={end_date}
                      openToDate={start_date || currentDate}
                      isClearable
                      popperPlacement="right"
                    />
                  </div>
                </Col>
                <Col xs="auto">
                  {this.renderErrors(response_sub_errors["end_date"], "Response: ")}
                  {this.renderErrors(validationErrors["end_date"], "PreValidation: ")}
                  <label className="my-0">End Date</label>
                  <div>
                    <DatePicker 
                      selected={end_date} 
                      onChange={ (date) => {
                        var sub = this.state.subscription;
                        sub.end_date = date;
                        this.setState({subscription: sub})
                      }}
                      timeInputLabel="Time:"
                      dateFormat={dateFormat}
                      selectsEnd
                      startDate={start_date}
                      endDate={end_date}
                      openToDate={end_date || currentDate}
                      isClearable
                      popperPlacement="right"
                    />
                  </div>
                </Col>
                <Col xs="auto">
                  {this.renderErrors(response_sub_errors["cancellation_date"], "Response: ")}
                  {this.renderErrors(validationErrors["cancellation_date"], "PreValidation: ")}
                  <label className="my-0">Cancellation Date</label>
                  <div>
                    <DatePicker 
                      selected={cancellation_date} 
                      onChange={ (date) => {
                        var sub = this.state.subscription;
                        sub.cancellation_date = date;
                        this.setState({subscription: sub})
                      }}
                      timeInputLabel="Time:"
                      dateFormat={dateFormat}
                      selectsEnd
                      startDate={start_date}
                      endDate={end_date}
                      openToDate={cancellation_date || currentDate}
                      isClearable
                      popperPlacement="right"
                    />
                  </div>
                </Col>
                <Col xs="auto">
                  {this.renderErrors(response_sub_errors["count_limit"], "Response: ")}
                  {this.renderErrors(validationErrors["count_limit"], "PreValidation: ")}
                  <label className="my-0">Count Limit</label>
                  <input
                    type="number"
                    className="form-control"
                    value={count_limit}
                    onChange={(e) => {
                      var sub = this.state.subscription;
                      sub.count_limit = e.target.value;
                      this.setState({subscription: sub})
                    }}
                  />
                </Col>
              </Row>


              {/* Feedlot Subs */}
              <Row className="gy-2 py-2">
                <Col>
                  <h4>Feedlot Subs</h4>
                </Col>
              </Row>
              <Row className="gy-2 py-2">
                <Col>
                  {grouped_feedlot_subs.map(([feedlot_id, f_subs]) => {
                    const feedlot = this.all_feedlots.find((f) => f.id == feedlot_id)
                    const feedlot_name = feedlot.name
                    const sorted_f_subs = f_subs.sort((a,b) => {
                      if (a.id < 0 && b.id < 0) {
                        return a.id > b.id ? -1 : 1
                      }
                      if (a.id < 0 && b.id >= 0) {
                        return 1
                      }
                      if (a.id >= 0 && b.id < 0) {
                        return -1
                      }
                      return (a.date_added || minDate()) < (b.date_added || maxDate()) ? -1 : 1
                    })
                    
                    return (
                      <React.Fragment key={feedlot_id}>
                        <Row>
                          <Col>
                            <h5>{feedlot_name}</h5>
                          </Col>
                        </Row> 
                        {sorted_f_subs.map((f_sub) => {
                          const {
                            id: fsub_id,
                            date_added,
                            date_removed
                          } = f_sub
                          
                          var previous_f_sub = sorted_f_subs[sorted_f_subs.indexOf(f_sub)-1]
                          var next_f_sub = sorted_f_subs[sorted_f_subs.indexOf(f_sub)+1]

                          var available_start_date = new Date(Math.max(previous_f_sub?.date_removed || minDate(), start_date))
                          var available_end_date = new Date(Math.min(next_f_sub?.date_added || maxDate(), cancellation_date || end_date))
                          
                          // does current date lie between available start and end dates
                          var isCurrentDateInside = currentDate >= available_start_date && currentDate <= available_end_date
                          var defaultAddDate = isCurrentDateInside ? currentDate : available_start_date
                          var defaultRemoveDate = isCurrentDateInside ? currentDate : available_end_date

                          var response_error_key = "feedlot_sub_"+fsub_id+"_"
                          
                          return (
                            <React.Fragment key={fsub_id}>
                              {/* Date Added, Date Removed, Delete Feedlot Sub*/}

                              <Row>
                                <Col xs="auto">
                                  {this.renderErrors(response_sub_errors[response_error_key+"overlapping"], "Response: ")}
                                  {this.renderOverlappingFeedlotSubs(response_sub_errors[response_error_key+"overlapping_ids"]?.[0]?.[0])}
                                  {this.renderErrors(validationErrors?.["fsubs"]?.[fsub_id]?.["overlapping"], "PreValidation: ")}
                                </Col>
                              </Row>
                              <Row className="gy-2 py-2 align-items-end">
                                {/* Print Id */}
                                {fsub_id < 0 &&
                                  <Col xs="auto" className="align-self-center">
                                    <b className="text-success">New</b>
                                  </Col>  
                                }
                                
                                <Col xs="auto">
                                  {this.renderErrors(response_sub_errors[response_error_key+"date_added"], "Response: ")}
                                  {this.renderErrors(validationErrors?.["fsubs"]?.[fsub_id]?.["date_added"], "PreValidation: ")}
                                  <label className="my-0">Date Added</label>
                                  <div>
                                    <DatePicker 
                                      selected={date_added} 
                                      onChange={ (date) => {
                                        var sub = this.state.subscription;
                                        for (var i = 0; i < sub.feedlot_subs.length; i++) {
                                          if (sub.feedlot_subs[i].id == fsub_id) {
                                            sub.feedlot_subs[i].date_added = date;
                                          }
                                        }
                                        this.setState({subscription: sub})
                                      }}
                                      timeInputLabel="Time:"
                                      dateFormat={dateFormat}
                                      startDate={available_start_date}
                                      endDate={available_end_date}
                                      openToDate={date_added || defaultAddDate}
                                      isClearable={true}
                                      popperPlacement="right"
                                    />
                                  </div>
                                </Col>
                                <Col xs="auto">
                                  {this.renderErrors(response_sub_errors[response_error_key+"date_removed"], "Response: ")}
                                  {this.renderErrors(validationErrors?.["fsubs"]?.[fsub_id]?.["date_removed"], "PreValidation: ")}
                                  <label className="my-0">Date Removed</label>
                                  <div>
                                    <DatePicker 
                                      selected={date_removed} 
                                      onChange={ (date) => {
                                        var sub = this.state.subscription;
                                        for (var i = 0; i < sub.feedlot_subs.length; i++) {
                                          if (sub.feedlot_subs[i].id == fsub_id) {
                                            sub.feedlot_subs[i].date_removed = date;
                                          }
                                        }
                                        this.setState({subscription: sub})
                                      }}
                                      timeInputLabel="Time:"
                                      dateFormat={dateFormat}
                                      startDate={date_added || available_start_date}
                                      endDate={available_end_date}
                                      openToDate={date_removed || defaultRemoveDate}
                                      isClearable={true}
                                      popperPlacement="right"
                                    />
                                  </div>
                                </Col>
                                <Col xs="auto">
                                    {/* Remove Feedlot Sub */}
                                    <Button
                                      variant="danger"
                                      onClick={() => {
                                        var sub = this.state.subscription;
                                        for (var i = 0; i < sub.feedlot_subs.length; i++) {
                                          if (sub.feedlot_subs[i].id == fsub_id) {
                                            sub.feedlot_subs.splice(i, 1);
                                          }
                                        }
                                        this.setState({subscription: sub})
                                      }}
                                    >
                                      <i className="fas fa-trash"/>
                                    </Button>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                        })}
                        
                        {/* Add Feedlot Sub */}
                        <Row className="gy-2 py-2">
                          <Col>
                            {/* Add Feedlot Sub */}
                            <Button
                              variant="success"
                              onClick={() => {
                                var last_sub = sorted_f_subs[sorted_f_subs.length-1]
                                var available_start_date = new Date(Math.max(last_sub?.date_removed || minDate(), start_date))
                                var available_end_date = cancellation_date || end_date
                                var isCurrentDateInside = currentDate >= available_start_date && currentDate <= available_end_date
                                var defaultAddDate = isCurrentDateInside ? currentDate : available_start_date
                                var sub = this.state.subscription;
                                sub.feedlot_subs.push({
                                  id: this.state.next_new_fs_id,
                                  feedlot_id: feedlot_id,
                                  date_added: null,
                                  date_removed: null
                                });
                                this.setState({
                                  subscription: sub,
                                  next_new_fs_id: this.state.next_new_fs_id - 1
                                })
                              }}
                            >
                              <i className="fas fa-plus"/>
                            </Button>
                            <hr/>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                  })}
                </Col>
              </Row>

              {/* Add Feedlot */}
              <Row>
                <Col>
                  <div>
                    <label className="my-0 mr-2">Add Feedlot</label>
                    <div style={{maxWidth: "fit-content", minWidth: "250px", display: "inline-block", verticalAlign: "middle"}}>
                      <Select ref={this.feedlotSelect} 
                        id="subscription_feedlot_select"
                        defaultValue={null}
                        options={feedlot_select_options}
                        menuPlacement="top"
                        /*styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            display: 'inline-block',
                          }),
                        }}*/
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                          ...theme.colors,
                            primary25: 'salmon',
                            primary: 'darkred'
                          },
                        })}
                      />
                    </div>
                    <Button
                      variant="success"
                      onClick={() => {
                        var value = this.feedlotSelect.current.getValue()
                        if (value == null || value.length == 0) {
                          return
                        }
                        var feedlot_id = value[0].value;
                        
                        if (isStringEmpty(feedlot_id) == false) {
                          var sub = this.state.subscription;
                          sub.feedlot_subs.push({
                            id: this.state.next_new_fs_id,
                            feedlot_id: feedlot_id,
                            date_added: null,
                            date_removed: null
                          });
                          var new_feedlot_ids = this.state.new_feedlot_ids;
                          new_feedlot_ids.push(feedlot_id);
                          
                          this.setState({
                            subscription: sub,
                            next_new_fs_id: this.state.next_new_fs_id - 1,
                            new_feedlot_ids: new_feedlot_ids
                          })
                          this.feedlotSelect.current.clearValue()
                        }
                      }}
                    >
                      <i className="fas fa-plus">
                        Add Feedlot
                      </i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer>
            {this.renderErrors(this.state.response_errors, "Response: ")}
            {this.renderErrors(this.state.errors, "PreValidation: ")}
            <Button
              variant="success"
              onClick={() => {
                this.submit()
              }}
              disabled={this.state.submitting}
            >
              Save
            </Button>
          </Card.Footer>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return (
        <h3 className="text-danger">Error Loading Page. Contact Developer</h3>
      )
    }
  }

  validateSubscription() {
    const sub = this.state.subscription
    const {
      id,
      group_id,
      start_date,
      end_date,
      cancellation_date,
      count_limit,
      feedlot_subs,
    } = sub

    var results = {}

    if (start_date == undefined) {
      results["start_date"] = "Start date is required"
    }

    if (end_date == undefined) {
      results["end_date"] = "End date is required"
    }

    if (start_date != undefined && end_date != undefined) {
      if (start_date > end_date) {
        results["start_date"] = "Start date must be before end date"
      } else if (cancellation_date != undefined && (cancellation_date < start_date || cancellation_date > end_date)) {
        results["cancellation_date"] = "Cancellation date must be between start and end date"
      }
    }

    if (count_limit == undefined) {      
      results["count_limit"] = "Count limit is required"
    }

    if (count_limit != undefined && count_limit <= 0) {
      results["count_limit"] = "Count limit must be greater than 0"
    }

    var fsubs_results = {}

    // check for valid date ranges
    for(const fsub of feedlot_subs) {
      const {
        id: fsub_id,
        feedlot_id,
        date_added,
        date_removed,
      } = fsub

      var fsub_results = {}      

      if(date_added != undefined && (date_added < start_date || date_added > end_date)) {
        console.log("date_added: ", date_added)
        console.log("start_date: ", start_date)
        fsub_results["date_added"] = "Date added must be between start and end date"
      }

      if (date_removed != undefined && (date_removed < start_date || date_removed > end_date)) {
        fsub_results["date_removed"] = "Date removed must be between start and end date"
      }
      
      if (date_added != undefined && date_removed != undefined) {
        if (date_added > date_removed) {
          fsub_results["date_added"] = "Date added must be before date removed"
        }
      }

      if (Object.keys(fsub_results).length > 0) {
        fsubs_results[fsub_id] = fsub_results
      }
    }

    if (Object.keys(fsubs_results).length > 0) {
      results["fsubs"] = fsubs_results
    }

    // check for overlapping fsub dates
    for(const fsub of feedlot_subs) {
      const {
        id: fsub_id,
        feedlot_id,
        date_added,
        date_removed,
      } = fsub

      for(const fsub2 of feedlot_subs) {
        const {
          id: fsub_id2,
          feedlot_id: feedlot_id2,
          date_added: date_added2,
          date_removed: date_removed2,
        } = fsub2

        if (fsub_id != fsub_id2 && feedlot_id == feedlot_id2) {
          if (dateRangeOverlaps(date_added, date_removed, date_added2, date_removed2)) {
            if (results["fsubs"] == undefined) { 
              results["fsubs"] = {}
            }
            if (results["fsubs"][fsub_id] == undefined) {
              results["fsubs"][fsub_id] = {}
            }
            results["fsubs"][fsub_id]["overlapping"] = "Feedlot Subscription Date Range is overlapping with another"
          }
        }
      }
    }
    return results
  }

  async submit() {
    if (this.state.submitting) {
      return
    }

    await this.setState({submitting: true, errors: [], response_errors: [], response_sub_errors: {}})

    var validationErrors = this.validateSubscription()
    if (Object.keys(validationErrors).length != 0) {
      this.setState({errors: ["Please Fix Validation Errors Before Saving"]})
      return
    }
    var subscription = this.state.subscription
    const path = this.subscription_id == null ? Routes.admin_subscriptions_path() : Routes.admin_subscription_path(this.subscription_id)
    const method = this.subscription_id == null ? "POST" : "PATCH"
    var _this = this
    $.ajax({
      url: path,
      method: method,
      data: {subscription: subscription},
      dataType: 'json',
      success: (data) => {
        if (data.status == "success") {
          window.location = Routes.admin_subscription_path(data.subscription_id)
        } else if (data.errors) {
          console.log(data.errors)
          console.log(data.sub_errors)
          console.log(data.id_to_temp_map)
          _this.setState({
            response_errors: data.errors || ["Request Failed"],
            response_sub_errors: data.sub_errors || {},
            id_to_temp_map: data.id_to_temp_map || {}
          })
        }
      },
      error: function(request, textStatus, errorThrown) {
        _this.setState({response_errors: ["Request Failed"]})
      },
      complete: function() {
        _this.setState({submitting: false})
        //window.location.reload()
      }
    })
  }

  renderErrors(errors, prefix = "") {
    if (errors == undefined) {
      return null
    }

    // check if errors is an array or a single string
    else if (typeof errors === 'string' || errors instanceof String) {
      return (
        <p className="text-danger my-0">{prefix}{errors}</p>
      )
    }

    else {
      if (errors.length == 0) {
        return null
      }

      return errors.map((error, i) => {
        return (
          <p key={i} className="text-danger my-0">{prefix}{error}</p>
        )
      })
    }
  }

  renderOverlappingFeedlotSubs(overlapping_ids) {
    if (overlapping_ids == undefined) {
      return null
    }
    var id_to_temp_map = this.state.id_to_temp_map


    return Object.entries(overlapping_ids).map(([sub_id, fsub_ids]) => {
      var sub_path = sub_id == this.subscription_id ? null : Routes.admin_subscription_path(sub_id)
      var fsub_elements = fsub_ids.map((fsub_id) => {
        var temp_id = id_to_temp_map[fsub_id]
        if (temp_id == undefined) {
          return <span className="text-success">{fsub_id}</span>
        } else {
          return <span className="text-warning">New: {temp_id*-1}</span>
        }
      }).map((element, index) => {
        return (
          <React.Fragment>
            {element}
            {index < (fsub_ids.length-1) && ", "}
          </React.Fragment>
        )
      })
      
      return (
        <p className="text-danger my-0">
          {"Feedlot Sub Overlaps with "}
          {sub_path == null && 
            <span>{sub_id}</span>
          }
          {sub_path != null && 
            <a href={sub_path} target="_blank" style={{textDecoration: "underline"}}>{sub_id}</a>
          }
          {": "}
          [{fsub_elements}]
        </p>
      )
    })
  }

  renderOverlappingSubs(overlapping_ids) {
    if (overlapping_ids == undefined) {
      return null
    }

    return (
      <p className="text-danger my-0">
        {"Subscription Overlaps with: "}
        {overlapping_ids.map((sub_id, index) => {
          var sub_path = Routes.admin_subscription_path(sub_id)
          return (
            <React.Fragment>
              <a href={sub_path} target="_blank" style={{textDecoration: "underline"}}>{sub_id}</a>
              {index < (overlapping_ids.length-1) && ", "}
            </React.Fragment>
          )
        })}
      </p>
    )
  }
}