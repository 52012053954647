import React from "react"
import ExifReader from 'exifreader';

export default class ImageDataView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      meta_data: props?.meta_data,
    }

    this.localMessageSubscriber = this.subscribeToLocalMessages()
  }

  subscribeToLocalMessages() {
    var _this = this
    return postal.subscribe({
      channel: "ImageDataView",
      topic: "meta_data",
      callback: function(data, envelope) {
        _this.setState({meta_data: data.meta_data})
      }
    });
  }

  render () {
    let keys_and_titles = [["gps", "GPS Data"], ["xmp", "XMP Data"], ["exif", "Exif Data"], ["file", "File Data"], ["jfif", "JFIF Data"], ["mpf", "MPF Data"]]

    try {
      return <React.Fragment>
        { this.state.meta_data != null && keys_and_titles.map(key_and_title => {
          try {
            let key = key_and_title[0]
            let title = key_and_title[1]
            return <table>
              <thead><tr><th>{title}</th></tr></thead>
              <tbody>
                {Object.keys(this.state.meta_data[key]).map(sub_key => {
                  try {
                    let item = this.state.meta_data[key][sub_key]
                    var value = key == "gps" ? item : item.description
                    return <tr>
                      <td className="px-4 py-1">{sub_key}:</td>
                      <td className="py-1">{value}</td>
                    </tr>
                  } catch (error) {
                    console.log(error)
                    return <tr><td>Failed To Parse</td></tr>
                  }
                })}
              </tbody>
            </table>  
          } catch(error) {
            console.log(error)
            return <div></div>
          }
        })}
      </React.Fragment>
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}