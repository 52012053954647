import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import fuzzysearch from 'fuzzysearch'
import React from 'react';

/*
  Modal To create Job and Flight Plan
*/
class CreateJobAndPlan extends React.Component {

  constructor(props) {
    super(props);

    this.feedlot_id = props.feedlot_id
    this.feedlot_name = props.feedlot_name
    this.ref_pens = props.ref_pens
    sortRefPensByName(this.ref_pens)

    this.drawMarkers = true
    this.markerCluster = null

    this.polyColor = "#FF0000" // polygon bases color , red
    this.labelColor = "#FF0000" // label base color, ref
    this.selectedPolyColor = "#00FF00" // polygon selected color, green
    this.selectedLabelColor = "#19632c" // selected label color, dark green 

    this.primaryColor = "#E21818"

    this.chosenPolyColor = "#19632c"
    this.chosenLabelColor = "#19632c"

    this.nameInput = React.createRef()
    this.searchInput = React.createRef()
    this.ignoreInput = React.createRef()
    this.yardCountInputs = []

    this.state = {
      page: 1,
      job_name: null,
      searched_ref_pens: [],
      selected_ref_pens: [],
      highlighted_ref_pen: null,
      ignore_job: false,
      is_submitting: false
    }
  }

  render () {
    try {
      const page = this.state.page
      const titles = ["Enter Name", "Select Pens", "Enter Yard Counts", "Review"]
      const title = titles[this.state.page-1]
      const total_yard_count = this.state.selected_ref_pens.reduce((total,pen)=> total + (pen.yard_count || 0),0)

      return (
        <Container fluid>
          
          {/* Display which page */}
          <Row className="step-row">
            <Col>
              <Row>
                <Col className="text-center">
                  <h4>
                    Order Job for {this.feedlot_name}
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col className="text-center" sm={12} md="auto" style={{color: page >= 1 ? this.primaryColor : "white"}}>
                  <b>Enter Name</b>
                </Col>
                <Col className="text-center d-none d-md-block">
                  {"->"}
                </Col>
                <Col className="text-center" sm={12} md="auto" style={{color: page >= 2 ? this.primaryColor : "white"}}>
                  <b>Select Pens</b>
                </Col>
                <Col className="text-center d-none d-md-block">
                  {"->"}
                </Col>
                <Col className="text-center" sm={12} md="auto" style={{color: page >= 3 ? this.primaryColor : "white"}}>
                  <b>Enter Yard Counts</b>
                </Col>
                <Col className="text-center d-none d-md-block">
                  {"->"}
                </Col>
                <Col className="text-center" sm={12} md="auto" style={{color: page >= 4 ? this.primaryColor : "white"}}>
                  <b>Review</b>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Actions to move between pages */}
          <Row className="page-row">
            <Col className="p-0">
              <Row>
                <Col>
                  {page > 1 &&
                    <button 
                      className="btn btn-dark"
                      onClick={()=>this.previousPage()}>
                        Previous
                    </button>
                  }
                </Col>
                <Col>
                  <h3>
                    {title}
                  </h3>
                </Col>
                <Col className="text-right">
                  {page != 4 &&
                    <button 
                    className="btn btn-dark"
                      onClick={()=>this.nextPage()}>
                        Next
                    </button>
                  } 
                  {page == 4 &&
                    <button
                    className="btn btn-dark"
                    disabled={this.state.is_submitting}
                    onClick={()=>this.submit()}>
                      Submit
                    </button>
                  }
                </Col>
              </Row>
              <Row>
                <hr/>
              </Row>


              {/* Page 1: Get Name */}
              {this.state.page == 1 &&
                <Row className="justify-content-center" style={{marginBottom: "2rem"}}>
                  <Col className="text-center" sm={12} md={6}>
                    <label>Job Name (required)</label>
                    <br/>
                    <input ref={this.nameInput} type="text" name="job_name" defaultValue={this.state.job_name} style={{width: "100%"}}/>
                    <br/>
                    {this.props.system_admin && 
                      <React.Fragment>
                        <label>Set as Ignored: </label>
                        <input ref={this.ignoreInput} type="checkbox" defaultChecked={this.state.ignore_job} onChange={()=>this.ignoreChanged()}/>
                        <br/>
                        <p>
                          Ignoring a job will 
                            not send an email, 
                            pens will not end up in the counting portal,
                            job will not show up in unfinished job view in the admin page.
                          This can be changed later if needed
                        </p>
                      </React.Fragment>
                    }
                  </Col>
                </Row>
              }

              {/* Page 2: Select Pens */}
              {this.state.page == 2 && 
                <React.Fragment>
                  {/* Show Selected PensList */}
                  <Row>
                    <Col>
                      <h6>
                        Selected Pens
                      </h6>
                      <label>
                        Amount Selected: {this.state.selected_ref_pens.length}
                      </label>
                    </Col>
                    <Col className="text-right">
                      <label>Double click an item on the list or polygon to add it to the job</label>
                    </Col>
                  </Row>

                  <Row>
                    <div className="ul list-group list-group-horizontal" style={{listStyleType: "none", display: "flex", flexWrap: "wrap"}}>
                      {this.state.selected_ref_pens.map((ref_pen, index) => {
                        return (
                          <li className="pen-badge" key={ref_pen.id}>
                            <button className="list-group-item" 
                              onClick={()=>this.singleClick(ref_pen)}
                              style={{background: "none", border: "none", display: "inline", padding: "2px 5px", boxShadow: "none", outline: "0px"}}>
                              {ref_pen.name}
                            </button>
                            <div className="close-icon btn-icon fas fa-times"
                              onClick={()=>this.removeRefPen(ref_pen)}>
                            </div>
                          </li>
                        )
                      })}
                    </div>
                  </Row>

                  <Row>
                    {/* Show Selectable Ref Pens */}
                    <Col md="3" sm="12" style={{height: "55vh", display: "flex", flexDirection: "column"}}>
                      <div style={{position: "relative"}}>
                        <input
                          ref={this.searchInput} 
                          type="text" name="search" 
                          defaultValue="" 
                          style={{width: "100%"}} 
                          onChange={()=>this.search()}
                          placeholder="Search"/>
                        <button 
                          className="btn-icon fas fa-times text-danger h-100" 
                          style={{position: "absolute", right: "0", top: "0"}}
                          onClick={()=>{this.searchInput.current.value = ""; this.search()}}/>
                      </div>
                      
                        
                      <div className="list-group" style={{overflowY: "auto", borderRadius: "0rem", backgroundColor: "white"}}>
                        {this.state.searched_ref_pens.map((ref_pen, index) => {
                          return (
                            <React.Fragment key={ref_pen.id}>
                              {(ref_pen.name != null && ref_pen.name != "") &&
                                <button className="list-group-item p-3 p-md-1" 
                                  onClick={()=>this.singleClick(ref_pen)} 
                                  onDoubleClick={()=>this.doubleClick(ref_pen)} >
                                  {ref_pen.name}
                                </button>
                              }
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </Col>

                    {/* Map */}
                    <Col md="9" sm="12" style={{height: "55vh"}}>
                      <div id="map" style={{width:"100%", height: "100%"}}/>
                    </Col>
                  </Row>
                </React.Fragment>
              }
              
              {/* Page 3: Add Yard Counts*/}
              {this.state.page == 3 && 
                <React.Fragment>
                  {this.yardCountInputs = []}
                  <Row>
                    <Col>
                      <h6 style={{display: "inline-block", marginRight: "0.5rem"}}>
                        Total Yard Count: 
                      </h6>
                      <div style={{display: "inline-block"}}>
                        {total_yard_count}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <table className="table table-bordered yard-count-table">
                        <thead>
                          <tr>
                            <th>
                              Pen
                            </th>
                            <th>
                              Yard Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.selected_ref_pens.map((ref_pen, index) => {
                          return (
                            <tr key={ref_pen.id}>
                              <td>
                                {ref_pen.name}
                              </td>
                              <td>
                                <input ref={yardCountInputs => this.yardCountInputs[index] = yardCountInputs} type="number" defaultValue={ref_pen.yard_count} onChange={(event)=>this.editYardCount(ref_pen,event)} onKeyDown={(event)=>this.handleEnter(index, event)}/>
                              </td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>

                    </Col>
                  </Row>
                </React.Fragment>
              }

              {/* Page 4: Review*/}
              {this.state.page == 4 && 
                <React.Fragment>
                  <Row>
                    <Col>
                      <div className="section">
                        1. Job Name
                      </div>
                      <h5>{this.state.job_name}</h5>
                      {this.props.system_admin && this.state.ignore_job && 
                        <h6>Job Ignored</h6>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="section">
                        2. Pens
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6 style={{display: "inline-block", marginRight: "0.5rem"}}>
                        Total Yard Count: 
                      </h6>
                      <div style={{display: "inline-block"}}>
                        {total_yard_count}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <table className="table table-bordered yard-count-table">
                        <thead>
                          <tr>
                            <th>
                              Pen
                            </th>
                            <th>
                              Yard Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.selected_ref_pens.map((ref_pen, index) => {
                          return (
                            <tr key={ref_pen.id}>
                              <td>
                                <div onClick={() => this.singleClick(ref_pen)} style={{textDecoration: "underline", cursor: "pointer"}}>
                                  {ref_pen.name}
                                </div>
                              </td>
                              <td>
                                {ref_pen.yard_count}
                              </td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </Col>
                    <Col sm={12} md={6}>
                      <div id="map" style={{width:"100%", height: "60vh"}}/>
                    </Col>
                  </Row>
                </React.Fragment>
              }

            </Col>
          </Row>
        </Container>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error);
      return null
    }
  }

  componentDidMount () {
  }

  // shift to the next yard count input
  handleEnter(input_index, event) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      event.preventDefault();
      input_index += 1
      if(input_index >= this.yardCountInputs.length) {
        input_index = 0
      }
      this.yardCountInputs[input_index].focus()
      this.yardCountInputs[input_index].scrollIntoView({block: "center"})
       
    }
  }

  generatePolygons(enableSingleClick = true, enableDoubleClick = true, useMarkers = true) {

    if(this.markerCluster) {
      this.markerCluster.clearMarkers()
      this.markerCluster.setMap(null);
      this.markerCluster = null
    }

    for(var ref_pen of this.ref_pens) {

      if(ref_pen.poly) {
        ref_pen.poly.setMap(null)
        ref_pen.poly = null
      }

      if(ref_pen.marker) {
        ref_pen.marker.setMap(null)
        ref_pen.marker = null
      }

      var geometry = ref_pen.geometry
      var coordinates = null
      if (geometry.type == "MultiPolygon") { // treat as single polygon
        if (geometry.coordinates.length > 0) {
          if (geometry.coordinates[0].length > 0) {
            coordinates = geometry.coordinates[0][0]
          }
        }
      } else if(geometry?.type == "Polygon") {
        if (geometry.coordinates.length > 0) {
          coordinates = geometry.coordinates[0]
        }
      }
      
      var points = coordinates.map(function(point) { 
        n = ((n || point[1]) > point[1]) ? n : point[1]
        s = ((s || point[1]) < point[1]) ? s : point[1]
        e = ((e || point[0]) > point[0]) ? e : point[0]
        w = ((w || point[0]) < point[0]) ? w : point[0]

        return {lat: point[1], lng: point[0]}
      })

      // remove last point, last point is the first point repeated
      points.pop()

      var polyColor = this.polyColor
      var labelColor = this.labelColor
      if(this.state.highlighted_ref_pen == ref_pen) {
        polyColor = this.selectedPolyColor
        labelColor = this.selectedLabelColor
      } else if(this.state.selected_ref_pens.includes(ref_pen)) {
        polyColor = this.chosenPolyColor
        labelColor = this.chosenLabelColor
      }

      var poly = new google.maps.Polygon({
        paths: points,
        strokeColor: polyColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: polyColor,
        fillOpacity: 0.35,
        map: map
      });

      var _this = this
      

      // easy references
      poly.ref_pen = ref_pen
      ref_pen.poly = poly

      const setPolygonActions = (ref_pen) => {

        if (enableSingleClick == true || enableDoubleClick == true) {
          // remove default map action over polygon when hovering
          google.maps.event.addListener(ref_pen.poly, "mouseover", function(event) {
            map.set("disableDoubleClickZoom", true)
          })
          
          // apply default map action over polygon when not hovering
          google.maps.event.addListener(ref_pen.poly, "mouseout", function(event) {
            map.set("disableDoubleClickZoom", false)
          })
        }
      
        // first click listener
        // uses timeout to determine if is in fact a single click
        if(enableSingleClick) {
          google.maps.event.addListener(ref_pen.poly, 'click', function (event) {
            _this.timer = setTimeout(() => {
              _this.singleClick(ref_pen)
              _this.timer = null
            }, 200)
          })
        }
        
        // second click listener
        if(enableDoubleClick) {
          google.maps.event.addListener(ref_pen.poly, 'dblclick', function (event) {
            clearTimeout(_this.timer)
            _this.doubleClick(ref_pen)
          })
        }
      }
      setPolygonActions(ref_pen)

      // set marker
      if (useMarkers) {
        var centroid = turf.centroid(geometry)
        var marker = this.attachPolygonInfoWindow(new google.maps.LatLng(centroid.geometry.coordinates[1],centroid.geometry.coordinates[0]), ref_pen.name, labelColor)
        
        // easy reference
        ref_pen.marker = marker
      }
    }

    var markers = this.ref_pens.map(function(ref_pen){return ref_pen.marker})
    markers = markers.filter(marker => marker != null) // null values


    var styles = [
      {
        width: 0,
        height: 0,
        url: "#{Rails.public_path.join('blank_marker.png')}",
        textColor: "#ffffff",
        textSize: 0,
      }
    ]
    
    this.markerCluster = new MarkerClusterer(map, 
      markers,
      {styles: styles});
    this.markerCluster.setMinimumClusterSize(3)

    this.markerCluster.setCalculator(function (markers, numStyles) {
      var index = 0;
      var count = markers.length;
      var dv = count;
      while (dv !== 0) {
          dv = parseInt(dv / 10, 10);
          index++;
      }
  
      index = Math.min(index, numStyles);
      return {
          text: "", // set to empty string
          index: index
      }
    })

    map.fitBounds({south: s, west: w, north: n, east: e})
  }

  attachPolygonInfoWindow(location, name, color) {
    if (name == "" || name == "undefined" || name == "null" || name == null) {
      // no marker if empty name
      return
    } else {
  
      // set image of marker - blank png to simulate no image, labels are kept
      var image_path = "#{Rails.public_path.join('blank_marker.png')}"
  
      // create and add marker to the map
      var marker = new google.maps.Marker({
        position: location,
        label: {
          color: color,
          fontWeight: 'bold',
          text: name,
        },
        map: map,
        icon: {
          url: image_path
        },
        anchorPoint: {
          x: 0,
          y: 0
        },
        clickable: false,
        zIndex: 10,
        optimized: true
      });
  
      // return marker
      return marker
    }
  }

  singleClick(ref_pen) {

    // previous highlighted pen set colors back to normal
    var highlighted_ref_pen = this.state.highlighted_ref_pen
    if(highlighted_ref_pen != null) {

      var polyColor = this.polyColor
      var labelColor = this.labelColor
      if(this.state.selected_ref_pens.includes(highlighted_ref_pen)) {
        polyColor = this.chosenPolyColor
        labelColor = this.chosenLabelColor
      }

      highlighted_ref_pen.poly.setOptions({strokeColor: polyColor, fillColor: polyColor});
      if(highlighted_ref_pen.marker) {
        var label = highlighted_ref_pen.marker.getLabel()
        label.color = labelColor
        highlighted_ref_pen.marker.setLabel(label)
      }
    }
    
    // set new highlighted pen colors to green
    ref_pen.poly.setOptions({strokeColor: this.selectedPolyColor, fillColor: this.selectedPolyColor});
    if(ref_pen.marker) {
      var label = ref_pen.marker.getLabel()
      label.color = this.selectedLabelColor
      ref_pen.marker.setLabel(label)
    }

    // focus map onto polygon
    var bbox = turf.bbox(ref_pen.geometry)
    map.fitBounds({south: bbox[1], west: bbox[0], north: bbox[3], east: bbox[2]})
    map.setZoom(Math.min(18,map.zoom))
    
    // change state
    this.setState({highlighted_ref_pen: ref_pen})
  }

  doubleClick(ref_pen) {
    var selected_ref_pens = this.state.selected_ref_pens

    // previous highlighted pen set colors back to normal
    var highlighted_ref_pen = this.state.highlighted_ref_pen
    if(highlighted_ref_pen != null) {

      var polyColor = this.polyColor
      var labelColor = this.labelColor
      if(this.state.selected_ref_pens.includes(highlighted_ref_pen)) {
        polyColor = this.chosenPolyColor
        labelColor = this.chosenLabelColor
      }

      highlighted_ref_pen.poly.setOptions({strokeColor: polyColor, fillColor: polyColor});
      if(highlighted_ref_pen.marker) {
        var label = highlighted_ref_pen.marker.getLabel()
        label.color = labelColor
        highlighted_ref_pen.marker.setLabel(label)
      }
    }

    // set new highlighted pen colors to green
    ref_pen.poly.setOptions({strokeColor: this.selectedPolyColor, fillColor: this.selectedPolyColor});
    if(ref_pen.marker) {
      var label = ref_pen.marker.getLabel()
      label.color = this.selectedLabelColor
      ref_pen.marker.setLabel(label)
    }

    // Check if ref_pen is already selected
    var found = false
    for(var index in selected_ref_pens) {
      var selected_ref_pen = selected_ref_pens[index]
      if(selected_ref_pen.id == ref_pen.id) {
        found = true
        break
      }
    }

    // add to end if not selected
    if(found == false) {
      selected_ref_pens.push(ref_pen)
      this.setState({selected_ref_pens: selected_ref_pens, highlighted_ref_pen: ref_pen})
    }
  }

  removeRefPen(ref_pen) {
    var selected_ref_pens = this.state.selected_ref_pens
    for(var index in selected_ref_pens) {
      var selected_ref_pen = selected_ref_pens[index]
      if(selected_ref_pen.id == ref_pen.id) {

        var polyColor = this.polyColor
        var labelColor = this.labelColor

        ref_pen.poly.setOptions({strokeColor: polyColor, fillColor: polyColor});
        if(ref_pen.marker) {
          var label = ref_pen.marker.getLabel()
          label.color = labelColor
          ref_pen.marker.setLabel(label)
        }

        // focus map onto polygon
        var bbox = turf.bbox(ref_pen.geometry)
        map.fitBounds({south: bbox[1], west: bbox[0], north: bbox[3], east: bbox[2]})
        map.setZoom(Math.min(18,map.zoom))

        selected_ref_pens.splice(index,1)
        this.setState({selected_ref_pens: selected_ref_pens})
        return
      }
    }
  }

  search() {
    if(this.searchInput.current) {
      var searchStr = this.searchInput.current.value.toLowerCase()
      var searched_ref_pens = []

      if (searchStr) {
        for(var index in this.ref_pens) {
          var ref_pen = this.ref_pens[index]
          if(ref_pen.name) {
            if(fuzzysearch(searchStr, ref_pen.name.toLowerCase())) {
              searched_ref_pens.push(ref_pen)
            }
          }
        }
      } else {
        searched_ref_pens = this.ref_pens
      }
      
      this.setState({searched_ref_pens: searched_ref_pens})
    }
  }

  nextPage() {

    var page = this.state.page
    var thingsToChange = {}

    if(page == 1) {
      var job_name = this.nameInput.current.value.trim()
      if (job_name) {
        thingsToChange.job_name = job_name
      } else {
        alert("Please enter a name")
        return
      }
    }

    if(page != 4) {
      page += 1
      thingsToChange.page = page
    }

    if(thingsToChange.page == 4) {
      thingsToChange.highlighted_ref_pen = null
      
      var ref_pen = this.state.highlighted_ref_pen
      var polyColor = this.polyColor
      var labelColor = this.labelColor
      if(this.state.selected_ref_pens.includes(ref_pen)) {
        polyColor = this.chosenPolyColor
        labelColor = this.chosenLabelColor
      }

      if(ref_pen) {
        ref_pen.poly.setOptions({strokeColor: polyColor, fillColor: polyColor});
        if(ref_pen.marker) {
          var label = ref_pen.marker.getLabel()
          label.color = labelColor
          ref_pen.marker.setLabel(label)
        }
      }
    }

    this.setState(thingsToChange, () => {
      if(this.state.page == 2) {
        this.openPageWithMap()
      } else if (this.state.page == 4) { // review  
        this.openPageWithMap(false)
      }
    })
  }

  previousPage() {
    var page = this.state.page
    if(page > 1) {
      page -= 1
    }
    this.setState({page: page}, () => {
      if(this.state.page == 2) {
        this.openPageWithMap()
      }
    })
  }

  openPageWithMap(actionable = true) {
    var mapCreated = false
    if(map) {
      mapCreated = true
    }
    initiate_map(false) // function from assets

    if(mapCreated == false) {
      this.generatePolygons()
    } else {
      map.fitBounds({south: s, west: w, north: n, east: e})
    }
    
    this.search()
  }

  editYardCount(ref_pen, event) {
    var yard_count = parseInt(event.target.value)
    if(isNaN(yard_count)) {
      ref_pen.yard_count = null
    } else {
      ref_pen.yard_count = Math.min(Math.max(parseInt(yard_count), 0), 1000);
    }
    this.setState({})
  }

  ignoreChanged() {
    var checked = this.ignoreInput.current.checked
    this.setState({ignore_job: checked})
  }

  submit() {
    if (this.state.is_submitting) {
      return
    }
    this.setState({is_submitting: true})
    const job_name = this.state.job_name
    const selected_ref_pens = this.state.selected_ref_pens.map(function(ref_pen) {
      return {id: ref_pen.id, yard_count: ref_pen.yard_count}
    })
    const ignore = this.state.ignore_job
    const form_authenticity_token = this.props.form_authenticity_token

    var _this = this
    
    var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token);
      jform.append('job_name', job_name)
      jform.append('ignore', ignore)
      jform.append('selected_ref_pens',JSON.stringify(selected_ref_pens))
    $.ajax({
      url: `/feedlots/${this.feedlot_id}/create_job`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          window.location.href = `/jobs/${response.job_id}`
        } else {
          alert("Failed. Please Try Again")
        }
      },
      error: function(request, textStatus, errorThrown) {
        alert("Failed. Please Try Again")
      },
      complete: function() {
        _this.setState({is_submitting: false})
      }
    })
  }
  
}

export default CreateJobAndPlan
