import React, { Fragment } from "react"
import {Card, Container, Row, Col, Button} from 'react-bootstrap' 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select"

export default class CounterAudit extends React.Component {
  
  #audit_id = null
  #user = null
  #is_admin = false

  constructor(props) {
    super(props)

    this.#audit_id = props.audit.id
    this.#user = props.audit.user
    this.#is_admin = props.is_admin

    this.state = {
      audit: props.audit,
      is_submitting: false
    }
    // audit consists of id, comments, allow_counter_viewing, counter_audit_details, user
    this.updateDetail = this.updateDetail.bind(this)

    this.removeDetail = this.removeDetail.bind(this)
  }

  render () {
    const details = this.state.audit.counter_audit_details
    const comments = this.state.audit.comments
    return (
      <React.Fragment>
        <Row>
          <Col>
            {this.renderPercentageRow()}

            { this.#is_admin && this.#audit_id != null &&
              <div>
                <label> Counter Access: </label>
                <i className={`btn-icon fas fa-toggle-${this.state.audit.allow_counter_viewing ? "on" : "off"} ml-2`}
                  onClick={() => {this.toggleCounterAccess()}}/>
              </div>
            }
          </Col>

          { this.#is_admin &&
            <Col xs="auto">
              <Button type="submit" variant="primary" onClick={() => this.submit()}>Save</Button>
            </Col>
          }
          
        </Row>

        <Row><Col>
          <div className="form-group">
            <label>Comments</label>
            <textarea
              readOnly={!this.#is_admin}
              className="form-control"
              value={comments || ""}
              onChange={(e) => {
                var audit = this.state.audit
                audit.comments = e.target.value
                this.setState({audit: audit})
              }}
            />
          </div>
        </Col></Row>

        <Row><Col>

          {details.map((detail, index) => {
            if (this.#is_admin == false && (detail.valid_missed_detection_ids.length == 0 && detail.boundary_changed == false)) {
              return null
            } else {
              return <EditCounterDetails 
                is_admin={this.#is_admin}
                detail={detail} 
                updateDetailCallback={this.updateDetail}
                removeDetailCallback={this.removeDetail}
                key={"detail_"+detail.counting_stat_id} 
              />
            }
          })}

        </Col></Row>
        
        { this.#is_admin &&
          <Row><Col>
            <Button type="submit" variant="primary" onClick={() => this.submit()}>Save</Button>
          </Col></Row>
        }
        
      </React.Fragment>
    )
  }

  renderPercentageRow() {
    return <React.Fragment>
      <strong className="mr-2">Total Percentage: </strong>
      { this.calculate_percentage() }
      
      { this.#is_admin &&
        <React.Fragment>
          <br/>
          <strong className="mr-2">Percent Boundary Correct: </strong>
          { 100 - this.calculate_boundary_different_percentage() }
        </React.Fragment>
      }
      
      <br/>
      <strong className="mr-2">Boundary Differences Key: </strong>
      <i>Red Lines - Your Boundary. Green Lines - Final Boundary</i>
    </React.Fragment>
  }

  calculate_percentage() {
    // sum the percentages of all the details and divide by the number of details
    const details = this.state.audit.counter_audit_details
    if (details.length == 0) { return 0 }
    var sum = 0
    
    details.forEach((detail) => {
      sum += this.calculate_detail_percentage(detail)
    })
    var percentage = sum / details.length
    percentage = round(percentage, 2)
    return percentage
  }

  calculate_boundary_different_percentage() {
    const details = this.state.audit.counter_audit_details
    if (details.length == 0) { return 0 }
    var sum = 0
    details.forEach((detail) => {
      if (detail.boundary_changed) {
        sum += 1
      }
    })
    var percentage = (sum / details.length) * 100
    percentage = round(percentage, 2)
    return percentage
  }

  calculate_detail_percentage(detail) {
    var percentage = ((detail.head_count - detail.valid_missed_detection_ids.length) / detail.head_count) * 100
    percentage = clamp(percentage, 0, 100)
    percentage = round(percentage, 2)
    return percentage
  }

  updateDetail(detail) {
    var audit = this.state.audit
    var index = audit.counter_audit_details.findIndex((d) => d.counting_stat_id == detail.counting_stat_id)
    if (index == -1) {
      console.error("Could not find detail with counting_stat_id: " + detail.counting_stat_id)
      return
    }
    audit.counter_audit_details[index] = detail
    this.setState({audit: audit})
  }

  removeDetail(detail) {
    var audit = this.state.audit
    var index = audit.counter_audit_details.findIndex((d) => d.counting_stat_id == detail.counting_stat_id)
    if(index == -1) {
      return
    }

    audit.counter_audit_details.splice(index, 1)
    this.setState({audit: audit})
  }

  submit() {
    if (this.state.is_submitting) {
      return
    }

    if (this.state.audit.id != this.#audit_id) {
      return
    }

    if (this.state.audit.user.id != this.#user.id) {
      return
    }
    
    var route = this.#audit_id == null ? Routes.admin_counter_audits_path() : Routes.admin_counter_audit_path(this.#audit_id)
    var method = this.#audit_id == null ? "POST" : "PATCH"

    let user_id = this.state.audit.user.id
    let comments = this.state.audit.comments || ""

    let details = this.state.audit.counter_audit_details.map((detail) => {
      return {
        counting_stat_id: detail.counting_stat_id,
        head_count: detail.head_count,
        pen_id: detail.pen.id,
        boundary_changed: detail.boundary_changed,
        added_detection_ids: detail.added_detection_ids,
        removed_detection_ids: detail.removed_detection_ids,
        small_movements: detail.small_movements,
        valid_missed_detection_ids: detail.valid_missed_detection_ids,
        show_boundary: detail.show_boundary,
        comments: detail.comments || ""
      }
    })

    this.setState({is_submitting: true})

    var _this = this
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append("user_id", user_id)
    jform.append("comments", comments)
    jform.append("counter_audit_details", JSON.stringify(details))
    $.ajax({
      url: route,
      type: method,
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          window.location = Routes.admin_counter_audit_path(response.counter_audit_id)
        } else {
          postAlert("danger", "Failed To Save Audit")
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", "Failed To Save Audit")
      },
      complete: function() {
        _this.setState({is_submitting: false})
      }
    })
  }

  toggleCounterAccess() {
    if (this.state.is_submitting) {
      return
    }

    if (this.#audit_id == null) {
      return
    }

    var route = Routes.set_counter_access_admin_counter_audit_path(this.#audit_id)
    var method = "POST"

    this.setState({is_submitting: true})

    var _this = this
    var jform = new FormData();
    var audit = this.state.audit
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append("value", !audit.allow_counter_viewing)
    $.ajax({
      url: route,
      type: method,
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          audit.allow_counter_viewing = response.value
          _this.setState({ audit: audit })
          postAlert("success", "Success Updating Counter Access")
        } else {
          postAlert("danger", "Failed To Update Counter Access")
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", "Failed To Update Counter Access")
      },
      complete: function() {
        _this.setState({is_submitting: false})
      }
    })
  }
}

class EditCounterDetails extends React.Component {
  #is_admin = false

  constructor(props) {
    super(props)
    this.#is_admin = props.is_admin
    this.updateDetailCallback = props.updateDetailCallback
    this.removeDetailCallback = props.removeDetailCallback

    this.state = {
      detail: props.detail
    }

    // detail consists of 
    // counting_stat_id, head_count, pen, boundary_changed, added_detection_ids, removed_detection_ids, small_movements, valid_missed_detection_ids, percentage_override, show_boundary, comments
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.detail != this.props.detail) {
      this.setState({detail: this.props.detail})
    }
  }

  render() {
    return <div className="card my-2" style={{height: "auto"}}>
      {this.renderCardHeader()}
      <div className="card-body">
        <div className="container-fluid">
          <Row>
            <Col xs="auto">
              {this.renderInfo()}
            </Col>
            <Col>
              {this.renderImages()}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderComments()}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  }

  renderCardHeader() {
    const pen_id = this.state.detail.pen.id
    const pen_name = this.state.detail.pen.name || ""
    if (this.#is_admin) {
      return <div className="card-header"> 
        <a className="link-light" href={Routes.stats_admin_pen_path(pen_id)} target="_blank" tabIndex={-1}>
          {pen_name} - {pen_id}
        </a>
        {this.#is_admin &&
          <button className="btn btn-danger float-right" onClick={() => this.removeDetailCallback(this.state.detail)}>
            <i className="fas fa-trash"/>
          </button>
        }
      </div>
    } else {
      return <div className="card-header"> 
        {pen_id}
      </div>
    }
    
  }


  renderInfo() {
    if (this.#is_admin) {
      const removed_detection_ids = this.state.detail.removed_detection_ids
      const added_detection_ids = this.state.detail.added_detection_ids
      const boundary_changed = this.state.detail.boundary_changed
      const small_movements = this.state.detail.small_movements
      const valid_missed_detection_ids = this.state.detail.valid_missed_detection_ids
      const head_count = this.state.detail.head_count
      return <React.Fragment>
        <div>
          <strong className="mr-2">Boundary Different:</strong>
          {boundary_changed ? "Yes" : "No"}
        </div>
        <div>
          <strong className="mr-2">Removals:</strong>
          {removed_detection_ids.length}
        </div>
        <div>
          <strong className="mr-2">Additions:</strong>
          {added_detection_ids.length}
        </div>
        <div>
          <strong className="mr-2">Small Movements: </strong>
          {small_movements.length}
        </div>
        <div>
          <strong className="mr-2">Valid Issues: </strong>
          {valid_missed_detection_ids.length}
        </div>
        <div>
          <strong className="mr-2">Percentage: </strong>
          {this.calculate_percentage()}
        </div>
        <div>
          <strong className="mr-2">Total Head: </strong>
          {head_count}
        </div>
        
      </React.Fragment> 
    } else {
      const boundary_changed = this.state.detail.boundary_changed
      const valid_missed_detection_ids = this.state.detail.valid_missed_detection_ids
      const head_count = this.state.detail.head_count
      return <React.Fragment>
        <div>
          <strong className="mr-2">Boundary Different:</strong>
          {boundary_changed ? "Yes" : "No"}
        </div>
        <div>
          <strong className="mr-2">Valid Issues: </strong>
          {valid_missed_detection_ids.length}
        </div>
        <div>
          <strong className="mr-2">Percentage: </strong>
          {this.calculate_percentage()}
        </div>
        <div>
          <strong className="mr-2">Total Head: </strong>
          {head_count}
        </div>
      </React.Fragment> 
    }
  }

  renderImages() {
    const counting_stat_id = this.state.detail.counting_stat_id
    const pen_id = this.state.detail.pen.id
    const removed_detection_ids = this.state.detail.removed_detection_ids
    const added_detection_ids = this.state.detail.added_detection_ids
    const valid_missed_detection_ids = this.state.detail.valid_missed_detection_ids
    const small_movements = this.state.detail.small_movements
    const boundary_changed = this.state.detail.boundary_changed
    var sm_removed_detection_ids = this.state.detail.small_movements.map((sm) => sm.removed_detection_id)
    var sm_added_detection_ids = this.state.detail.small_movements.map((sm) => sm.added_detection_id)
    var removed_detection_ids_to_show = difference(removed_detection_ids, sm_removed_detection_ids)
    var added_detection_ids_to_show = difference(added_detection_ids, sm_added_detection_ids)

    let boundary_picture_route = this.#is_admin ? 
      Routes.generate_picture_boundary_difference_admin_pen_path(pen_id, {counting_stat_id: counting_stat_id}) 
      : 
      Routes.generate_picture_boundary_difference_counters_path({pen_id: pen_id, counting_stat_id: counting_stat_id})


    return <React.Fragment>
      {removed_detection_ids_to_show.map((detection_id) => {
        let is_valid = valid_missed_detection_ids.includes(detection_id)
        let picture_route = this.#is_admin ? 
          Routes.generate_picture_snippet_admin_pen_path(pen_id, {removed_detection_id: detection_id}) 
          : 
          Routes.generate_picture_snippet_counters_path({pen_id: pen_id, removed_detection_id: detection_id})

        if (this.#is_admin || is_valid) {
          return <div 
            className="m-2 p-3" 
            style={{display: "inline-block", width: "200px", border: "2px solid #b81414", borderRadius: "20px"}}
            key={"removal_" + counting_stat_id + "_detection_id_" + detection_id}
            >
            <div className="mb-2">
              <label>Removal</label>
              { this.#is_admin &&
                <input className="float-right" type="checkbox" tabIndex={-1} checked={is_valid} onClick={() => this.toggleDetectionValidity(detection_id)}/>
              }
            </div>
            <img 
              style={{width: "100%", height: "100%", backgroundColor: "#f3f3f3"}}
              src={picture_route}
              loading="lazy"
              key={counting_stat_id + "_removed_detection_id_" + detection_id}
            />
          </div>
        } else {
          return null
        }
      })}
      {added_detection_ids_to_show.map((detection_id) => {
        let is_valid = valid_missed_detection_ids.includes(detection_id)
        let picture_route = this.#is_admin ? 
          Routes.generate_picture_snippet_admin_pen_path(pen_id, {added_detection_id: detection_id}) 
          : 
          Routes.generate_picture_snippet_counters_path({pen_id: pen_id, added_detection_id: detection_id})
        if (this.#is_admin || is_valid) {
          return <div 
            className="m-2 p-3" 
            style={{display: "inline-block", width: "200px", border: "2px solid #b81414", borderRadius: "20px"}}
            key={"addition_" + counting_stat_id + "_detection_id_" + detection_id}
            >
            <div className="mb-2">
              <label>Addition</label>
              { this.#is_admin &&
                <input className="float-right" type="checkbox" tabIndex={-1} checked={is_valid} onClick={() => this.toggleDetectionValidity(detection_id)}/>
              }
            </div>
            <img 
            style={{width: "100%", height: "100%", backgroundColor: "#f3f3f3"}}
            src={picture_route}
            loading="lazy"
            key={counting_stat_id + "_added_detection_id_" + detection_id}
          />
          </div>
        } else {
          return null
        }
      })}

      {small_movements.map((movement) => {
        let removed_id = movement.removed_detection_id
        let added_id = movement.added_detection_id
        let is_valid = valid_missed_detection_ids.includes(removed_id) || valid_missed_detection_ids.includes(added_id)
        let picture_route = this.#is_admin ? 
          Routes.generate_picture_snippet_admin_pen_path(pen_id, {removed_detection_id: removed_id, added_detection_id: added_id}) 
          : 
          Routes.generate_picture_snippet_counters_path({pen_id: pen_id, removed_detection_id: removed_id, added_detection_id: added_id})
        if (this.#is_admin || is_valid) { 
          return <div 
            className="m-2 p-3" 
            style={{display: "inline-block", width: "200px", border: "2px solid #b81414", borderRadius: "20px"}}
            key={"small_movement_" + counting_stat_id + "_removed_id_" + removed_id + "_added_id_" + added_id}
            >
            <div className="mb-2">
              <label>Movement</label>
              { this.#is_admin &&
                <input className="float-right" type="checkbox" tabIndex={-1} checked={is_valid} onClick={() => this.toggleSmallMovementValidity(is_valid, removed_id, added_id)}/>
              }
            </div>
            <img 
            style={{width: "100%", height: "100%", backgroundColor: "#f3f3f3"}}
            src={picture_route}
            loading="lazy"
            key={counting_stat_id + "_small_movement_" + removed_id + "_" + added_id}
            />
          </div>
        } else {
          return null
        }
      })}

      { boundary_changed && 
        <div 
          className="m-2 p-3" 
          style={{display: "inline-block", width: "500px", border: "2px solid #b81414", borderRadius: "20px"}}
          key={"boundary_changed_" + counting_stat_id}
          >
          <div className="mb-2">
            <label>Boundary Different</label>
            <a className="btn btn-info float-right fas fa-eye" href={boundary_picture_route} target="_blank" tabIndex={-1}/>
          </div>
          <img 
          style={{width: "100%", height: "100%", backgroundColor: "#f3f3f3"}}
          src={boundary_picture_route}
          loading="lazy"
          key={counting_stat_id + "_boundary_changed"}
         />
        </div>
      }
      
    </React.Fragment>
  }

  renderComments() {
    return <div className="form-group">
      <label>Comments</label>
      <textarea
        readOnly={!this.#is_admin}
        className="form-control"
        value={this.state.detail.comments || ""}
        onChange={(e) => {
          var detail = this.state.detail
          detail.comments = e.target.value
          this.updateDetailCallback(detail)
        }}
      />
    </div>
  }

  calculate_percentage() {
    const head_count = this.state.detail.head_count
    const valid_missed_detection_ids = this.state.detail.valid_missed_detection_ids
    var percentage = ((head_count - valid_missed_detection_ids.length) / head_count) * 100
    percentage = clamp(percentage, 0, 100)
    percentage = round(percentage, 2)
    return percentage
  }

  toggleDetectionValidity(detection_id) {
    var detail = this.state.detail
    var valid_missed_detection_ids = detail.valid_missed_detection_ids
    if (valid_missed_detection_ids.includes(detection_id)) { // if is valid then remove it
      valid_missed_detection_ids = valid_missed_detection_ids.filter((id) => id != detection_id)
      detail.valid_missed_detection_ids = valid_missed_detection_ids
      this.setState({detail: detail})
      this.updateDetailCallback(detail)
    } else {
      const removed_detection_ids = detail.removed_detection_ids
      const added_detection_ids = detail.added_detection_ids
      if (removed_detection_ids.includes(detection_id) || added_detection_ids.includes(detection_id)) {
        valid_missed_detection_ids = valid_missed_detection_ids.concat(detection_id)
        detail.valid_missed_detection_ids = valid_missed_detection_ids
        //this.setState({audit: audit})
        this.updateDetailCallback(detail)
      }
    }
  }

  toggleSmallMovementValidity(is_valid, removed_id, added_id) {
    var detail = this.state.detail
    var valid_missed_detection_ids = detail.valid_missed_detection_ids
    if (is_valid) {
      valid_missed_detection_ids = valid_missed_detection_ids.filter((id) => {
        return id != removed_id && id != added_id
      })
      detail.valid_missed_detection_ids = valid_missed_detection_ids
      //this.setState({detail: detail})
      this.updateDetailCallback(detail)
    } else {
      const removed_detection_ids = detail.removed_detection_ids
      const added_detection_ids = detail.added_detection_ids
      if(
        removed_detection_ids.includes(removed_id) || 
        added_detection_ids.includes(removed_id)
      ) {
        valid_missed_detection_ids = valid_missed_detection_ids.concat(removed_id)
      }
      if(
        removed_detection_ids.includes(added_id) || 
        added_detection_ids.includes(added_id)
      ) {
        valid_missed_detection_ids = valid_missed_detection_ids.concat(added_id)
      }
      detail.valid_missed_detection_ids = valid_missed_detection_ids
      //this.setState({detail: detail})
      this.updateDetailCallback(detail)
    }
  }
}