import React from "react"
import {Container, Row, Col, NavLink} from 'react-bootstrap' 
import Joyride, { ACTIONS, EVENTS, STATUS }  from 'react-joyride';

export default class JoyRideTutorial extends React.Component {

  constructor(props) {
    super(props);

    var completed_steps = props.completed_steps
    this.is_mobile = props.is_mobile

    var steps = [
      {
        id: 1,
        target: 'body',
        content: 'This quick tutorial will get you up to speed and display our new features!',
        placement: 'center',
        disableScrolling: true,
        disableBeacon: true
      },
      {
        id: 2,
        target: '#feedlot_select',
        title: 'Select Feedlot',
        content: "Select the feedlot you'd like to view from here.  This always shows the feedlot you are currently viewing",
        placement: 'bottom',
        disableScrolling: true,
        disableBeacon: true
      },
      {
        id: 3,
        target: '#notifications',
        title: 'Notifications',
        content: "Stay up to date on recent activity done on your feedlots.",
        placement: 'bottom',
        disableScrolling: true,
        disableBeacon: true
      },
      {
        id: 4,
        target: '#left-menu',
        title: 'Left Menu Navigation',
        content: 'This navigation menu is great for quick navigation. Always know how to get back to the Job or Feedlot Level. It also contains other actions like logging out, profile settings, etc.',
        placement: 'right',
        disableScrolling: true,
        disableBeacon: true,
      },
      {
        id: 5,
        target: '#ordered-jobs',
        title: 'Ordered Jobs',
        content: "This section is where jobs you've ordered will be. You can order new jobs from this area as well as upload images",
        placement: 'auto',
        disableBeacon: true,
      },
      {
        id: 6,
        target: '#completed-jobs',
        title: 'Completed Jobs',
        content: "Jobs that have been finished being counted will display here along with relevant stats.",
        placement: 'auto',
        disableBeacon: true,
      },
      {
        id: 7,
        target: '#team-members',
        title: 'Team Members',
        content: "Manage and view users that have access to this feedlot from here.",
        placement: 'auto',
        disableBeacon: true,
      },
      {
        id: 8,
        target: '#notif-settings',
        title: 'Notification Settings',
        content: "Manage your notifications for this feedlot.  Turn on/off email and/or sms notifications.",
        placement: 'auto',
        disableBeacon: true,
      },
      {
        id: 9,
        target: '#feedlot-map',
        title: 'Map',
        content: "This displays a map of all your pens.  You can edit the names of these pens as well.  If you have updates to the geometry of the pens, please reach out to us to update it.",
        placement: 'left-start',
        disableBeacon: true,
      },
      {
        id: 10,
        target: '#upload-images-btn',
        title: 'Upload Images',
        content: "Click here to upload images to this job.",
        placement: 'bottom',
        disableBeacon: true,
      },
      {
        id: 11,
        target: '#export-pen-data-btn',
        title: 'Export Pen Data',
        content: "When a job is complete, click here to export the pen data to csv file.",
        placement: 'bottom',
        disableBeacon: true,
      },
      {
        id: 12,
        target: '#pen-list-component',
        title: 'Pen Counting Stats',
        content: "Counting Data that we calculate will display once the job is completed.",
        placement: 'top',
        disableBeacon: true,
      },
      {
        id: 13,
        target: '#list-map-toggler',
        title: 'Toggle List/Map View',
        content: "Switch between viewing the pens as a list or represented in a map",
        placement: 'top',
        disableBeacon: true,
      },
      {
        id: 14,
        target: '#pen-row',
        title: 'Viewing Pens',
        content: "Either click on the pen name to view the image on this page or click the menu button at the right of the row to open the pen in a new tab.",
        placement: 'top',
        disableBeacon: true,
      },
      {
        id: 15,
        target: '#pen-view-component',
        title: 'Viewing Counted Pen Image',
        content: "Zoom in via scrolling over the image or using the zoom tool.  Pan by dragging the picture around with your mouse.",
        placement: 'center',
        disableBeacon: true,
      }
    ]

    // remove steps that have been completed
    steps = steps.filter((step) => completed_steps.includes(step.id) == false)

    this.state = {
      steps: steps,
      run: false   
    };

    setTimeout(()=>this.setState({run: true}), 3000)
  }

  handleJoyrideCallback = data => {
    
    const { action, index, status, type } = data;

    if(type == "step:after" && (action == "close" || action == "next" || action == "prev")) {
      var tutorial_step_id = data.step.id
      
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      jform.append('tutorial_step_id', tutorial_step_id)
      $.ajax({
        url: Routes.home_viewed_tutorial_step_path(),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if(response.status == "Success") {
            console.log("Successfully Updated Tutorial Step")
          } else {
            console.log("Failed To Update Tutorial Step")
          }
        },
        error: function(request, textStatus, errorThrown) {
          console.log("Failed To Update Tutorial Step")
        },
        complete: function() {
        }
      })
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render () {
    var nav_height = document.getElementById("header").clientHeight
    
    return (
      <Joyride 
        steps={this.state.steps}
        callback={this.handleJoyrideCallback}
        showSkipButton={false}
        continuous={true}
        disableCloseOnEsc={true}
        disableScrollParentFix={!this.is_mobile}
        hideCloseButton={true}
        //disableScrolling={true}
        //scrollToFirstStep={false}
        scrollDuration={600}
        scrollOffset={nav_height}
        run={this.state.run}
        locale={{next: "Ok", last: "Finish"}}
        styles={{
          options: {
            primaryColor: '#b81414'
          }
        }}
      />
      
    )
  }
}


