import React from "react"
import PenListComponent from "./PenListComponent"
import JobMapCard from "./JobMapCard"

export default class PensViewToggler extends React.Component {

  constructor(props) {
    super(props)

    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id
    this.job_finished = props.job_finished || false
    this.is_admin = props.is_admin || false
    this.show_map = props.show_map || false
    this.focused_pen_id = props.focused_pen_id || null
    
    this.state = {
      selected: this.show_map == false ? "List" : "Map"
    }

    this.localMessageSubscriber = this.subscribeToLocalMessages()
  }

  render () {
    try {
      return (
        <React.Fragment>
          {this.state.selected == "List" && 
            <PenListComponent job_id={this.job_id} feedlot_id={this.feedlot_id} is_admin={this.is_admin}/>
          }
          {this.state.selected == "Map" && 
            <JobMapCard job_id={this.job_id} job_finished={this.job_finished} feedlot_id={this.feedlot_id} is_admin={this.is_admin} focused_pen_id={this.focused_pen_id}/>
          }
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribeToLocalMessages() {
    var _this = this
    return postal.subscribe({
      channel: "PensViewToggler",
      topic: "toggle",
      callback: function(data, envelope) {
        if (data.message == "Map") {
          _this.setState({
            selected: "Map"
          })
        } else if (data.message == "List") {
          _this.setState({
            selected: "List"
          })
        }
      }
    });
  }
}