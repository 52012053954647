import React from "react"

/*
  Contact Support with a template body
*/
export default class ContactSupport extends React.Component {

  constructor(props) {
    super(props);
  }


  render () {
    return <a 
      className="btn btn-primary"
      href="mailto:support@headcountinventory.com?
        &body=Step 1: Enter a broad subject name describing your issue or question.%0D%0A
        Step 2: Include Relevant Details. IE - Feedlot Name/Job Name/Pen Name/Links. Note: More info is better.%0D%0A
        Step 3: Describe the issue you are experiencing or question you have with as much details as you can. IE - What actions were you taking when it occured and on which pages (include links if you can).%0D%0A
        %0D%0A
        %0D%0A">
      Contact Support
    </a>
  }
}
