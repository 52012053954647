import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 

/*
  This class is not generally used and will likely be deleted soon or revamped

  Shows some recent stats that the counters did but doesn't diplay enough info to matter
*/
export default class TeamCard extends React.Component {

  constructor(props) {
    super(props)

    this.is_admin = props.is_admin || false

    this.feedlot_id = props.feedlot_id
    this.searchInput = React.createRef()
    this.shareModal = React.createRef()
    
    this.state = {
      status: "Loading",
      shares: []
    }

    this.getShares = this.getShares.bind(this)
    this.feedlotListener = this.subscribe()

    this.openShareModal = this.openShareModal.bind(this)
    this.removeShare = this.removeShare.bind(this)
  }

  render () {
    try {
      return (
        <React.Fragment>
          <Card id="team-members">
            <Card.Header>
              <Card.Title>
                Team
              </Card.Title>
              
              <button 
                className="btn btn-primary ml-2 fas fa-user-plus"
                data-tip data-for="invite-tooltip"
                onClick={this.openShareModal}
                style={{verticalAlign: "bottom"}}/>
            
              <ReactTooltip id='invite-tooltip' effect='solid' 
                 

                textColor="black">
                <span>Invite A User To Have Access To This Feedlot</span>
              </ReactTooltip>
            </Card.Header>
            <Card.Body>
              {this.state.shares.map((share,i) => 
                <TeamShareObject 
                key={share.share_id}
                is_admin={this.is_admin}
                feedlot_id={this.feedlot_id}
                share={share} 
                removeShare={this.removeShare}
                />
              )}
            </Card.Body>
          </Card>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "DashboardChannels::TeamComponentChannel",
        feedlot_id: this.feedlot_id
      },
      {  
        connected() {
          _this.getShares()
        },

        received(data) {
          if (data.shares != null) {
            _this.setState({
              shares: data.shares
            })
          } else if(data.message == "updated") {
            this.perform("get_updates")
          }
        }
      }
    )
  }

  getShares() {
    let search = this.searchInput?.current?.value || ""
    this.feedlotListener.perform("get_updates")
  }

  openShareModal() {
    postal.publish({
      channel: "ShareModal",
      topic: "open",
      data: {
        feedlot_id: this.feedlot_id
      }
    })
  }

  removeShare(share) {
    var _this = this
    
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('share_id', share.share_id);

    $.ajax({
      url: `/feedlots/${this.feedlot_id}/remove_team_member`,
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data', // this too
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        var status = response.status
        
        if (status == "Success") {
          postAlert("success", `Successfully Removed ${share.name || share.email}`)
        } else {
          postAlert("danger", `Failed To Remove ${share.name || share.email}`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Remove ${share.name || share.email}`)
      },
      complete: function() {
        
      }
    })
  }
}

class TeamShareObject extends React.Component {

  constructor(props) {
    super(props);

    this.removeShare = props.removeShare
    this.is_admin = props.is_admin
    this.feedlot_id = props.feedlot_id
    
    this.state = {
      share: props.share,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      share: nextProps.share
    })
  }

  componentWillUnmount() {
    this.listener?.unsubscribe()
  }

  render () {
    try {
      var share = this.state.share
      var name = share.name || share.email || "Undefined"
      var is_admin = this.is_admin

      var user_path = is_admin ? Routes.admin_user_path(share.user_id) : null
      
      return (
        <Container fluid className="card-body-row">
          <Row className={"pt-1 pb-1"}>
            <Col className="pl-1">
              <a href={user_path}>
                  <Row>
                    {(share.name != null && share.name != "") &&
                      <React.Fragment>
                        <Col xs="auto">
                          {share.name}
                        </Col>
                      </React.Fragment>
                    }
                    <Col xs="auto">
                      <i>{share.email}</i>
                    </Col>
                  </Row>
                </a>
                {is_admin && 
                  <Row>
                    <Col xs="auto">
                      Phone Number: <a href={`tel:${share.phone_number}`}>{formatPhoneNumber(share.phone_number)}</a>
                    </Col>
                    <Col xs="auto">
                      Email Notifs: 
                      <input className="ml-2" readOnly type="checkbox" defaultChecked={share.email_notifications}/>  
                    </Col>

                    <Col xs="auto">
                      SMS Notifs: 
                      <input className="ml-2" readOnly type="checkbox" defaultChecked={share.sms_notifications}/> 
                    </Col>
                  </Row>
                }
            </Col>
            <Col xs="auto">
              <button 
                className="btn text-danger btn-icon fas fa-trash" 
                role="button" 
                onClick={() => {
                  if (ask(`Are You Sure you want to Remove ${name}?`)) {
                    this.removeShare(share)
                  }
                }}/>
            </Col>
          </Row>
        </Container>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }
}