import React from "react"
import {Row, Col} from 'react-bootstrap' 
import CustomSmoothEntry from "../../CustomSmoothEntry"

export default class TempAlertComponent extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      alerts: [], //format - {type: "alert||notice", message: "string", id: UUID}
    }

    this.localMessageSubscriber = this.subscribeToLocalMessages()
  }

  render () {
    try {
      var alerts = this.state.alerts
      console.log(alerts)
      return (
        <React.Fragment>
          {alerts.map((alert, index) =>
            <CustomSmoothEntry key={alert.id} removing={alert.removing || false}>

              <Row className="temp-message">
                <Col md="6" xs="12">
                  <div className={`alert alert-${alert.type}`} role="alert">
                    {alert.message}
                  </div>
                </Col>
              </Row>
            </CustomSmoothEntry>
          )}
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribeToLocalMessages() {
    var _this = this

    return postal.subscribe({
      channel: "TempAlertComponent",
      topic: "alert",
      callback: function(data, envelope) {
        var uuid = stringToUUID()
        // append new alert to the front and remove after some seconds
        if (data.type && data.message) {
          _this.setState(function(state, props) {
            return {
              alerts: [{type: data.type, message: data.message, id: uuid}, ...state.alerts]
            }
          }, () => {

            // set timeout to let item know its going to be removed
            setTimeout(() => {
              _this.setState(function(state, props) {
                return {
                  alerts: state.alerts.map((alert) => {
                    if (alert.id != uuid) {
                      return alert
                    } else {
                      alert.removing = true
                      return alert
                    }
                  })
                }
              })
            },7000)

            // set timeout to remove item
            setTimeout(() => {
              _this.setState(function(state, props) {
                return {
                  alerts: state.alerts.filter((alert) => alert.id != uuid)
                }
              })
            },9000)

          })
          
        }

      }
    });
  }


}