import React from "react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class CustomDateTimePicker extends React.Component {

  constructor(props) {
    super(props)

    console.log(props)

    this.state = {
      value: stringToDate(props.value)
    }

    this.name = props.name
    this.format = props.format
    this.time_zone = props.time_zone || "UTC"
  }

  render () {

    var adjustedDate = isValidDate(this.state.value) ? new Date(this.state.value) : null

    return (
      <React.Fragment> 
        <input type="hidden" name={this.name} value={adjustedDate}/>
        <DatePicker 
          selected={this.state.value}
          onChange={(date) => {
            this.setState({value: date})
          }}
          showTimeSelect
          dateFormat={this.format}
          isClearable
        />
      </React.Fragment>
    )
  }
}