import React from "react"
import ProgressBar from 'react-bootstrap/ProgressBar'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'

/*
  Used to import a csv that contains yard_count info for pens
*/
class ImportCSV extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      count: 0,
      complete: 0,
      errors: 0,
      done: false
    }

    this.organizerArray = null
    this.toBeImported = []

    this.organizerInput = React.createRef()
    this.organizerButton = React.createRef()
    this.csvPenList = React.createRef();

    this.hasBeenOpened = false
  }

  render () {
    try {
      const uploading = this.state.uploading
      const count = this.state.count
      const complete = this.state.complete
      const errors = this.state.errors
      const done = this.state.done
      return (
        <div className="col-lg-12">
          <div className="row">
            {uploading == true &&
              <div>
                <h6 style={{display: "inline"}}>
                  {done ? "Done: " : "Uploading: "}
                  <div style={{display: 'inline'}}>
                    {complete}
                  </div>
                  /
                  <div style={{display: 'inline'}}>
                    {count}
                  </div>
                  {errors > 0 && 
                    <div style={{display: 'inline'}}>
                      {"   "}
                      Errors: 
                      {errors}
                    </div>
                  }
                </h6>
                {done == false && 
                  <div style={{color: "red"}}>
                    Do not leave page until upload is finished
                  </div>
                }
              </div>
            }
            {uploading == false &&
              <h6 style={{display: "inline"}}>
                <div style={{display: 'inline'}}>
                  {count} Total Pens to Update
                </div>
              </h6>
            }
          </div>
          {uploading == true &&
            <div className="row">
              <ProgressBar animated={!done} now={complete/count * 100} style={{width: '100%'}}/>
            </div>
          }
          <div className="row" style={{marginTop: '5px', marginBottom: '5px'}}>
            <input ref={this.organizerInput} type="file" multiple='multiple' name="organizer" onChange={() => this.onInputChanged()}/>
            { ((uploading == true && done == true) || uploading == false) && 
              <button ref={this.imageButton} className="btn btn-primary"  onClick={() => this.organizerInput.current.click()}>
                  Import Data from CSV
              </button>
            }
            {count > 0 &&
              <div align="right" style={{flexGrow: "1"}}>
                {uploading == false &&
                  <Button type="submit" variant="primary" onClick={() => this.submit()}>Submit</Button>
                }
                {errors > 0 && done == true && 
                  <Button type="submit" variant="primary" onClick={() => this.submit(true)}>Resubmit Failed Images</Button>
                }
              </div>
            }
          </div>
          <div className="row">
            <CsvPenList ref={this.csvPenList}/>
          </div>
        </div>
      );
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  // parse through the file when it a file is selected
  onInputChanged() {
    const _this = this
    const organizerInput = this.organizerInput.current
    const organizerButton = this.organizerButton.current
    const csvPenList = this.csvPenList.current
    if (organizerInput.files.length == 1) {
      var file = organizerInput.files[0]
      if (validate_file_size(file)) {
        file.text().then(function(csvString){
          _this.organizerArray = CSVToArray(csvString); 
          _this.parseOrganizerArray()
          _this.setState({count: _this.toBeImported.length, complete: 0, uploading: false, errors: 0, done: false})
          csvPenList.setState({csv_data: _this.toBeImported})
        })
      }
    }
  }

  // parse file and store a "toBeImported" array 
  parseOrganizerArray() {
    const organizerArray = this.organizerArray
    this.toBeImported = []
    var penNameColumn = null
    var yardCountColumn = null
    for(var i = 0;i<organizerArray[0].length;i++) {
      var columnName = organizerArray[0][i]
      if (columnName == "Pen Number:") {
        penNameColumn = i
      } else if(columnName == "Yardsheet Count:") {
        yardCountColumn = i
      }
    }

    if (penNameColumn == null || yardCountColumn == null) { // error 
      return
    }

    for(var i = 1; i<organizerArray.length; i++) {
      var row = organizerArray[i]
      if(row[penNameColumn] && row[yardCountColumn]) {
        this.toBeImported.push({pen_name: row[penNameColumn], yard_count: row[yardCountColumn]})
      }
    }
  }

  // start submiting the "toBeImported" data one at a time
  async submit(onlyFailed = false) { 
    if(onlyFailed) {
      var csvPenList = this.csvPenList.current
      var cnt_failed = 0
      for(var i = 0; i< this.toBeImported.length;i++) {
        var pen_row = this.toBeImported[i]
        if(pen_row.upload_result == "Failed") {
          cnt_failed += 1
          pen_row.upload_result = null
        }
      }
      await this.setState({done: false, uploading: true, count: cnt_failed, complete: 0, errors: 0})
      await csvPenList.setState({csv_data: this.toBeImported})
    } else {
      await this.setState({uploading: true, complete: 0, done: false})
    }

    this.importPenData(0, onlyFailed)
  }

  // imports a single row from "toBeImported"
  async importPenData(index, onlyFailed = false) {
    var _this = this
    var user_id = this.props.user_id
    var job_id = this.props.job_id
    var csvPenList = this.csvPenList.current

    const complete = this.state.complete
    const errors = this.state.errors

    if (this.toBeImported.length > index) {
      var pen_data = this.toBeImported[index]
      if(onlyFailed && pen_data.upload_result == "Success") {
        _this.importPenData(index+1, onlyFailed)
        return
      }

      try {
        var jform = new FormData();
        jform.append('utf8',"&#x2713;");
        jform.append('authenticity_token',form_authenticity_token());
        jform.append('user_id',user_id);
        
        
        jform.append('pen_name',pen_data.pen_name);
        jform.append('yard_count', pen_data.yard_count)
      
        $.ajax({
          url: Routes.import_csv_admin_job_path(job_id),
          type: "POST",
          data: jform,
          dataType: 'json',
          mimeType: 'multipart/form-data', // this too
          contentType: false,
          cache: false,
          processData: false,
          success: function(response) {
            if(response.status == "Failed") {
              _this.setState({errors: errors + 1})
            }
            _this.setState({complete: complete + 1})
            pen_data.upload_result = response.status
          },
          error: function(request, textStatus, errorThrown) {
            _this.setState({complete: complete + 1, errors: errors + 1})
            pen_data.upload_result = "Failed"
          },
          complete: function() {
            csvPenList.setState({csv_data: _this.toBeImported})
            _this.importPenData(index+1, onlyFailed)
          }
        })
      } catch(err) {
        _this.setState({complete: complete + 1, errors: errors + 1})
        pen_data.upload_result = "Failed"
        csvPenList.setState({csv_data: _this.toBeImported})
        _this.importPenData(index+1, onlyFailed)
      }

    } else {
      if(errors == 0) {
        setTimeout(function(){ window.location.href = `/jobs/${job_id}`}, 1000);
      } else {
        _this.setState({done: true})
      }
    }
  }
}

// Displays Badges with different colors to denote the result of submitting the data
class CsvPenList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csv_data: []
    }
  }

  render() {
    const csv_data = this.state.csv_data
    return (
      <div style={{margin: '5px'}}>
        {csv_data.map((value, index) => {
          if (value.upload_result == null) {
            return <Badge style={{background: "#bfbfbf", margin: '5px'}} key={index}>{value.pen_name}</Badge>
          } else if (value.upload_result == "Success") {
            return <Badge style={{background: "#008000", margin: '5px'}} key={index}>{value.pen_name}</Badge>
          } else if (value.upload_result == "Failed") {
            return <Badge variant="danger" style={{margin: '5px'}} key={index}>{value.pen_name}</Badge>
          }
        })}
      </div>
    )
  }
}

export default ImportCSV
