import React from "react"

export default class DelayEntry extends React.Component {

  constructor(props) {
    super(props)

    this.childrenRefs = []
  }

  componentDidMount() {
    var elements = compact(this.childrenRefs.map((ref) => ref.current))
    for(var element of elements) {
      var width = $(element).width()
      
      anime({
        targets: element,
        width: [0,width],
        opacity: [0,1],
        delay: 500,
        duration: 1,
        easing: 'linear',
      });
    }
  }

  componentWillUnmount() {}

  render () {
    return (
      <React.Fragment>
        {React.Children.map(this.props.children, (element, idx) => {
          var ref = React.createRef()
          this.childrenRefs.push(ref)
          return React.cloneElement(element, { ref: ref });
        })}
      </React.Fragment>
    )
  }
}