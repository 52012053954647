import React from "react"

/*
  Contact Support with a template body
*/
export default class CalendarEditor extends React.Component {
  #is_admin = false

  constructor(props) {
    super(props);

    this.state = {
      date: null, // the day being viewed
      events: [], // list of events for the day
      edit_event_id: null,
      is_submitting: false
    }

    this.#is_admin = props.is_admin;

    this.listener = this.postalListener();

    this.nameInput = React.createRef();
    this.descriptionInput = React.createRef();
  }

  postalListener() {
    var _this = this
    return postal.subscribe({
      channel: "CalendarEditor",
      topic: "select_date",
      callback: function(data, envelope) {
        var date = null
        try {
          date = new Date(data.date)
        } catch {}
        _this.setState({
          date: date,
          events: data.events || [],
        })
      }
    });
  }

  render () {
    const date = this.state.date
    const events = this.state.events
    var day_of_month = ""
    var month = ""
    var day_of_week = ""
    if (date != null) {
      try {
        day_of_month = date.toLocaleDateString('en-US', {timeZone: 'UTC', day: 'numeric' })
        month = date.toLocaleDateString('en-US', {timeZone: 'UTC', month: 'short' })
        day_of_week = date.toLocaleDateString('en-US', {timeZone: 'UTC', weekday: 'short' })
      } catch {

      }
    }
    return <div>
      <div className="txt-xlg pt-2">
        {day_of_week}, {month} {day_of_month} 
        {this.#is_admin &&
          <button className="btn btn-primary fas fa-plus float-right" onClick={()=>this.setEditEvent(null)}/>
        }
      </div>
      <hr/>
      <div>
        { this.#is_admin && this.state.edit_event_id == -1 &&
          this.generateNewEventView()
        }
        {events.map((event, index) => {
          if (event.id == this.state.edit_event_id) {
            return this.generateEditEventView(event)
          } else {
            return this.generateEventView(event)
          }
        })}
      </div>
    </div>
  }

  generateEventView(event) {
    return <div className="rounded bg-primary text-white text-left px-2 mb-2">
      <div className="txt-lg"> 
        {event.name}
        { this.#is_admin && 
          <button className="btn btn-icon fas fa-edit float-right text-white txt-sm" onClick={()=>this.setEditEvent(event)}/>
        }
      </div>
      
      <i>{event.description} </i>
    </div>
  }

  generateEditEventView(event) {
    return <div className="rounded text-left p-2  mb-2" style={{outline: `${theme.primary} solid`}}>
      <div className="txt-xlg"> 
        Edit Event 

        <a 
          className="btn btn-danger fas fa-trash txt-xs float-right"
          href={Routes.admin_calendar_event_path(event.id)}
          data-method="delete"
          data-confirm="Are you sure?"
        />
      </div>
      <div>
        <label className="mt-2 mb-0">Name</label>
        <input ref={this.nameInput} type="text" name="event_name" defaultValue={event.name} style={{width: "100%"}} autoComplete="off"/>
      </div>

      <div>
        <label className="mt-2 mb-0">Description</label>
        <input ref={this.descriptionInput} type="text" name="event_description" defaultValue={event.description} style={{width: "100%"}} autoComplete="off"/>
      </div>

      <div className="mt-2"> 
        <button className="btn btn-primary" onClick={()=>this.submitEditEvent(event)}>
          Save
        </button>

        <button className="btn btn-primary float-right" onClick={
          ()=>this.setState({edit_event_id: null})
        }>
          Cancel
        </button>
      </div>
      
    </div>
  }

  generateNewEventView() {
    return <div className="rounded text-left p-2 mb-2" style={{outline: `${theme.primary} solid`}}>
      <div className="txt-xlg"> New Event </div>
      <div>
        <label className="mt-2 mb-0">Name</label>
        <input ref={this.nameInput} type="text" name="event_name" defaultValue="" style={{width: "100%"}} autoComplete="off"/>
      </div>

      <div>
        <label className="mt-2 mb-0">Description</label>
        <input ref={this.descriptionInput} type="text" name="event_description" defaultValue="" style={{width: "100%"}} autoComplete="off"/>
      </div>

      <div className="mt-2"> 
        <button className="btn btn-primary" onClick={()=>this.submitNewEvent()}>
          Save
        </button>
        <button className="btn btn-primary float-right" onClick={
          ()=>this.setState({edit_event_id: null})
        }>
          Cancel
        </button>
      </div>
    </div>
  }

  setEditEvent(event) {
    if (event != null) { 
      this.setState({
        edit_event_id: event.id,
      })
    } else {
      this.setState({
        edit_event_id: -1,
      })
    }
  }

  submitEditEvent(event) {
    if (this.state.is_submitting) {
      return
    }
    var event_name = this.nameInput.current?.value
    var event_description = this.descriptionInput.current?.value

    // validate input
    if (event_name == "") {
      alert("Name is required")
      return
    }

    this.setState({is_submitting: true})

    var _this = this
    var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      jform.append('event_name', event_name)
      jform.append('event_description', event_description)
    $.ajax({
      url: Routes.admin_calendar_event_path(event.id),
      type: "PATCH",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          window.location.reload()
        } else {
          alert("Failed To Update Calendar Event")
        }
      },
      error: function(request, textStatus, errorThrown) {
        alert("Failed To Update Calendar Event")
      },
      complete: function() {
        _this.setState({is_submitting: false})
      }
    })
  }

  submitNewEvent(event) {
    if (this.state.is_submitting) {
      return
    }
    var event_name = this.nameInput.current?.value
    var event_description = this.descriptionInput.current?.value
    var event_date = this.state.date

    // validate input
    if (event_name == "") {
      alert("Name is required")
      return
    }

    if (event_date == null) {
      alert("Invalid Date")
      return
    }

    this.setState({is_submitting: true})

    var _this = this
    var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      jform.append('event_name', event_name)
      jform.append('event_description', event_description)
      jform.append('event_date', event_date)
    $.ajax({
      url: Routes.admin_calendar_events_path(),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if(response.status == "Success") {
          window.location.reload()
        } else {
          alert("Failed To Create Calendar Event")
        }
      },
      error: function(request, textStatus, errorThrown) {
        alert("Failed To Create Calendar Event")
      },
      complete: function() {
        _this.setState({is_submitting: false})
      }
    })
  }
}
